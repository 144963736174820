import { useAppSelector } from "../../config/hooks";

const useGender = () => {
  const genderArray = useAppSelector((state) => state.appConfig.genderList);

  const defaultGender = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Id: dafaultId, Title: dafaultId };
    }
    const selectedIndustry = genderArray.find(
      (industry) => industry.Id == dafaultId
    );
    return selectedIndustry;
  };

  return { genderArray, defaultGender };
};

export default useGender;
