
const DownloadHrmsIcon = ({ width = "23", height = "23", color = "#0A65CC" }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7.5V14.5M12 14.5L15 11.5M12 14.5L9 11.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 17.5H12H8" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
      <path d="M22 12.5C22 17.214 22 19.5711 20.5355 21.0355C19.0711 22.5 16.714 22.5 12 22.5C7.28595 22.5 4.92893 22.5 3.46447 21.0355C2 19.5711 2 17.214 2 12.5C2 7.78595 2 5.42893 3.46447 3.96447C4.92893 2.5 7.28595 2.5 12 2.5C16.714 2.5 19.0711 2.5 20.5355 3.96447C21.5093 4.93821 21.8356 6.30655 21.9449 8.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  );
};

export default DownloadHrmsIcon;
