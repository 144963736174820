import  apiClient from "../config/axiosConfig"
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base.model";
import {
  AllMessage,
  ChatMessage,
  ChatRoom,
  Conversation,
  CreateChatRoom,
  CreateConversation,
  CreateConversationAndMessage,
  GetAllMessages,
  SendBulkMessage,
  SendMessage
} from "../models/chat.model";


class ChatService {
  createChatRoom(
    userChatRoom: CreateChatRoom
  ): AxiosPromise<ApiResponse<ChatRoom>> {
    return apiClient.post(`/v1/exo/conversations-users`, userChatRoom);
  }

  getAllConversation(): AxiosPromise<Array<Conversation>> {
    return apiClient.get(`/v1/exo/conversations`);
  }

  createConversation(
    con: CreateConversation
  ): AxiosPromise<Conversation> {
    return apiClient.post(`/v1/exo/conversations`, con);
  }

  checkConversation(
    members: CreateConversation
  ): AxiosPromise<Conversation> {
    return apiClient.post(`/v1/exo/conversations/check`, members);
  }

  sendMessage(
    message: SendMessage
  ): AxiosPromise<ChatMessage> {
    return apiClient.post(`/v1/exo/conversations/${message.conversationId}/messages`, message);
  }

  createConversationAndSendMessage(
    conMessage: CreateConversationAndMessage
  ): AxiosPromise<ChatMessage> {
    return apiClient.post(`/v1/exo/conversations/messages`, conMessage);
  }

  getAllMessage(
    getMessage: GetAllMessages
  ): AxiosPromise<AllMessage> {
    return apiClient.get(`/v1/exo/conversations/${getMessage.conversationId}/messages`);
  }

  getMessageByUrl(
    nextUrl: string
  ): AxiosPromise<AllMessage> {
    return apiClient.get(nextUrl);
  }

  sendBulkMessage(
    data: SendBulkMessage
  ): AxiosPromise<AllMessage> {
    return apiClient.post(`/v1/exo/conversations/messages/bulk`,
      data
    );
  }

}

const chatService = new ChatService();
export default chatService;