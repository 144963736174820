import React, { useMemo } from "react";
import { useAppSelector } from "../../../../../config/hooks";
import ClockIcon from "../../../../../assets/Icons/ClockIcon";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import useSalaries from "../../../../../utils/dropdown-options/useSalaries";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import useJobTypes from "../../../../../utils/dropdown-options/useJobTypes";
import useWorkPlaceTypes from "../../../../../utils/dropdown-options/useWorkPlaceTypes";
import useMinExperiences from "../../../../../utils/dropdown-options/useMinExperiences";
import moment from "moment";
import IndustryIcon from "../../../../../assets/Icons/IndustryIcon";
import { v4 as uuid4 } from "uuid";
import SalaryRangeIcon from "../../../../../assets/Icons/SalaryRangeIcon";
import JobTypeIcon from "../../../../../assets/Icons/JobTypeIcon";
import WorkPlaceIcon from "../../../../../assets/Icons/WorkPlaceIcon";
import LocationIcon from "../../../../../assets/Icons/LocationIcon";
import ExperienceIcon from "../../../../../assets/Icons/ExperienceIcon";
import BulbIcon from "../../../../../assets/Icons/BulbIcon";
import * as constants from "../../../../../constant";

const JobPreview = ({ jobDetails, author, assignedMembers }) => {
  const token = useAppSelector((state) => state?.login?.session?.token);
  const loginSelector = useAppSelector((state) => state?.login?.session);

  const { defaultLocations } = useLocations();
  const { defualtSalary } = useSalaries();
  const { defaultIndustries } = useIndustries();
  const { defaultJobType } = useJobTypes();
  const { defaultWorkPlaceType } = useWorkPlaceTypes();
  const { defaultMinExperience } = useMinExperiences();

  const loc = useMemo(() => {
    return jobDetails?.location ? defaultLocations(jobDetails.location) : null;
  }, [defaultLocations, jobDetails.location]);

  const salMin = useMemo(() => {
    return jobDetails?.salary?.min
      ? defualtSalary(jobDetails.salary.min)
      : null;
  }, [defualtSalary, jobDetails.salary.min]);

  const salMax = useMemo(() => {
    return jobDetails?.salary?.max
      ? defualtSalary(jobDetails.salary.max)
      : null;
  }, [defualtSalary, jobDetails.salary.max]);

  const defaultInd = useMemo(() => {
    return jobDetails?.industries
      ? defaultIndustries(jobDetails?.industries)
      : null;
  }, [defaultIndustries, jobDetails?.industries]);

  const defaultJobTyp = useMemo(() => {
    return jobDetails?.jobType ? defaultJobType(jobDetails?.jobType) : null;
  }, [defaultJobType, jobDetails?.jobType]);

  const defaultworkPlaceTyp = useMemo(() => {
    return jobDetails?.workplaceType
      ? defaultWorkPlaceType(jobDetails?.workplaceType)
      : null;
  }, [defaultWorkPlaceType, jobDetails?.workplaceType]);

  const defaultMinExp = useMemo(() => {
    return jobDetails?.experience?.min
      ? defaultMinExperience(jobDetails?.experience?.min)
      : null;
  }, [defaultMinExperience, jobDetails?.experience?.min]);

  const defaultMaxExp = useMemo(() => {
    return jobDetails?.experience?.max
      ? defaultMinExperience(jobDetails?.experience?.max)
      : null;
  }, [defaultMinExperience, jobDetails?.experience?.max]);

  const postedBy = useMemo(() => {
    return jobDetails?.createdAt
      ? moment(jobDetails?.createdAt).fromNow()
      : null;
  }, [jobDetails?.createdAt]);

  return (
    <div className="w-[70%] bg-exwhite-100 p-5 rounded-xl h-screenheight overflow-y-auto no-scrollbar space-y-2">
      <div>
        <div className="flex items-center justify-start">
          <div className="flex-1 space-y-1">
            <div className="flex items-center justify-start gap-4">
              <h1 className="text-lg text-exgray-800 font-semibold">
                {jobDetails?.title}
              </h1>
              {/* <p className="text-lg font-semibold text-secondary-400">
                lnt6063
              </p> */}
            </div>

            <p className="w-fit px-4 py-1 rounded-full bg-secondary-100 text-secondary-400 text-sm">
              {jobDetails?.hideCompany &&
              !jobDetails?.authorId === loginSelector?.userId
                ? "Confidential"
                : jobDetails?.company}
            </p>

            <div className="">
              <div className="flex items-center justify-start gap-2">
                <div>
                  <ClockIcon width="15" height="15" color="#8C8C8C" />
                </div>
                <p className="self-start text-base text-exgray-200">{`Posted ${postedBy}`}</p>
              </div>
            </div>
          </div>
          <div className="h-10 w-10 rounded-sm bg-deafultCompanyProfile bg-cover bg-center self-start "></div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-2">
        <div className="flex-1 self-stretch border-t-2 border-secondary-100 whitespace-pre-wrap text-base text-exgray-200 bg-exwhite-100 p-4">
          {jobDetails?.description}
        </div>
        <div className="w-[40%] self-stretch space-y-2">
          <div className="border-2 border-secondary-100 p-6 rounded-md space-y-6 bg-exwhite-100">
            <div className="grid grid-cols-2 auto-rows-auto gap-y-6 gap-x-2">
              <div className="text-start text-base space-y-1">
                <div>
                  <IndustryIcon />
                </div>
                <h1 className="text-exgray-200 text-sm">INDUSTRY</h1>
                {defaultInd && defaultInd.length > 0
                  ? defaultInd.map((l, i) => (
                      <p
                        className="text-expurple-800 font-semibold"
                        key={uuid4()}
                      >
                        {`${l.Name} ${defaultInd.length - 1 !== i ? ", " : ""}`}
                      </p>
                    ))
                  : "N/A"}
              </div>
              <div className="text-start text-base space-y-1">
                <div>
                  <SalaryRangeIcon />
                </div>
                <h1 className="text-exgray-200 text-sm">SALARY RANGE</h1>
                {jobDetails?.hideSalary &&
                !jobDetails?.authorId === loginSelector?.userId ? (
                  <p className="text-expurple-800 font-semibold text-start">
                    Confidential
                  </p>
                ) : (
                  <p className="text-expurple-800 font-semibold text-start">
                    &#x20b9; {salMin?.Title} - &#x20b9; {salMax?.Title}
                  </p>
                )}
              </div>
              <div className="text-start text-base space-y-1">
                <div>
                  <JobTypeIcon />
                </div>
                <h1 className="text-exgray-200 text-sm">JOB TYPE</h1>
                <p className="text-expurple-800 font-semibold">
                  {defaultJobTyp?.Title}
                </p>
              </div>
              <div className="text-start text-base space-y-1">
                <div>
                  <WorkPlaceIcon />
                </div>
                <h1 className="text-exgray-200 text-sm">WORK PLACE TYPE</h1>
                <p className="text-expurple-800 font-semibold">
                  {defaultworkPlaceTyp?.Title}
                </p>
              </div>
              <div className="text-start text-base space-y-1">
                <div>
                  <LocationIcon width="24" height="24" />
                </div>
                <h1 className="text-exgray-200 text-sm">LOCATION</h1>
                <div className="flex flex-wrap items-center justify-start gap-1">
                  {loc && loc.length > 0
                    ? loc.map((l, i) => (
                        <p
                          className="text-expurple-800 font-semibold"
                          key={uuid4()}
                        >
                          {`${l.Name} ${loc.length - 1 !== i ? ", " : ""}`}
                        </p>
                      ))
                    : "N/A"}
                </div>
              </div>
              <div className="text-start text-base space-y-1">
                <div>
                  <ExperienceIcon />
                </div>
                <h1 className="text-exgray-200 text-sm">YRS OF EXPERIENCE</h1>
                <p className="text-expurple-800 font-semibold">
                  {defaultMinExp?.Title} - {defaultMaxExp?.Title}
                </p>
              </div>
            </div>
            <div>
              <div className="text-start text-base space-y-1">
                <div>
                  <BulbIcon width="32" height="32" />
                </div>
                <h1 className="text-exgray-200 text-sm">SKILLS</h1>
                <div className="text-expurple-800 font-semibold flex flex-wrap gap-2">
                  {jobDetails.skills.length > 0 &&
                    jobDetails.skills.map((skill) => (
                      <p
                        className="border-2 border-secondary-400 px-3 py-1 text-start text-base text-secondary-400 font-semibold rounded-full"
                        key={uuid4()}
                      >
                        {skill}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {assignedMembers && assignedMembers?.length > 0 && (
            <div className="p-6 border-2 border-secondary-100 rounded-md space-y-3 bg-exwhite-100">
              <h1 className="text-exgray-800 text-xl font-semibold text-start">
                Assign Job to
              </h1>

              <div className="text-expurple-800 font-semibold flex flex-wrap gap-2">
                {assignedMembers.map((member) => (
                  <p
                    className="bg-expurple-800 px-3 py-2 text-start text-base text-exwhite-100 font-semibold rounded-md"
                    key={uuid4()}
                  >
                    {member?.memberEmail}
                  </p>
                ))}
              </div>
            </div>
          )}

          {jobDetails.authorType === constants.COMPANY && (
            <div className="p-6 border-2 border-secondary-100 rounded-md space-y-3 bg-exwhite-100">
              <h1 className="text-exgray-800 text-xl font-semibold text-start">
                Job Created By
              </h1>
              <div className="flex items-center justify-start gap-4">
                {author?.profileImage ? (
                  <img
                    className="h-20 w-20 rounded-full object-cover"
                    src={`${constants.baseUrl}/${author?.profileImage}?t=${token}`}
                    alt={`Profile`}
                  />
                ) : (
                  <div className="h-20 w-20 rounded-full flex items-center justify-center bg-deafultCompanyProfile bg-center bg-no-repeat bg-cover"></div>
                )}
                <h1 className="text-xl text-exgray-200 font-semibold">{`${author?.title}`}</h1>
              </div>
            </div>
          )}

          {jobDetails.authorType === constants.COMPANY_MEMBER && (
            <div className="p-6 border-2 border-secondary-100 rounded-md space-y-3">
              <h1 className="text-exgray-800 text-xl font-semibold text-start">
                Job Created By
              </h1>
              <div className="flex items-center justify-start gap-4">
                {author?.profileImage ? (
                  <img
                    className="h-20 w-20 rounded-full object-cover"
                    src={`${constants.baseUrl}/${author?.profileImage}?t=${token}`}
                    alt={`Profile`}
                  />
                ) : (
                  <div className="h-20 w-20 rounded-full flex items-center justify-center bg-DefaultProfileImg bg-center bg-no-repeat bg-cover"></div>
                )}
                <h1 className="text-xl text-exgray-200 font-semibold">{`${author?.firstName} ${author?.lastName}`}</h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobPreview;
