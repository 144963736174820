import { AxiosPromise } from "axios";
import { CreateInteractionReq, Interaction } from "../models/interaction.model";
import { ApiResponse } from "../models/base.model";
import apiClient from "../config/axiosConfig";

class InteractionService {
    createInteraction(
       interaction:CreateInteractionReq
      ): AxiosPromise<ApiResponse<Interaction>> {
        return apiClient.post(`/v1/exo/clients/${interaction.clientId}/interactions`, interaction);
    }

    getAllInteractions(clientId:string): AxiosPromise<ApiResponse<Array<Interaction>>> {
         return apiClient.get(`/v1/exo/clients/${clientId}/interactions`);
     }

    // getClientById(clientId:string):AxiosPromise<ApiResponse<Client>>{
    //     return apiClient.get(`/v1/exo/clients/${clientId}`);
    // }

    // checkDomain(domain:string):AxiosPromise<ApiResponse<CompanyProfile>>{
    //     return apiClient.get(`/v1/exo/companies/domain?domain=${domain}`)
    // }


}

const interactionService=new InteractionService();
export default interactionService;