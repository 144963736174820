import React, { useState } from "react";

const Contact = ({data}) => {
   const contact=data
    // console.log("CONTACT",data,contact)
    
    // const contact = {
    //   name: "Sarah Johnson",
    //   designation: "CEO",
    //   phone: "+1-234-567-8901",
    //   email: "sarah.johnson@abctech.com",
    //   isPrimary: true,
    // };

    //   const currentContact = contacts.find(
    //     (contact) => contact.name === selectedContact
    //   );

  return (
    <>   
    <div className=" min-w-60 min-h-52 border border-gray-300 rounded-lg px-3 pt-8 max-w-md bg-white">
      {/* Header */}
      <div>
        <div className="mb-2 flex justify-between">
          <div>
            <span className="text-exgray-500">Name:</span>{" "}
            <span className="text-exgray-800 font-semibold">
              {contact.name}
            </span>
          </div>
          {contact.isPrimary && (
            <span className="bg-blue-100 text-blue-600 text-xs font-semibold ml-2 px-2 py-1 rounded-full ">
              Primary
            </span>
          )}
        </div>
        <div className="mb-2">
          <span className="text-exgray-500">Designation:</span>{" "}
          <span className="text-exgray-800 font-semibold">
            {contact.designation}
          </span>
        </div>
        <div className="mb-2">
          <span className="text-exgray-500">Phone Number:</span>{" "}
          <span className="text-exgray-800 font-semibold">{contact.phoneNumber}</span>
        </div>
        <div>
          <span className="text-exgray-500">Email ID:</span>{" "}
          <span className="text-exgray-800 font-semibold">{contact.email}</span>
        </div>
      </div>
          
    </div>
    </>
  );
};

export default Contact;
