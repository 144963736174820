
export interface BasicDataViewProps {
  title: string;
  value: any;
}

const BasicDataView = ({
  title,
  value
}: BasicDataViewProps) => {

  return (
    <div className="flex w-full">
      <h1 className="text-sm text-exgray-400 mt-2">{title}  :  </h1>
      <h1 className="text-sm text-primary-500 mt-2 font-semibold ml-2"> {value}</h1>
    </div>
  );
};

export default BasicDataView;
