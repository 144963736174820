import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toggleIndex: 0,
};

export const sideMenuSlice = createSlice({
  name: "sideMenu",
  initialState,
  reducers: {
    toggleOption: (state, action) => {
      state.toggleIndex = action.payload.index;
    },
  },
});

export const { toggleOption } = sideMenuSlice.actions;
export default sideMenuSlice.reducer;
