import React from "react";
import useCountryCodes from "../../../../../utils/dropdown-options/useCountryCodes";
import useSalaries from "../../../../../utils/dropdown-options/useSalaries";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import { useLocation } from "react-router-dom";

const UploadedUserAdditionalSection = ({ userDetails }) => {
  const location = useLocation();
  const receivedData = location.state;
  const additionalDetails = userDetails.userAdditionalInfo;

  const { defaultCountryCode } = useCountryCodes();
  const { defualtSalary } = useSalaries();
  const { defaultIndustry } = useIndustries();
  const { defaultLocation } = useLocations();

  let defaultCode;
  let defaultSal;
  let defaultIndust;
  let defaultLoc;
  let expectedDefaultSal;

  if (additionalDetails?.countryCode) {
    defaultCode = defaultCountryCode(additionalDetails?.countryCode);
  }

  if (additionalDetails?.lastSalary) {
    defaultSal = defualtSalary(additionalDetails?.lastSalary);
  }
  if (receivedData?.expectation) {
    expectedDefaultSal = defualtSalary(receivedData?.expectation);
  }
  if (
    additionalDetails?.preferredIndustry &&
    additionalDetails?.preferredIndustry[0]
  ) {
    defaultIndust = defaultIndustry(additionalDetails?.preferredIndustry[0]);
  } else {
    if (
      userDetails?.userDetails?.industry &&
      userDetails?.userDetails?.industry.length > 0
    ) {
      defaultIndust = defaultIndustry(userDetails?.userDetails?.industry[0]);
    }
  }
  if (
    additionalDetails?.preferredLocation &&
    additionalDetails?.preferredLocation[0]
  ) {
    defaultLoc = defaultLocation(additionalDetails?.preferredLocation[0]);
  }

  return (
    <div className="">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h1 className="text-lg text-black text-semibold text-start">
            Additional Information
          </h1>
        </div>

        {additionalDetails?.id && (
          <div className="grid grid-cols-2 auto-rows-auto gap-2">
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Additional Phone No. :
              </h1>
              <p className="text-base text-exgray-400 font-semibold">{`+ ${defaultCode?.PhoneCode} ${additionalDetails.mobile}`}</p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Additional Email ID :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {additionalDetails?.email}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Last Salary :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {defaultSal?.Title}
              </p>
            </div>
            {expectedDefaultSal?.Title && (
              <div className="flex items-center justify-start gap-2">
                <h1 className="text-base text-expurple-800 font-semibold">
                  Expected Salary :{" "}
                </h1>
                <p className="text-base text-exgray-400 font-semibold">
                  {expectedDefaultSal?.Title}
                </p>
              </div>
            )}
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Preferred Industry :
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {defaultIndust?.Name}
              </p>
            </div>
            {receivedData?.qualification && (
              <div className="flex items-center justify-start gap-2">
                <h1 className="text-base text-expurple-800 font-semibold">
                  Highest Qualification :
                </h1>
                <p className="text-base text-exgray-400 font-semibold">
                  {receivedData?.qualification}
                </p>
              </div>
            )}
            {receivedData?.previousCompany && (
              <div className="flex items-center justify-start gap-2">
                <h1 className="text-base text-expurple-800 font-semibold">
                  Previous Company Name :
                </h1>
                <p className="text-base text-exgray-400 font-semibold">
                  {receivedData?.previousCompany}
                </p>
              </div>
            )}
            {receivedData?.reportingTo && (
              <div className="flex items-center justify-start gap-2">
                <h1 className="text-base text-expurple-800 font-semibold">
                  Reporting To :
                </h1>
                <p className="text-base text-exgray-400 font-semibold">
                  {receivedData?.reportingTo}
                </p>
              </div>
            )}
            {receivedData?.workMode && (
              <div className="flex items-center justify-start gap-2">
                <h1 className="text-base text-expurple-800 font-semibold">
                  Work Mode :
                </h1>
                <p className="text-base text-exgray-400 font-semibold">
                  {receivedData?.workMode}
                </p>
              </div>
            )}
            {receivedData?.teamSize !== 0 && (
              <div className="flex items-center justify-start gap-2">
                <h1 className="text-base text-expurple-800 font-semibold">
                  Team Size :
                </h1>
                <p className="text-base text-exgray-400 font-semibold">
                  {receivedData?.teamSize}
                </p>
              </div>
            )}
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Preferred Location :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {defaultLoc?.Name}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Open for Relocation :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {additionalDetails.relocation ? "Yes" : "No"}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Serving Notice Period :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {additionalDetails.isServingNoticePeriod ? "Yes" : "No"}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                No of Serving :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {additionalDetails.isServingNoticePeriod
                  ? additionalDetails.noticePeriod
                    ? additionalDetails.noticePeriod
                    : "-"
                  : "0"}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadedUserAdditionalSection;
