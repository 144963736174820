import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as constants from "../../../../../constant";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { getMemberByIdApi } from "../../../../../slices/companyMemberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import { v4 as uuidV4 } from "uuid";
import EmployeePersonalInfo from "../../../../../components/employee-details/EmployeePersonalInfo";

const CompanyMemberDetails = () => {
  const dispatch = useAppDispatch();

  const { memberId } = useParams();

  const token = useAppSelector((state) => state.login.session.token);

  const [memberDetails, setMemberDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const memberTabs = useMemo(() => {
    return [
      {
        title: "Dashboard",
      },
      {
        title: "Personal Details",
      },
      {
        title: "Contact Details",
      },
      {
        title: "Family Details",
      },
      {
        title: "Documents",
      },
      {
        title: "Medicals",
      },
      {
        title: "More",
      },
    ];
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getMemberByIdApi({ memberId })
        ).then(unwrapResult);
        const memberInfo = response.data.data;
        setMemberDetails(memberInfo);
        setIsLoading(false);
      } catch (error) {
        setError(true);
        setIsLoading(false);
        toast.error("Unable to fetch employee details");
      }
    };

    fetchData();
  }, [dispatch, memberId]);

  const togglTabsHandler = (index) => {
    setTabIndex(index);
  };

  if (error) {
    <h1>Somthing went wrong</h1>;
  }

  return isLoading ? (
    <div className="bg-exwhite-100 p-4">
      <div className="space-y-4">
        <h1 className="text-lg text-primary-400/50 font-semibold">
          Employee Details
        </h1>

        <div className="flex items-center justify-start gap-4 animate-pulse">
          <div className="h-20 w-20 rounded-full bg-exgray-100"></div>
          <div className="flex-1 grid grid-cols-3 gap-x-6">
            <div className="space-y-4">
              <div className="bg-exgray-100 p-1 rounded-full"></div>
              <div className="bg-exgray-100 p-1 rounded-full"></div>
              <div className="bg-exgray-100 p-1 rounded-full"></div>
            </div>
            <div className="space-y-4">
              <div className="bg-exgray-100 p-1 rounded-full"></div>
              <div className="bg-exgray-100 p-1 rounded-full"></div>
              <div className="bg-exgray-100 p-1 rounded-full"></div>
            </div>
            <div className="space-y-4">
              <div className="bg-exgray-100 p-1 rounded-full"></div>
              <div className="bg-exgray-100 p-1 rounded-full"></div>
              <div className="bg-exgray-100 p-1 rounded-full"></div>
            </div>
          </div>
        </div>

        <div className="w-full bg-exgray-100 p-48 rounded-xl animate-pulse"></div>
      </div>
    </div>
  ) : (
    <div className="min-h-screenheight bg-exwhite-100 p-4">
      <div className="space-y-8">
        <div className="space-y-4">
          <h1 className="text-lg text-primary-400/50 font-semibold">
            Employee Details
          </h1>

          <div className="flex items-start justify-start gap-4">
            {memberDetails.picUrl ? (
              <div className="h-20 w-20  rounded-full flex items-center justify-center overflow-hidden">
                <img
                  src={`${constants.baseUrl}/files/${memberDetails?.picUrl}?t=${token}`}
                  alt="profile"
                  className="w-full h-full object-cover"
                />
              </div>
            ) : memberDetails.firstName && memberDetails.lastName ? (
              <div className="h-20 w-20 rounded-full bg-primary-400 flex items-center justify-center text-3xl text-exwhite-100 font-semibold">{`${memberDetails.firstName[0]}${memberDetails.lastName[0]}`}</div>
            ) : (
              <div className="h-20 w-20 rounded-full bg-DefaultProfileImg bg-cover bg-center bg-no-repeat"></div>
            )}

            <div className="flex-1 grid grid-cols-3 gap-x-6">
              <div className="space-y-1">
                <p className="text-base text-primary-400 font-semibold">
                  <span className="font-normal">Full Name:</span>
                  {` ${memberDetails.firstName} ${memberDetails.lastName}`}
                </p>

                <p className="text-base text-primary-400 font-semibold">
                  <span className="font-normal">Department:</span>
                  {` ${memberDetails.groups[0]}`}
                </p>

                <p className="text-base text-primary-400 font-semibold">
                  <span className="font-normal">Position:</span>
                  {` ${"Marketing Specialist"}`}
                </p>
              </div>

              <div className="space-y-1">
                <p className="text-base text-primary-400 font-semibold">
                  <span className="font-normal">Employee ID:</span>
                  {` ${"EXO25640"}`}
                </p>

                <p className="text-base text-primary-400 font-semibold">
                  <span className="font-normal">Official Email ID:</span>
                  {` ${memberDetails.emailId}`}
                </p>

                <p className="text-base text-primary-400 font-semibold">
                  <span className="font-normal">Phone Number:</span>
                  {` ${memberDetails.mobileNo}`}
                </p>
              </div>

              <div className="space-y-1">
                <p className="text-base text-primary-400 font-semibold">
                  <span className="font-normal">Date of Joining:</span>
                  {` ${"2020-01-15"}`}
                </p>

                <div className="flex items-start justify-start text-base text-primary-400 font-semibold gap-1">
                  <span className="font-normal">Address:</span>
                  <p className="flex-1">{` ${" 101 Marine Drive, Churchgate, Mumbai, Maharashtra"}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-primary-100 rounded-lg py-4 space-y-4">
          <div className="border-b border-exgray-200 pb-2">
            <div className="flex items-center justify-start gap-4 px-4">
              {memberTabs.map((tab, index) => (
                <button
                  className={`py-1 px-2 flex-1 rounded-full text-center ${
                    index === tabIndex
                      ? "bg-primary-400 text-exwhite-100"
                      : "text-primary-400 hover:font-semibold"
                  }`}
                  onClick={() => togglTabsHandler(index)}
                  key={uuidV4()}
                >
                  {tab.title}
                </button>
              ))}
            </div>
          </div>

          <div className="h-96 overflow-y-auto no-scrollbar">
            {tabIndex === 1 && (
              <div className="flex items-start justify-center px-4">
                <div className="flex-1">
                  <div className="space-y-4">
                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Salutation:</span>
                      {` ${"Mr"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">First Name:</span>
                      {` ${memberDetails.firstName}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Middle Name:</span>
                      {` ${"Kailash"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Last Name:</span>
                      {` ${memberDetails.lastName}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Father's Name:</span>
                      {` ${"Kailash Patel"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Gender:</span>
                      {` ${"Male"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Place of Birth:</span>
                      {` ${"Pune, Maharashtra"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Date of Birth:</span>
                      {` ${"1990-07-20"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Actual Date of Birth:</span>
                      {` ${"1990-07-20"}`}
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="space-y-4">
                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Nationality:</span>
                      {` ${"Indian"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Religion:</span>
                      {` ${"Hindu"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Caste:</span>
                      {` ${"kshatriya"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Age:</span>
                      {` ${"34"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Blood Group:</span>
                      {` ${"A+"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Marital Status:</span>
                      {` ${"Married"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Wedding Anniversary:</span>
                      {` ${"2018-10-15"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Aadhar Number:</span>
                      {` ${"9876-5432-1098"}`}
                    </p>

                    <p className="text-base text-exgray-400 font-semibold">
                      <span className="font-normal">Hobbies:</span>
                      {` ${"Painting, Gardening, Yoga"}`}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {tabIndex === 2 && <EmployeePersonalInfo />}

            {tabIndex === 3 && (
              <div className="space-y-4 px-4">
                <div className="space-y-4">
                  <h1 className="text-base text-primary-400 font-semibold">
                    Emergency Contact 1
                  </h1>

                  <div className="flex items-start justify-start">
                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Name :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Rahul Sharma"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Relationship :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Brother"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Gender :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Male"}`}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start justify-start">
                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Date of Birth :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"B.E."}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Blood Group :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"B+"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Qualtification :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Mumbai"}`}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start justify-start">
                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Occupation :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Working"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Department :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"It Department"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Income :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"N/A"}`}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <h1 className="text-base text-primary-400 font-semibold">
                    Emergency Contact 2
                  </h1>

                  <div className="flex items-start justify-start">
                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Name :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Rahul Sharma"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Relationship :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Brother"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Gender :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Male"}`}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start justify-start">
                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Date of Birth :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"B.E."}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Blood Group :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"B+"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Qualtification :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Mumbai"}`}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start justify-start">
                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Occupation :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"Working"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Department :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"It Department"}`}
                      </p>
                    </div>

                    <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
                      <span className="font-normal">Income :</span>
                      <p className="flex-1 text-base font-semibold">
                        {` ${"N/A"}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyMemberDetails;
