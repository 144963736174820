import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../../../components/basic-components/ExInput";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import ExRadioBtnGroup from "../../../../../components/basic-components/ExRadioBtnGroup";
import useCountryCodes from "../../../../../utils/dropdown-options/useCountryCodes";
import {
  createAdditionalDetailsApi,
  updateAdditionalDetailsApi,
} from "../../../../../slices/candidateProfileSlice";
import useSalaries from "../../../../../utils/dropdown-options/useSalaries";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import PlusCircleIcon from "../../../../../assets/Icons/PlusCircleIcon";
import uploadContent from "../../../../../utils/upload-content/uploadContent";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingIcon from "../../../../../assets/Icons/LoadingIcon";
import { toast } from "sonner";

const AdditionalInformationForm = ({ onClose = () => {} }) => {
  const dispatch = useAppDispatch();

  const inputRef = useRef(null);

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const { modifiedCountryCodes, defaultCountryCode } = useCountryCodes();
  const { minSalaries, defualtSalary } = useSalaries();
  const { industriesArray, defaultIndustry } = useIndustries();
  const { locationsArray, defaultLocation } = useLocations();

  const userId = useAppSelector((state) => state.login.session.userId);
  const userDetails = useAppSelector(
    (state) => state.candidateProfile.userDetails
  );
  const additionalDetails = useAppSelector(
    (state) => state.candidateProfile.userAdditionalInfo
  );

  const { email, countryCode, mobile } = userDetails;

  const form = useForm({
    defaultValues: {
      phoneNo:
        mobile > 0
          ? additionalDetails?.mobile > 0
            ? additionalDetails?.mobile.toString()
            : mobile.toString()
          : additionalDetails?.mobile > 0
          ? additionalDetails.mobile.toString()
          : "",
      emailId: email
        ? additionalDetails?.email
          ? additionalDetails?.email
          : email
        : additionalDetails?.email
        ? additionalDetails.email
        : "",
      countryCode:
        countryCode > 0
          ? additionalDetails?.countryCode > 0
            ? defaultCountryCode(additionalDetails.countryCode)
            : defaultCountryCode(countryCode)
          : additionalDetails?.countryCode > 0
          ? defaultCountryCode(additionalDetails.countryCode)
          : null,
      openForRelocation: additionalDetails?.relocation
        ? "Yes"
        : additionalDetails?.relocation === false
        ? "No"
        : "Yes",
      noticePeriod: additionalDetails.isServingNoticePeriod ? "Yes" : "No",
      noticePeriodDuration: additionalDetails.noticePeriod
        ? additionalDetails.noticePeriod
        : "",
      salary: additionalDetails?.lastSalary
        ? defualtSalary(additionalDetails?.lastSalary)
        : null,
      industry: additionalDetails?.id
        ? additionalDetails?.preferredIndustry &&
          additionalDetails?.preferredIndustry.length > 0 &&
          additionalDetails?.preferredIndustry[0]
          ? defaultIndustry(additionalDetails?.preferredIndustry[0])
          : null
        : null,
      location: additionalDetails?.id
        ? additionalDetails?.preferredLocation &&
          additionalDetails?.preferredLocation.length > 0 &&
          additionalDetails?.preferredLocation[0]
          ? defaultLocation(additionalDetails?.preferredLocation[0])
          : null
        : null,

      file: {
        name: additionalDetails.resume.name
          ? additionalDetails.resume.name
          : "",
        url: additionalDetails.resume.url ? additionalDetails.resume.url : "",
        contentType: additionalDetails.resume.contentType
          ? additionalDetails.resume.contentType
          : "",
        size: additionalDetails.resume.size ? additionalDetails.resume.size : 0,
      },

      //additional fields
      expectedCtc: additionalDetails?.expectation
        ? defualtSalary(additionalDetails?.expectation)
        : null,
      teamSize: additionalDetails?.teamSize ? additionalDetails?.teamSize : "", // number type
      reportingTo: additionalDetails?.reportingTo
        ? additionalDetails?.reportingTo
        : "",
    },
  });

  const { handleSubmit, control, formState, setValue, watch } = form;
  const { errors, isDirty } = formState;

  const triggerInputfileHanlder = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const uploadedFile = watch("file");
  const isServingNoticePeriodTrack = watch("noticePeriod");

  useEffect(() => {
    if (isServingNoticePeriodTrack === "No") {
      setValue("noticePeriodDuration", "0");
    } else {
      setValue(
        "noticePeriodDuration",
        additionalDetails.noticePeriod ? additionalDetails.noticePeriod : ""
      );
    }
  }, [additionalDetails.noticePeriod, isServingNoticePeriodTrack, setValue]);

  const onSubmit = async (formData) => {
    setIsFormLoading(true);
    if (additionalDetails?.id) {
      const data = {
        userId: userId,
        id: additionalDetails?.id,
        mobile: parseInt(formData.phoneNo),
        email: formData.emailId,
        preferredLocation: [formData.location.Id.toString()],
        lastSalary: formData.salary.Id.toString(),
        preferredIndustry: [formData.industry.Id.toString()],
        relocation: formData.openForRelocation === "Yes" ? true : false,
        countryCode: parseInt(formData.countryCode.PhoneCode),
        resume: formData.file,
        isServingNoticePeriod: formData.noticePeriod === "Yes" ? true : false,
        noticePeriod: formData.noticePeriodDuration,

        //additional fields
        teamSize: parseInt(formData.teamSize),
        reportingTo: formData.reportingTo,
        expectation: formData.expectedCtc.Id.toString(),
      };
      try {
        await dispatch(updateAdditionalDetailsApi(data)).then(unwrapResult);
        toast.success("Updated successfully!");
      } catch (error) {
        toast.error("Something went wrong");
      }
      onClose();
      setIsFormLoading(false);
    } else {
      const data = {
        userId: userId,
        mobile: parseInt(formData.phoneNo),
        email: formData.emailId,
        preferredLocation: [formData.location.Id.toString()],
        lastSalary: formData.salary.Id.toString(),
        preferredIndustry: [formData.industry.Id.toString()],
        relocation: formData.openForRelocation === "Yes" ? true : false,
        countryCode: parseInt(formData.countryCode.PhoneCode),
        resume: formData.file,
        isServingNoticePeriod: formData.noticePeriod === "Yes" ? true : false,
        noticePeriod: formData.noticePeriodDuration,

        //additional fields
        teamSize: parseInt(formData.teamSize),
        reportingTo: formData.reportingTo,
        expectation: formData.expectedCtc.Id.toString(),
      };
      try {
        await dispatch(createAdditionalDetailsApi(data)).then(unwrapResult);
        setIsFormLoading(false);
        toast.success("Additional information added !");
        onClose();
      } catch (error) {
        toast.error("Something went wrong");
        setIsFormLoading(false);
        onClose();
      }
    }
  };

  return (
    <div className="w-[60%] max-h-[90%] bg-expurple-200 p-10 rounded-xl overflow-y-auto no-scrollbar space-y-5">
      <h1 className="text-3xl text-expurple-800 font-semibold text-center">
        Additional Information
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-2 auto-rows-auto gap-4">
          <div className="flex items-center justify-between gap-2">
            <div className="min-w-[120px] self-start">
              <Controller
                name="countryCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Country"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="PhoneCode"
                    optionLabel="PhoneCodeLabel"
                    options={modifiedCountryCodes}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    // dropDownBorderColor="#696969"
                    error={errors?.countryCode?.message}
                  />
                )}
              />
            </div>
            <div className="flex-1 self-start">
              <Controller
                name="phoneNo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Mobile no is required",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid mobile number",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Phone Number"
                    placeholder="Enter your phone number"
                    name="phoneNo"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={onChange}
                    value={value}
                    error={errors?.phoneNo?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Email ID"
                  placeholder="eg. abc@abc.com"
                  name="emailId"
                  onChange={onChange}
                  value={value}
                  error={errors?.emailId?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="salary"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Last salary is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Last Salary *"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Title"
                  options={minSalaries}
                  dropDownHeight="40px"
                  dropDownBorderWidth="2px"
                  dropDownBorderColor="#CDCED2"
                  error={errors?.salary?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="expectedCtc"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Expected CTC is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Expectation"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Title"
                  options={minSalaries}
                  dropDownHeight="40px"
                  dropDownBorderWidth="2px"
                  dropDownBorderColor="#CDCED2"
                  error={errors?.expectedCtc?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="industry"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Preffered industry is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Preffered Industry *"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={industriesArray}
                  dropDownHeight="40px"
                  dropDownBorderWidth="2px"
                  // dropDownBorderColor="#696969"
                  error={errors?.industry?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="location"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Preffered location is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Preffered Location *"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={locationsArray}
                  dropDownHeight="40px"
                  dropDownBorderWidth="2px"
                  // dropDownBorderColor="#696969"
                  error={errors?.location?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="openForRelocation"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Employment Type is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExRadioBtnGroup
                  title="Open For Relocation"
                  onChange={onChange}
                  selectedOption={value}
                  options={["Yes", "No"]}
                  error={errors?.openForRelocation?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="noticePeriod"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Notice period is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExRadioBtnGroup
                  title="Serving Notice Period"
                  onChange={onChange}
                  selectedOption={value}
                  options={["Yes", "No"]}
                  error={errors?.noticePeriod?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="noticePeriodDuration"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Notice period required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="No. of Days Serving"
                  placeholder="Ex. 30"
                  name="noticePeriodDuration"
                  type="number"
                  onChange={onChange}
                  onWheel={(e) => e.target.blur()}
                  value={value}
                  error={errors?.noticePeriodDuration?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="teamSize"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Team size required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Team Size"
                  placeholder="Ex. 10"
                  name="teamSize"
                  type="number"
                  onChange={onChange}
                  onWheel={(e) => e.target.blur()}
                  value={value}
                  error={errors?.teamSize?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="reportingTo"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Field is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Reporting To"
                  placeholder="Enter here"
                  name="reportingTo"
                  onChange={onChange}
                  value={value}
                  error={errors?.reportingTo?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="file"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                validate: {
                  hasName: (value) => value?.name !== "" || "File is required",
                  hasUrl: (value) => value?.url !== "" || "File is required",
                  hasContentType: (value) =>
                    value?.contentType !== "" || "File is required",
                  // validSize: (value) => value?.size > 0 || "File is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label
                    htmlFor="file"
                    className={`text-lg font-normal text-expurple-800`}
                  >
                    Upload Resume / CV
                  </label>
                  <div className="hidden">
                    <input
                      title="Add Resume / CV"
                      placeholder="Browse file"
                      name="file"
                      id="file"
                      type="file"
                      ref={inputRef}
                      accept=".pdf"
                      onChange={(e) => {
                        setIsFileLoading(true);
                        uploadContent.uploadProfileContent(
                          userId,
                          e.target.files,
                          (response) => {
                            setIsFileLoading(false);

                            if (!response[0].error) {
                              onChange({
                                name: response[0].orginalFileName,
                                url: response[0].url,
                                contentType: response[0].contentType,
                                size: response[0].size,
                              });
                            }
                          }
                        );
                      }}
                      // value={value}
                    />
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <div className="flex-1 h-10 bg-expurple-100 px-3 flex items-center justify-start gap-1">
                      <div
                        className="cursor-pointer"
                        onClick={triggerInputfileHanlder}
                      >
                        <PlusCircleIcon />
                      </div>
                      <p className="text-expurple-700 text-base line-clamp-1">
                        {uploadedFile.name
                          ? uploadedFile.name
                          : "Browse file. Only pdf"}
                      </p>
                    </div>
                    {isFileLoading && (
                      <div>
                        <LoadingIcon />
                      </div>
                    )}
                  </div>
                  {errors?.file?.message && (
                    <p className="text-red-400">{errors?.file?.message}</p>
                  )}
                </div>
              )}
            />
          </div>
        </div>

        <div>
          <div className="w-fit ml-auto mr-0">
            <button
              className={`text-base text-center px-8 py-2 ${
                isFileLoading || isFormLoading || !isDirty
                  ? "bg-exgray-400/50 text-exwhite-100"
                  : "bg-expurple-800 text-golden"
              } rounded-lg`}
              disabled={isFileLoading || isFormLoading || !isDirty}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdditionalInformationForm;
