import { useAppSelector } from "../../config/hooks";

const useDesignation = () => {
  const designationList = useAppSelector((state) => state.appConfig.designationList);

  const defaultDesignation = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Id: dafaultId, Title: dafaultId };
    }
    const selectedDesignation = designationList.find(
      (des) => des.Id == dafaultId
    );
    return selectedDesignation;
  };

  return { designationList, defaultDesignation };
};

export default useDesignation;
