import React, { useState } from "react";
import { useAppDispatch } from "../../config/hooks";
import { createContactApi } from "../../slices/salesSlice";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

interface ContactDetail {
  name: string;
  email: string;
  countryCode: number;
  phoneNumber: number;
  designation: string;
  isPrimary: boolean;
}

const ContactDetailForm = ({ togglePopupHandler }) => {

  const dispatch=useAppDispatch()
  const clientId=useParams()
  const [contactDetail, setContactDetail] = useState<ContactDetail>({
    name: "",
    email: "",
    countryCode: 91,
    phoneNumber: 0,
    designation: "",
    isPrimary: false,
  });

  const [isUpdate, setIsUpdate] = useState(false); // Determine if it's an update or create

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type, checked } = e.target;
    setContactDetail({
      ...contactDetail,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(contactDetail)
    contactDetail.phoneNumber=Number(contactDetail.phoneNumber)
    
    console.log(contactDetail)
    // const apiEndpoint = isUpdate ? "/api/updateContact" : "/api/createContact";
    // const method = isUpdate ? "PUT" : "POST";

    try {
      dispatch(createContactApi({clientId:clientId.clientId,...contactDetail}))
      toast.success("Contact Created Successfully")
      togglePopupHandler(false)
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error submitting the form.");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-[50%] mx-auto p-4 bg-white shadow-md rounded-lg"
    >
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Name:</label>
        <input
          type="text"
          name="name"
          value={contactDetail.name}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Email:</label>
        <input
          type="email"
          name="email"
          value={contactDetail.email}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          required
        />
      </div>

      <div className="flex gap-2">
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">
            Country Code:
          </label>
          <input
            type="number"
            name="countryCode"
            value={contactDetail.countryCode}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            required
          />
        </div>

        <div className="mb-4 flex-1">
        <label className="block text-gray-700 font-bold mb-2">
          Phone Number:
        </label>
        <input
          type="text" // Change to text to handle validation manually
          name="phoneNumber"
          value={contactDetail.phoneNumber}
          minLength={10}
          onChange={(e) => {
            const value = e.target.value;
            // Allow only numeric values and ensure length is <= 10
            if (/^\d*$/.test(value) && value.length <= 10) {
              handleChange(e); // Update the state only if validation passes
            }
          }}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          required
        />
        {contactDetail?.phoneNumber.toString().length > 0 && contactDetail?.phoneNumber.toString().length !== 10 && (
          <p className="text-red-500 text-sm mt-1">Phone number must be 10 digits long.</p>
        )}
      </div>

      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">
          Designation:
        </label>
        <input
          type="text"
          name="designation"
          value={contactDetail.designation}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
        />
      </div>

      <div className="mb-4">
        <label className="flex items-center text-gray-700 font-bold">
          <input
            type="checkbox"
            name="isPrimary"
            checked={contactDetail.isPrimary}
            onChange={handleChange}
            className="mr-2 focus:ring focus:ring-blue-300"
          />
          Is Primary Contact
        </label>
      </div>

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
      >
        Submit
      </button>
    </form>
  );
};

export default ContactDetailForm;
