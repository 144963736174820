import React from "react";
import Login from "../../../../components/login/Login";

const RecruiterLogin = () => {
  return (
    <div className="h-full flex">
      <div className="w-full"></div>
      <div className="h-full w-full flex justify-center pt-10">
        <Login
          title="Login As a Recruiter"
          redirectTo="/recruiter/register"
          userType="recruiter"
        />
      </div>
    </div>
  );
};

export default RecruiterLogin;
