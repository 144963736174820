import Pdf from "../pdf-viewer/Pdf";
import DownloadIcon from "../../assets/Icons/DownloadIcon";
import { toast } from "sonner";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as constants from "../../constant";
import { useAppSelector } from "../../config/hooks";

const ResumeTab = () => {
  const { state } = useLocation();

  const token = useAppSelector((state) => state?.login?.session?.token);
  const toggleSwitch = useAppSelector(
    (state) => state.jobs.isCandidateViewEnable
  );

  const handleDownload = async () => {
    if (toggleSwitch) {
      if (state?.candidateAddInfo?.url) {
        axios
          .get(`${constants.baseUrl}/${state?.url}?t=${token}`, {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          })
          .then((response) => {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(response.data);
            a.href = url;
            a.download = state?.name;
            a.click();
          })
          .catch((err) => {
            toast.error("Unable to download resume");
          });
      }
    } else if (state?.candidate?.attachment?.url) {
      axios
        .get(
          `${constants.baseUrl}/${state?.candidate?.attachment?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = state?.candidate?.attachment?.name;
          a.click();
        })
        .catch((err) => {
          toast.error("Unable to download resume");
        });
    }
    // else if (
    //   (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
    //     memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
    //   loginSelector?.who === constants.RECRUITER.toLowerCase()
    // ) {
    //   axios
    //     .get(
    //       `${constants.baseUrl}/${candidate?.applications[0]?.attachment?.url}?t=${token}`,
    //       {
    //         headers: {
    //           "Content-Type": "application/octet-stream",
    //         },
    //         responseType: "blob",
    //       }
    //     )
    //     .then((response) => {
    //       const a = document.createElement("a");
    //       const url = window.URL.createObjectURL(response.data);
    //       a.href = url;
    //       a.download = candidate?.applications[0]?.attachment?.name;
    //       a.click();
    //     })
    //     .catch((err) => {});
    // }
  };

  return (
    <div className="">
      <div className="flex items-center justify-between mb-2">
        <button
          className="w-fit ml-auto p-1 border-2 border-secondary-400 mr-4 rounded-md active:scale-95"
          onClick={handleDownload}
        >
          <DownloadIcon />
        </button>
      </div>

      {toggleSwitch ? (
        <div className="w-fit mx-auto h-screen overflow-y-auto no-scrollbar">
          <Pdf
            src={`${constants.baseUrl}/${state?.candidateAddInfo?.url}?t=${token}`}
          />
        </div>
      ) : (
        <div className="w-fit mx-auto h-screen overflow-y-auto no-scrollbar">
          <Pdf
            src={`${constants.baseUrl}/${state?.candidate?.attachment?.url}?t=${token}`}
          />
        </div>
      )}
    </div>
  );
};

export default ResumeTab;
