import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../../../components/basic-components/ExInput";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import * as constants from "../../../../../constant";
import { createMemberApi } from "../../../../../slices/companyMemberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import CrossIcon from "../../../../../assets/Icons/CrossIcon";
import CustomDatePicker from "../../../../../components/basic-components/CustomDatePicker";
import ExMultiDropdown from "../../../../../components/basic-components/ExMultiDropdown";
//import ExMultiDropdown from "../../../components/basic-components/ExMultiDropdown";


const CreateMember = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const companySelector = useAppSelector(
    (state) => state.company.companyDetails
  );
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const bandList = useAppSelector(
    (state) => state?.appConfig?.bandList
  );

  const gender = useAppSelector(
    (state) => state?.appConfig?.genderList
  );

  const roles = useAppSelector(
    (state) => state?.appConfig?.roleList
  );
  const designationList = useAppSelector(
    (state) => state?.appConfig?.designationList
  );
  const departmentList = useAppSelector(
    (state) => state?.appConfig?.departmentList
  );

  const members = useAppSelector((state) => state.member.members);

  const list = members.map((mem) => {
    return { Name: `${mem.firstName} ${mem.lastName}`, Id: mem.id };
  })


  const domain = useMemo(() => {
    if (
      loginSelector.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector.emailId
    ) {
      const email = memberSelector.emailId.match(
        /@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/
      );
      if (email.length > 0) {
        return email[0] ? email[0] : "";
      }
      return "";
    } else if (
      loginSelector.who === constants.COMPANY.toLowerCase() &&
      companySelector?.domain
    ) {
      return `@${companySelector.domain}`;
    }
    return "";
  }, [companySelector?.domain, loginSelector?.who, memberSelector?.emailId]);

  const form = useForm({
    defaultValues: {
      emailId: "",
      role: "",
      firstName: "",
      lastName: "",
      mobileNo: null,
      departments: "",
      joiningDate:null,
      reportingManager: "",
      employeeCode: "",
      band: "",
      jobProfile:""
    },
  });
  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  const onSubmit = async (formData) => {
    try {
      await dispatch(
        createMemberApi({
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          emailId: formData?.emailId,
          designation: formData?.role?.Title,
          departments:formData?.departments?.map((dep)=>{return dep.Title}),
          reportingManager:formData?.reportingManager.Id,
          joiningDate:formData?.joiningDate,
          companyId:
            loginSelector.who === constants.COMPANY.toLowerCase()
              ? userId
              : memberSelector?.companyId,
          authorId: userId,
          authortype: loginSelector?.who?.toUpperCase(),
          mobileNo: parseInt(formData?.mobileNo),
          gender: formData?.gender?.Title,
          employeeCode: formData?.employeeCode,
          band: formData?.band?.Title,
          jobProfile:formData?.jobProfile?.Title
        })
      ).then(unwrapResult);
      toast.success("Meamber created successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
    onClose();
  };
  return (
    <div className="px-10 py-6 w-[70%] mx-auto bg-white shadow-md rounded-lg h-screenheight overflow-y-auto no-scrollbar">
      <div className="mb-5">
        <div className="flex flex-row items-center justify-between mb-2">
          <h1 className="text-xl text-primary-400 font-medium mb-1">
            Add New Employee
          </h1>
          <div onClick={() => onClose()}>
            <CrossIcon />
          </div>
        </div>
        <text className="text-gray-400">
          A unique Employee ID has been automatically assigned based on
          predefined rules. You can edit it if necessary.
        </text>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "First name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="First Name"
                placeholder="Type here..."
                name="firstName"
                onChange={onChange}
                value={value}
                error={errors?.firstName?.message}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Last name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Last Name"
                placeholder="Type here..."
                name="lastName"
                onChange={onChange}
                value={value}
                error={errors?.lastName?.message}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="emailId"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Email ID is required",
              },
              pattern: {
                value:
                  /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                message: "Please enter a valid email ID",
              },
              validate: (value) => {
                // Ensure email ends with @aws.com
                return (
                  value.endsWith(domain) || `Email must end with ${domain}`
                );
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Official Email ID"
                placeholder="Type here..."
                name="emailId"
                onChange={onChange}
                value={value}
                error={errors?.emailId?.message}
              />
            )}
          />
          <Controller
            name="mobileNo"
            control={control}
            rules={{
              valueAsNumber: true,
              required: {
                value: true,
                message: "phoneNo is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Phone Number"
                placeholder="Type here..."
                name="mobileNo"
                onChange={onChange}
                value={value}
                error={errors?.mobileNo?.message}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="role"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Role is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Role"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={roles}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.role?.message}
              />
            )}
          />
          <Controller
            name="departments"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Department is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExMultiDropdown
                title="Department"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={departmentList}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.role?.message}

              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="gender"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Gender is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Gender"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={gender}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.gender?.message}
              />
            )}
          />

          <Controller
            name="reportingManager"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Reporting manager is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              // <ExInput
              //   title="Reporting Manager"
              //   placeholder="Type here..."
              //   name="reportingManager"
              //   onChange={onChange}
              //   value={value}
              //   error={errors?.reportingManager?.message}
              // />
              <ExDropdown
                title="Reporting Manager"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Name"
                options={list}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.band?.message}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="joiningDate"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Joining date is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                title="Date of Joining"
                name="joiningDate"
                onChange={onChange}
                selected={value ? new Date(value) : null}
                //maxDate={new Date()}
                //minDate={new Date()}
                showYearDropdown={true}
                dropdownMode="select"
                placeholderText="Ex. mm/dd/yyyy"
                popperPlacement="bottom-end"
                dateFormat="MM/dd/yyyy"
                showMonthYearPicker={false}
                error={errors?.joiningDate?.message}
              />
            )}
          />
          <Controller
            name="employeeCode"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Employee code is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Employee Code"
                placeholder="Type here..."
                name="employeeCode"
                onChange={onChange}
                value={value}
                error={errors?.employeeCode?.message}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="band"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Band is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Band"
                placeholder="Select band"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={bandList}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.band?.message}
              />
            )}
          />
          <Controller
            name="jobProfile"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Designation is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Designation"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={designationList}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.role?.message}
              />
            )}
          />
        </div>
        {/* <div className="mb-2 pt-3">
          <text className="text-gray-400 text-sm">
            An email has been sent to [Candidate Name] with instructions for the
            next steps in the application process. Please monitor their progress
            and follow up if necessary.
          </text>
        </div> */}
        <div className="w-fit ml-auto mr-0 pt-3">
          <button
            type="submit"
            className="px-8 py-2 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateMember;
