import React from 'react'
import { useAppSelector } from '../../config/hooks'

const useSalesMultipleDropdown = () => {
  const {salesEngangementType}=useAppSelector((state)=>state.appConfig)

const defaultSalesEngagementType=(arr)=>{
    const value= salesEngangementType.filter((ele)=>arr?.includes(String(ele.Id))).map((item)=>item.Title).join(", ")
    return value
}
return {defaultSalesEngagementType}
}

export default useSalesMultipleDropdown
