import React, { useEffect, useMemo, useState } from "react";
import WorkPlaceIcon from "../../../assets/Icons/WorkPlaceIcon";
import { useNavigate } from "react-router-dom";

import CreateMember from "../../ats/pages/company-dashboard/components/CreateMember";
import EmployeeCommenView from "../components/EmployeeCommenView";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getAllAttendanceApi } from "../../../slices/attendanceSlice";
import moment from "moment";
import { getRecommendedJobApi } from "../../../slices/jobSlice";


const EmployeeAttendance = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const attendanceList = useAppSelector((state) => state?.attendance?.attendanceList);
  const [startDateSelected, setStartDateSelected] = useState(null);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let month = moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
    let year = moment().year();
    dispatch(
      getAllAttendanceApi(
        {
          dateFrom: moment(`01/${month}/${year}`).format('YYYY-MM-DD'),
          dateTo: moment().format('YYYY-MM-DD'),
          employeeId: userId
        }
      )
    )
  }, []);

  useEffect(() => {
    var timer = null;
    if (!startDateSelected) {
      timer = setInterval(() => setDate(new Date()), 1000)
    }
    return function cleanup() {
      clearInterval(timer)
    }
  });

  //console.log(date, 'this is selected Date', startDateSelected);

  // var startTime = moment(attendanceList[0].checkIn, "HH:mm:ss a");
  // var endTime = moment(attendanceList[0].checkOut, "HH:mm:ss a");
  // var dif = moment.duration(endTime.diff(startTime));
  // console.log(dif.hours(), "@@@@@@@@@@@@ difdifdifdif @@@@@@@@@@@@");
  // console.log(dif.minutes(), "@@@@@@@@@@@@ difdifdifdif @@@@@@@@@@@@");
  // console.log([dif.hours(), dif.minutes(), dif.seconds()].join(':'));
  // console.log('dif in Mins: ', (dif.hours() * 60) + dif.minutes());

  return (
    <>
      <EmployeeCommenView />
      <div className="mt-3">
        <h1 className="text-lg text-exgray-300">Attendance Details</h1>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full mt-2 text-center rounded-sm">
            <thead className="uppercase py-3">
              <tr className="bg-secondary-800 rounded-lg ">
                <th className="text-sm text-exwhite-100 font-medium py-3">Day</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Date</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">In-Time</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Out-Time</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Working hrs</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Status</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Approver</th>
              </tr>
            </thead>
            <tbody className="items-center justify-center ">
              {
                attendanceList?.map((attendance, index) => {
                  var startTime = moment(attendance.checkIn);
                  var endTime = moment(attendance.checkOut);
                  var dif = moment.duration(endTime.diff(startTime));
                  console.log(dif, "@@@@@@@@@@@@ difdifdifdifdifdifdifdif ########");
                  return (
                    <tr key={attendance.id} className="items-center border-b border-l border-r border-exgray-100">
                      <td className="items-center py-3">{moment(attendance.date).format('dddd')}</td>
                      <td className="items-center py-3 text-secondary-500 font-medium">{moment(attendance.date).format('DD/MM/YYYY')}</td>
                      <td className="items-center py-3">{moment(attendance.checkIn).format('LT')}</td>
                      <td className="items-center py-3">{attendance?.status ? moment(attendance.checkOut).format('LT') : ''}</td>
                      <td className="items-center py-3">{attendance?.status ? `${dif.hours()}:${dif.minutes() < 10 ? "0" + dif.minutes() : dif.minutes()}` : ''}</td>
                      <td className="items-center py-3">{attendance?.status}</td>
                      <td className="items-center py-3">HR</td>
                    </tr>
                  )
                })
              }


            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};

export default EmployeeAttendance;
