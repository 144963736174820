import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { createInteractionApi } from '../../slices/salesSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import DatePicker from "react-datepicker";

const InteractionForm = ({togglePopupHandler}) => {
    const {userId}=useAppSelector((state)=>state.login?.session) 
    const dispatch=useAppDispatch()
    const clientId=useParams()
    const [formData, setFormData] = useState({
        // interactionDate: new Date().toISOString().slice(0,16),
        interactionDate: new Date(),
        interactionPerson: "",
        remarks: "",
        action: "",
        // nextFollowUpDate: new Date().toISOString().slice(0, 16),
        nextFollowUpDate: new Date(),
        clientId:clientId.clientId,
        userId:""
      });
    
      const [isUpdate, setIsUpdate] = useState(false); // Determine if it's an update or create
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
      
      const handleDateChange = (date, name) => {
        setFormData({
            ...formData,
            [name]: date
        });
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
          
        // const localDateInteract = moment(formData.interactionDate, "YYYY-MM-DD").local();  // Local IST date
        // formData.interactionDate = localDateInteract.format("YYYY-MM-DDTHH:mm:ss[Z]");
        
        // const localDateFollow = moment(formData.nextFollowUpDate, "YYYY-MM-DD").local();  // Local IST date
               // formData.interactionDate = formData.interactionDate + ":00Z";
               // formData.nextFollowUpDate = formData.nextFollowUpDate + ":00Z";
        // formData.interactionDate = "2024-12-23T00:00:00Z";
        // formData.nextFollowUpDate = "2024-12-23T00:00:00Z";
        formData.clientId=clientId.clientId
        formData.interactionPerson = formData.interactionPerson?formData.interactionPerson.split(","):[] 
        formData.interactionDate = formData.interactionDate.toISOString() ;
        formData.nextFollowUpDate = formData.nextFollowUpDate.toISOString() ;
        formData.userId=userId
        console.log("formData Interaction",formData)
        togglePopupHandler()
        try {
          dispatch(createInteractionApi(formData))
          
        } catch (error) {
          console.error("Error:", error);
          alert("There was an error submitting the form.");
        }
      };
    
      return (
        <form onSubmit={handleSubmit} className=" p-6 w-[70%] bg-white shadow-md rounded-lg">
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Interaction Date:</label>
            {/* <input
              type="datetime-local"
              name="interactionDate"
              value={new Date(formData.interactionDate).toISOString().slice(0,16)}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            /> */}
             <DatePicker
                    selected={formData.interactionDate}
                    onChange={(date) => handleDateChange(date, 'interactionDate')}
                    showTimeSelect
                    dateFormat="Pp"
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Next Follow-Up Date:</label>
            {/* <input
              type="datetime-local"
              name="nextFollowUpDate"
              value={new Date(formData.nextFollowUpDate).toISOString().slice(0, 16)}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            /> */}
            <DatePicker
                    selected={formData.nextFollowUpDate}
                    onChange={(date) => handleDateChange(date, 'nextFollowUpDate')}
                    showTimeSelect
                    dateFormat="Pp"
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                />
          </div>
    
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Interaction Person:</label>
            <input
              type="text"
              name="interactionPerson"
              value={formData.interactionPerson || ""}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
    
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Remarks:</label>
            <textarea
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
    
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Action:</label>
            <input
              type="text"
              name="action"
              value={formData.action}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
    
         
    
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          >
            Submit
          </button>
        </form>
      );
}

export default InteractionForm