import React from "react";
import SearchIcon from "../../../../../assets/Icons/SearchIcon";
import PlusCircleIcon from "../../../../../assets/Icons/PlusCircleIcon";
import FileIcon from "../../../../../assets/Icons/FileIcon";
import RightArrowIcon from "../../../../../assets/Icons/RightArrowIcon";
import DeleteIcon from "../../../../../assets/Icons/DeleteIcon";

const CompanyLegalDocs = () => {
  const onChange = (e) => {};

  return (
    <div className="min-h-screenheight bg-exwhite-100 p-4 space-y-4">
      <div className="flex items-center justify-between">
        <div className="w-[50%] flex items-center justify-start gap-4">
          <h1 className="text-lg text-exgray-900 font-semibold">
            Legal Documents
          </h1>
          <div className="flex-1 relative">
            <input
              type="text"
              name="title"
              onChange={onChange}
              // value={value}
              placeholder="Search docs"
              className="outline outline-2 outline-exgray-100 bg-transparent py-2 w-full text-base px-10 placeholder:text-exgray-100 rounded-full text-exgray-400"
            />
            <div className="absolute top-0 left-2 h-full flex items-center justify-center">
              <SearchIcon color="#042347" />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-4">
          <button
            className="flex items-center justify-center gap-2 px-8 py-2 bg-expurple-800 text-base text-exwhite-100 font-semibold rounded-md"
            //   onClick={togglePopupHandler}
          >
            <PlusCircleIcon color="white" /> Add
          </button>
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex items-center justify-start gap-4 px-4 py-2 border-b-2 border-exgray-100">
          <div className="w-fit">
            <FileIcon width="32" height="32" />
          </div>
          <div className="flex-1">
            <h1 className="text-base text-primary-900 font-semibold">
              Terms & Condition 2024.pdf
            </h1>
            <p className="text-base text-exgray-400">3.5 MB</p>
          </div>
          <div className="w-fit flex items-center justify-between gap-4">
            <button className="text-base text-[#0BA02C] bg-green-100 px-2 py-2 text-center rounded-md flex items-center justify-center gap-2 active:scale-90">
              <div className="rotate-90 flex-1">
                <RightArrowIcon color="#0BA02C" />
              </div>
            </button>

            <button className="flex-1">
              <DeleteIcon />
            </button>
          </div>
        </div>

        <div className="flex items-center justify-start gap-4 px-4 py-2 border-b-2 border-exgray-100">
          <div className="w-fit">
            <FileIcon width="32" height="32" />
          </div>
          <div className="flex-1">
            <h1 className="text-base text-primary-900 font-semibold">
              Employee Code Of Conduct 2024.pdf
            </h1>
            <p className="text-base text-exgray-400">4.5 MB</p>
          </div>
          <div className="w-fit flex items-center justify-between gap-4">
            <button className="text-base text-[#0BA02C] bg-green-100 px-2 py-2 text-center rounded-md flex items-center justify-center gap-2 active:scale-90">
              <div className="rotate-90 flex-1">
                <RightArrowIcon color="#0BA02C" />
              </div>
            </button>

            <button className="flex-1">
              <DeleteIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyLegalDocs;
