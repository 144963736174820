import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  addChat,
  chatRoomActive,
  checkConversationApi,
  createConversationApi,
  getAllConversationsApi,
  getAllMessageApi,
  getReceivedMessage,
} from "../../slices/chatSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  CheckConversationApiFulfilledAction,
  CreateConversation,
  CreateConversationAction,
} from "../../models/chat.model";
import { v4 as uuidv4 } from "uuid";
import { baseUrl, socketUrl } from "../../constant";
import CrossIcon from "../../assets/Icons/CrossIcon";
import SearchIcon from "../../assets/Icons/SearchIcon";
import MoreFriends from "../../assets/Icons/MoreFriends";
import { getMembersApi } from "../../slices/companyMemberSlice";

import * as constants from "../../constant";
import MessageInboxItem from "./MessageInboxItem";

const ChatRoom = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userId, token } = useAppSelector((state) => state.login.session);
  const { conversations } = useAppSelector((state) => state.chat);
  const { members } = useAppSelector((state) => state.member);
  const myNetworks = members;
  const [searchText, setSearchText] = useState("");
  const [showNetwork, setShowNetwork] = useState(false);
  const [connection, setConnection] = useState(null);
  const [connectedWithSocket, setConnectedWithSocket] = useState(false);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state?.member);
  const [filteredList, setFilteredList] = useState([]);
  const [networkFilteredList, setNetworkFilteredList] = useState(myNetworks);

  const newCliId = uuidv4();
  useEffect(() => {
    // Filter conversations based on search text
    const filteredConv = conversations.filter((conv) =>
      conv.memberDetails.some(
        (member) =>
          member.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          member.lastName.toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredList(filteredConv);

    const filteredNewt = myNetworks?.filter(
      (net) =>
        net.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        net.lastName.toLowerCase().includes(searchText.toLowerCase())
    );
    setNetworkFilteredList(filteredNewt);

    if (!conversations.length) {
      setShowNetwork(true);
    }
  }, [searchText, conversations]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllConversationsApi());
        //  await dispatch(getAllMessageApi({conversationId:conversations[0]?.id}))

        //  dispatch(getMyNetworksApi())
        dispatch(
          getMembersApi({
            companyId:
              loginSelector.who === constants.COMPANY.toLowerCase()
                ? userId
                : memberSelector?.memberDetails?.companyId,
          })
        );
      } catch (err) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    var socket = new WebSocket(
      `${socketUrl}/v1/ws?token=${token}&uid=${userId}&cli=${newCliId}`
    );

    setConnection(socket);

    socket.onopen = () => {
      setConnectedWithSocket(true);
    };

    socket.onerror = () => {
      console.log("WebSocket error.");
    };

    socket.onclose = (e) => {
      console.log("websocket connection closed");
    };

    socket.onmessage = function (event) {
      const json = JSON.parse(event.data);
      // console.log(json, "@@@@@@@@@@@@@@@  jsonjsonjsonjsonjson");
      try {
        if ((json.event = "data")) {
          const messageData = JSON.parse(json.content);
          console.log(messageData, "json.data *********8");

          let content = {
            message: messageData.content.message,
            state: null,
            form: null,
          };

          if (userId !== messageData.sender) {
            dispatch(
              getReceivedMessage({
                createdAt: messageData.createdAt,
                id: messageData.id,
                conversationId: messageData.conversationId,
                sender: messageData.sender,
                content: {
                  message: messageData.content.message,
                },
              })
            );
          }
        }
      } catch (err) {
        console.log(err, "this is websocket connection err");
        console.log(err);
      }
    };
  }, []);

  const handleClick = async (otherUserId) => {
    const response = (await dispatch(
      checkConversationApi({ members: [userId, otherUserId] })
    )) as CheckConversationApiFulfilledAction;
    console.log("RESPONSE CHECK", response);
    if (response.payload === "No Conversations Found") {
      const newResponse = (await dispatch(
        createConversationApi({ members: [userId, otherUserId] })
      )) as CreateConversationAction;
      console.log(response, "CHECKNEWRESPONSE");
      console.log(newResponse.payload.data.id);
      setShowNetwork(false);
      dispatch(
        addChat({
          member: newResponse.payload.data.memberDetails.filter(
            (ele) => ele.userId !== userId
          ),
          conversationId: newResponse.payload.data.id,
        })
      );
      //  navigate(`/tab/messages/${newResponse.payload?.data.id}`)
    } else {
      setShowNetwork(false);
      dispatch(
        addChat({
          member: response.payload.data.memberDetails.filter(
            (ele) => ele.userId !== userId
          ),
          conversationId: response.payload.data.id,
        })
      );
      // navigate(`/tab/messages/${response.payload?.data.id}`)
    }
  };

  const handleChatMessage = (member, conversationId) => {
    console.log(member, ":member", conversationId, ":conversationID");
    dispatch(addChat({ member: member, conversationId: conversationId }));
  };

  const handleChatRoom = () => {
    dispatch(chatRoomActive());
  };

  return (
    <div
      style={{ height: `calc(100vh - 18vh)` }}
      className="bg-exwhite-100  w-72 2xl:w-[380px]  overflow-x-scroll no-scrollbar rounded-t-lg border border-lxgray-500 shadow-sm shadow-golden"
    >
      <div className="flex items-center justify-between px-4 py-4 bg-lxgray-800 border-b border-lxgray-500 bg-primary-700">
        <h3 className="text-exwhite-100 text-base font-semibold">Chat Room</h3>
        {/* <EditDots/> */}
        <div onClick={handleChatRoom} className="cursor-pointer ">
          <CrossIcon width="13" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="relative py-4 px-4 flex-1">
          <input
            type="text"
            placeholder="Search Here"
            className="w-full border border-gray-400 rounded-full py-1 px-4  text-exgray-500"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div className="absolute top-5 right-6">
            <SearchIcon />
          </div>
        </div>
        <div
          onClick={() => setShowNetwork(!showNetwork)}
          className={`${
            !showNetwork ? "" : "bg-secondary-100"
          } rounded-full px-4 py-3 mr-2 cursor-pointer `}
        >
          <MoreFriends />
        </div>
      </div>

      {!showNetwork && (
        <div>
          {filteredList.map(
            (ele) => (
              // <Link key={ele.id} to={`/tab/messages/${ele.id}` }>
              <div
                onClick={() =>
                  handleChatMessage(
                    ele.memberDetails.filter((ele) => ele.userId !== userId),
                    ele.id
                  )
                }
              >
                <MessageInboxItem
                  lastMessage={ele?.lastMessage}
                  time={ele?.createdAt}
                  memberDetails={ele?.memberDetails.filter(
                    (ele) => ele.userId !== userId
                  )}
                />
              </div>
            )
            // </Link>
          )}
        </div>
      )}

      {showNetwork && (
        <div>
          <h1 className="text-exgray-200  text-sm pl-4">Your Connections</h1>
          {networkFilteredList.map(
            (ele) =>
              ele?.firstName &&
              ele?.id !== userId && (
                <div
                  onClick={() => handleClick(ele?.id)}
                  key={ele.id}
                  className="flex gap-4 items-center px-4 py-3 border-b border-lxgray-400 hover:bg-lxgray-400 cursor-pointer "
                >
                  <div>
                    {ele?.picUrl ? (
                      <img
                        className="w-12 h-12 object-cover border rounded-full"
                        src={`${baseUrl}/files/${ele?.picUrl}?t=${token}`}
                        alt="Profile"
                      />
                    ) : (
                      // <AlternateProfile  firstName={ele.otherUserDetails.firstName} lastName={ele.otherUserDetails.lastName} width='12' height='12'/>}
                      <div className="h-12 w-12 rounded-full bg-primary-400 flex items-center justify-center text-2xl text-exwhite-100 font-semibold ">{`${ele.firstName[0].toUpperCase()}${ele.lastName[0].toUpperCase()}`}</div>
                    )}
                  </div>
                  <div>
                    <h1 className="text-primary-500 text-lg">
                      {`${ele?.firstName} ${ele?.lastName}`}{" "}
                    </h1>
                    <h3 className="text-exgray-300 text-sm">{ele?.company}</h3>
                    <h3 className="text-exgray-300 text-xs">
                      {ele?.departments}{" "}
                    </h3>
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default ChatRoom;
