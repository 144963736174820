import React, { useEffect, useRef, useState } from "react";

export interface ProfileAvatarProps {
  firstName: string;
  lastName: string;
}

const ProfileAvatar = ({
  firstName,
  lastName
}: ProfileAvatarProps) => {

  return (
    <div className="h-20 w-20 rounded-full bg-primary-400 flex items-center justify-center text-3xl text-exwhite-100 font-semibold">
      {`${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`}
    </div>
  );
};

export default ProfileAvatar;
