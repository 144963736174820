import React from "react";
import { Link } from "react-router-dom";

const ClientCard = ({ clientName,domain }) => {
  return (
   
    <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center text-center border border-gray-200 h-full">
      <img
      src={"https://pic.onlinewebfonts.com/thumbnails/icons_148020.svg"}
      alt={`logo`}
      className="w-12 h-12 object-contain mb-4"
    />
      <h3 className="text-lg font-semibold">{clientName}</h3>
      <h3 className="text-sm text-exgray-500">{domain}</h3>
      {/* <p className="text-sm text-gray-600">{openings} Openings</p> */}
      {/* <p className="text-sm text-gray-600">{candidatesInProgress} Candidate in progress</p> */}
    </div>

  );
};

export default ClientCard;
