import { useAppSelector } from "../../config/hooks"


const useSalesDropdown = () => {
    const {salesIndustry,salesSubIndustry}=useAppSelector((state)=>state.appConfig)

    const defaultSalesIndustry=(id)=>{
        return salesIndustry.find((ele)=>ele.Id==id)?.Title
    }

    const defaultSalesSubIndustry=(id)=>{
        return salesSubIndustry.find((ele)=>ele.Id==id)?.Title
    }
   
    return {defaultSalesIndustry,defaultSalesSubIndustry}
  
};

export default useSalesDropdown
