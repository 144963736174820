import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useAppSelector } from "../../../../../config/hooks";
import Popup from "../../../../../components/popup/Popup";
import SkillsForm from "../forms/SkillsForm";

const SkillSection = () => {
  const [togglePopup, setTogglePopup] = useState(false);

  const userSkills = useAppSelector(
    (state) => state.candidateProfile?.userDetails?.skills
  );

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };
  const closePopupHandler = () => {
    setTogglePopup(false);
  };

  return (
    <div className="">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h1 className="text-lg text-black text-semibold text-start">
            Skills
          </h1>
          <button
            type="button"
            className="text-sm text-expurple-800 font-medium"
            onClick={togglePopupHandler}
          >
            <span className="font-bold text-lg">+</span> Add new skill
          </button>
        </div>

        <div className="flex flex-wrap gap-3">
          {userSkills?.map((skill) => (
            <div
              className="px-8 py-2 border-2 border-expurple-800 rounded-lg text-center text-base text-expurple-800 font-semibold"
              key={uuidv4()}
            >
              {skill}
            </div>
          ))}
        </div>
      </div>

      <Popup onClose={closePopupHandler} visible={togglePopup}>
        <SkillsForm onClose={closePopupHandler} />
      </Popup>
    </div>
  );
};

export default SkillSection;
