import React from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../../../components/basic-components/ExInput";
import ExMultiDropdown from "../../../../../components/basic-components/ExMultiDropdown";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import useMinExperiences from "../../../../../utils/dropdown-options/useMinExperiences";
import ExRadioBtnGroup from "../../../../../components/basic-components/ExRadioBtnGroup";
import useSalaries from "../../../../../utils/dropdown-options/useSalaries";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import {
  advanceJobSearchReducer,
  getRecommendedJobApi,
} from "../../../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const AdvanceFilterForm = ({ onClose = () => {} }) => {
  const dispatch = useAppDispatch();

  const jobData = useAppSelector((state) => state.jobs);

  const { industriesArray, defaultIndustries } = useIndustries();
  const { locationsArray, defaultLocations } = useLocations();
  const { minExperienceArray, defaultMinExperience } = useMinExperiences();
  const { minSalaries, defualtSalary } = useSalaries();

  const form = useForm({
    defaultValues: {
      title: jobData.advanceJobSearch ? jobData.advanceJobSearch.title : "",
      location: jobData.advanceJobSearch
        ? jobData.advanceJobSearch.location
        : null,
      minExperience: jobData.advanceJobSearch
        ? jobData.advanceJobSearch.minExperience
        : null,
      maxExperience: jobData.advanceJobSearch
        ? jobData.advanceJobSearch.maxExperience
        : null,
      workMode: jobData.advanceJobSearch
        ? jobData.advanceJobSearch.workMode
        : "Onsite",
      minSal: jobData.advanceJobSearch ? jobData.advanceJobSearch.minSal : null,
      maxSal: jobData.advanceJobSearch ? jobData.advanceJobSearch.maxsal : null,
      industry: jobData.advanceJobSearch
        ? jobData.advanceJobSearch.industry
        : null,
    },
  });

  const { handleSubmit, control, formState, reset, setValue } = form;
  const { errors, isDirty } = formState;

  const resetFormHandler = () => {
    reset({
      title: "",
      location: null,
      minExperience: null,
      maxExperience: null,
      workMode: "Onsite",
      minSal: null,
      maxSal: null,
      industry: null,
    });
    dispatch(advanceJobSearchReducer(null));
    dispatch(
      getRecommendedJobApi({
        status: "OPEN",
      })
    );
  };

  const onSubmit = async (formData) => {
    dispatch(advanceJobSearchReducer(formData));
    let locations = [];
    let industries = [];
    let workplaceType = "";
    if (formData.location) {
      locations = formData.location.map((loc) => loc.Id.toString());
    }

    if (formData.industry) {
      industries = formData.industry.map((ind) => ind.Id.toString());
    }

    if (formData.workMode) {
      workplaceType =
        formData.workMode === "Onsite"
          ? "1"
          : formData.workMode === "Hybrid"
          ? "2"
          : "3";
    }

    try {
      await dispatch(
        getRecommendedJobApi({
          status: "OPEN",
          s: formData.title,
          expMin: formData?.minExperience?.Id
            ? parseInt(formData?.minExperience?.Id)
            : null,
          expMax: formData?.maxExperience?.Id
            ? parseInt(formData?.maxExperience?.Id)
            : null,
          //   salaryMin: formData?.minSal?.Id
          //     ? parseInt(formData?.minSal?.Id)
          //     : null, //not working
          //   salaryMax: formData?.minSal?.Id
          //     ? parseInt(formData?.minSal?.Id)
          //     : null, // not working
          location: locations.length > 0 ? locations : null,
          workplaceType: workplaceType,
          industries: industries.length > 0 ? industries : null,
        })
      ).then(unwrapResult);
      onClose();
    } catch (error) {}
  };

  return (
    <div className="w-[70%] max-h-screenheight bg-expurple-200 p-10 rounded-xl space-y-2">
      <div>
        <h1 className="text-xl text-expurple-800 font-semibold text-start">
          Filter
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div>
          <Controller
            name="title"
            control={control}
            rules={{
              required: {
                value: false,
                message: "Title is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Ex. React js developer"
                title="Title"
                name="title"
                value={value || ""}
                onChange={onChange}
                error={errors?.title?.message}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-2 auto-rows-auto gap-2">
          <div>
            <Controller
              name="location"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExMultiDropdown
                  title="Location"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={locationsArray}
                  closeMenuOnSelect={true}
                  dropDownHeight="auto"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  menuListHeight="250px"
                  error={errors?.location?.message}
                />
              )}
            />
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="flex-1 self-start">
              <Controller
                name="minExperience"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Experience is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Minimum Experience"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={minExperienceArray}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.minExperience?.message}
                  />
                )}
              />
            </div>

            <div className="flex-1 self-start">
              <Controller
                name="maxExperience"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Experience is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Maximum Experience"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={minExperienceArray}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.maxExperience?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="workMode"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Hiring is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExRadioBtnGroup
                  title="Work Mode"
                  onChange={onChange}
                  selectedOption={value}
                  options={["Onsite", "Hybrid", "Remote"]}
                  error={errors?.workMode?.message}
                />
              )}
            />
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="flex-1 self-start">
              <Controller
                name="minSal"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Last salary is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Minimum Salary"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={minSalaries}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.minSal?.message}
                  />
                )}
              />
            </div>

            <div className="flex-1 self-start">
              <Controller
                name="maxSal"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Last salary is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Maximum Salary"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={minSalaries}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.maxSal?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div>
          <Controller
            name="industry"
            control={control}
            rules={{
              required: {
                value: false,
                message: "required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExMultiDropdown
                title="Industry"
                onChange={onChange}
                placeholder="Select"
                value={value || null}
                optionValue="Id"
                optionLabel="Name"
                options={industriesArray}
                closeMenuOnSelect={true}
                dropDownHeight="auto"
                dropDownBorderWidth="0px"
                dropDownBorderColor="#696969"
                menuListHeight="250px"
                error={errors?.industry?.message}
              />
            )}
          />
        </div>

        <div className="py-2">
          <div className="w-fit ml-auto mr-0">
            <button
              type="button"
              className={`px-10 py-2 rounded-md text-expurple-800 text-base font-semibold bg-transparent`}
              onClick={resetFormHandler}
            >
              Clear
            </button>

            <button
              type="submit"
              className={`px-8 py-2 rounded-md text-exwhite-100 text-base font-semibold ${
                !isDirty ? "bg-exgray-200" : "bg-expurple-800"
              }`}
              disabled={!isDirty}
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdvanceFilterForm;
