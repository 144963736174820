import Dexie, { Table } from "dexie";

// Define the types for the data model
export interface AppToken {
  id?: number; // Optional, as it will be auto-incremented
  token: string;
}

// Create the Dexie database class with the appToken store
export class TokenRepo extends Dexie {
  appToken!: Table<AppToken>; // Define the type for the appToken store

  constructor() {
    super("exotalent");
    this.version(1).stores({
      appToken: "++id, token", // Primary key and indexed properties
    });
  }
}

// Create an instance of TokenRepo
export const tokenRepo = new TokenRepo();
