import React, { useEffect, useState } from "react";
import ClientCard from "./ClientCard";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getAllClientsApi, getNextClientsApi } from "../../slices/salesSlice";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
interface Client {
  name: string;
  logo: string; // URL of the logo image
  openings: number;
  candidatesInProgress: number;
}

const clientsData: Client[] = [
  {
    name: "Accenture",
    logo: "path/to/accenture-logo.png",
    openings: 562,
    candidatesInProgress: 20,
  },
  {
    name: "Godrej",
    logo: "path/to/godrej-logo.png",
    openings: 562,
    candidatesInProgress: 20,
  },
  {
    name: "Sopra Steria",
    logo: "path/to/sopra-steria-logo.png",
    openings: 562,
    candidatesInProgress: 20,
  },
  {
    name: "Agratas",
    logo: "path/to/agratas-logo.png",
    openings: 562,
    candidatesInProgress: 20,
  },
];

const ClientList = () => {
  const dispatch = useAppDispatch()
  const allClients = useAppSelector((state) => state.sales.clients)
  const { clients, metadata } = allClients || {}
  const [searchTerm, setSearchTerm] = useState<string>("");
  const filteredClients =
    clients &&
    clients?.filter((client) =>
      client.clientName.toLowerCase().includes(searchTerm.toLowerCase())
    );

  useEffect(() => {
    dispatch(getAllClientsApi());
  }, []);

  const getMoreDataHandler = () => {
    try {
      console.log("HIT NEXT URL")
      dispatch(getNextClientsApi());
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };
  return (
    <div className="p-6">
      {/* Header with search and add client button */}
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Search"
          className="border border-gray-300 rounded-md px-4 py-2 w-full max-w-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* Grid displaying client cards */}
      <InfiniteScroll
        className="no-scrollbar"
        dataLength={clients?.length || 0}
        next={() => getMoreDataHandler()}
        hasMore={allClients?.metadata?.nextResultURL ? true : false}
        // height={"calc(100vh - 155px)"}
        loader={""}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredClients?.length > 0 ? (

            filteredClients.map((client, index) => (
              <Link to={`/company-admin/sales/detail/${client.id}`}>
                <ClientCard key={client.id + index} {...client} />
              </Link>

            )

            )
          ) : (
            <p className="col-span-full text-center text-gray-500">
              No clients found.
            </p>
          )}
        </div>
      </InfiniteScroll>

    </div>
  );
};

export default ClientList;
