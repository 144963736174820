import { useAppSelector } from "../../config/hooks";

const useFunctions = () => {
  const functionsArray = useAppSelector((state) => state.appConfig.functions);

  const defaultFunction = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Id: dafaultId, Name: dafaultId };
    }
    const selectedFunction = functionsArray.find(
      (func) => func.Id == dafaultId
    );
    return selectedFunction;
  };

  const defaultFunctions = (dafaultIds) => {
    const selectedFunction = dafaultIds.map((id) => {
      if (isNaN(id)) {
        return { Id: id, Name: id };
      }

      const filteredFunction = functionsArray.find((func) => func.Id == id);
      return filteredFunction;
    });

    return selectedFunction;
  };

  return { functionsArray, defaultFunction, defaultFunctions };
};

export default useFunctions;
