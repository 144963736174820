import useReadMoreToggle from "../../../../../utils/custome-hooks/useReadMoreToggle";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { userExperienceDelete } from "../../../../../slices/candidateProfileSlice";
import ExperienceForm from "../forms/ExperienceForm";
import Popup from "../../../../../components/popup/Popup";
import { useEffect, useMemo, useRef, useState } from "react";
import VerticleThreeDotsIcon from "../../../../../assets/Icons/VerticleThreeDotsIcon";
import useLocations from "../../../../../utils/dropdown-options/useLocations";

const ExperienceCard = ({ expDetail }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.userId);
  const employmentTypes = useAppSelector((state) => state.appConfig.jobTypes);

  const { defaultLocation } = useLocations();

  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  const { userExperienceDetails, id, organization } = expDetail;

  const {
    location,
    title,
    startDate,
    endDate,
    description,
    employmentType,
    isCurrentRole,
  } = userExperienceDetails[0];

  const jobType = employmentTypes.find((option) => option.Id == employmentType);

  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(description);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggleModal(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const jobLocation = useMemo(() => {
    if (location) {
      return defaultLocation(location);
    }
    return null;
  }, [defaultLocation, location]);

  const deletedExperienceHandler = () => {
    dispatch(
      userExperienceDelete({
        infoType: "experience",
        userId: userId,
        id: id,
      })
    );
  };

  const toggleModalHandler = () => {
    setToggleModal(!toggleModal);
  };

  const togglePopupHandler = () => {
    toggleModalHandler();
    setTogglePopup(!togglePopup);
  };

  return (
    <div>
      <div className="p-4 bg-expurple-100 space-y-1">
        <div className="flex items-center justify-between relative">
          <h1 className="text-base text-expurple-800 font-semibold">{title}</h1>
          <div
            className="w-fit cursor-pointer"
            onClick={toggleModalHandler}
            ref={menuParentRef}
          >
            <VerticleThreeDotsIcon width="30" height="30" />
          </div>
          {toggleModal && (
            <div
              className="absolute top-6 right-3 bg-exwhite-100 py-2 divide-y-2 divide-exgray-100"
              ref={menuRef}
            >
              <button
                className="w-full py-1 font-semibold px-6 text-center text-expurple-600 text-base"
                onClick={togglePopupHandler}
              >
                Edit
              </button>
              <button
                className="w-full py-1 font-semibold px-6 text-center text-expurple-600 text-base"
                onClick={deletedExperienceHandler}
              >
                Delete
              </button>
            </div>
          )}
        </div>

        <div className="w-[98%] flex items-center justify-between">
          <h2 className="text-base text-expurple-600 font-normal">
            {organization}
          </h2>
          <div className="flex items-center justify-center gap-12">
            <h2>{jobType?.Title}</h2>
            {isCurrentRole ? (
              <h2 className="text-base text-expurple-600 font-normal">
                {`${startDate.month} ${startDate.year} - Present`}
              </h2>
            ) : (
              <h2 className="text-base text-expurple-600 font-normal">
                {`${startDate.month} ${startDate.year} - ${endDate.month} ${endDate.year}`}
              </h2>
            )}
          </div>
        </div>

        {jobLocation?.Name && (
          <h2 className="text-base text-expurple-600 font-normal">
            {jobLocation?.Name}
          </h2>
        )}

        <div>
          <p
            className={`text-sm text-expurple-600 font-normal ${
              showMore ? "line-clamp-none" : "line-clamp-3"
            }`}
            ref={contentRef}
          >
            {description}
          </p>
          {description && isOverflowing && (
            <div className="flex justify-end items-center">
              <button
                className="border-none text-black text-base font-normal w-[100px] text-center"
                onClick={textToggleHandler}
              >
                {showMore ? "View Less" : "View More"}
              </button>
            </div>
          )}
        </div>
      </div>
      <Popup onClose={() => setTogglePopup(false)} visible={togglePopup}>
        <ExperienceForm editExp={expDetail} />
      </Popup>
    </div>
  );
};

export default ExperienceCard;
