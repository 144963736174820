import Login from "../../../../components/login/Login";

const CandidateLogin = () => {
  return (
    <div className="h-full flex">
      <div className="w-full"></div>
      <div className="h-full w-full flex justify-center pt-10">
        <Login
          title="Login As a Candidate"
          redirectTo="/candidate/register"
          userType="user"
        />
      </div>
    </div>
  );
};

export default CandidateLogin;
