import React from "react";
const Address = ({ width = "100%", height = "full", mapLink }) => {
  return (
    <div>
      <iframe
        //   title="fasd"
        className="rounded-lg"
        src={mapLink}
        width={width}
        height={height}
        // style="border:0;"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};
export default Address;
