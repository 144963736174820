import React, { useEffect, useState } from "react";
import CandidateCard from "../../../../../components/candidate-card/CandidateCard";
import { v4 as uuid4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import {
  getApplicantsApi,
  getNextApplicantsApi,
  getNextApplicantsCMApi,
  tagApplicantApi,
} from "../../../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAllReferralsApi } from "../../../../../slices/referSlice";
import * as constants from "../../../../../constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "../../../../../assets/Icons/SearchIcon";
import FilterIcon from "../../../../../assets/Icons/FilterIcon";

const JobApplicants = () => {
  const { jobId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const jobSelector = useAppSelector((state) => state.jobs.jobApplicants);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  const [appliedCadidateViaRefer, setAppliedCadidateViaRefer] = useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getApplicantsApi({
            jobId,
            recruiterId:
              loginSelector?.who === constants.COMPANY.toLowerCase() ||
              (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
                memberSelector?.designation === "Admin")
                ? ""
                : loginSelector.userId,
          })
        ).then(unwrapResult);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getMoreDataHandler = () => {
    try {
      dispatch(getNextApplicantsApi());
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  const onChange = (e) => {
    console.log("e.target.value :>> ", e.target.value);
  };

  const tagCandidatesHandler = async () => {
    if (selectedCandidates && selectedCandidates.length > 0) {
      for (let index = 0; index < selectedCandidates.length; index++) {
        try {
          dispatch(
            tagApplicantApi({
              applicantId: selectedCandidates[index].id,
              isTagged: true,
            })
          );
          toast.success("Candidate tagged successfully");
          navigate(`/company-admin/job/${jobId}/tagged-list`);
          setSelectedCandidates([]);
        } catch (error) {
          toast.error(
            `${selectedCandidates[index].author.firstName} ${selectedCandidates[index].author.lastName} - try again !`
          );
        }
      }
    }
  };

  const getMoreHandler = async () => {
    try {
      const response = await dispatch(
        getNextApplicantsCMApi({
          nextUrl: appliedCadidateViaRefer.metadata.nextResultURL,
          jobId: jobId,
          recruiterId: loginSelector?.userId,
        })
      ).then(unwrapResult);

      setAppliedCadidateViaRefer({
        referrals: [
          ...appliedCadidateViaRefer.referrals,
          ...response.data.data.referrals,
        ],
        metadata: response.data.data.metadata,
      });
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  if (jobSelector.error) {
    return <h1>Somthing went wrong</h1>;
  }

  if (
    loginSelector?.who === constants.COMPANY.toLowerCase() ||
    (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      !jobSelector.isLoading)
  ) {
    return (
      <div className="space-y-1">
        <div className="flex items-center justify-between my-3">
          <div className="w-[40%] flex items-center justify-start gap-2">
            <h1 className="text-base text-exgray-100 font-semibold">
              Source List
            </h1>

            {/* <div className="flex-1 relative pl-2">
              <input
                type="text"
                name="title"
                onChange={onChange}
                // value={value}
                placeholder="Search"
                className="outline outline-2 outline-exgray-100 bg-exwhite-100 py-2 w-full text-base pr-10 placeholder:text-exgray-100 rounded-full text-exgray-400 pl-5 focus:outline-secondary-300"
              />
              <div className="absolute top-0 right-2 h-full flex items-center justify-center">
                <SearchIcon color="#042347" />
              </div>
            </div>

            <div className="cursor-pointer">
              <FilterIcon />
            </div> */}
          </div>

          <button
            className="text-base border-2 border-primary-700 text-primary-700 font-semibold text-center px-4 py-1 rounded-full active:scale-95"
            onClick={tagCandidatesHandler}
          >
            Move Forward
          </button>
        </div>
        <div>
          <div className="flex items-center justify-start gap-4 px-4">
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Candidate Name
              </h1>
            </div>
            <div className="w-44">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1">
                Email Id
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Location
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Yrs of Experience
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Notice Period
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Current CTC
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Expected CTC
              </h1>
            </div>
          </div>
        </div>
        {!jobSelector.isLoading && (
          <InfiniteScroll
            className="no-scrollbar"
            dataLength={
              jobSelector?.applicants?.applications?.length
                ? jobSelector?.applicants?.applications?.length
                : 0
            }
            next={() => getMoreDataHandler()}
            hasMore={jobSelector?.applicants?.metadata?.nextResultURL}
            // hasMore={true}
            height={"calc(100vh - 155px)"}
            style={{ overflow: "auto" }}
            loader={""}
          >
            <div className="space-y-2">
              {jobSelector?.applicants?.applications?.map((candidate) => (
                <CandidateCard
                  candidate={candidate}
                  setSelectedCandidates={setSelectedCandidates}
                  selectedCandidates={selectedCandidates}
                  candidateDetails={candidate.author}
                  key={uuid4()}
                />
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
    );
  } else {
    return (
      <div className="space-y-1">
        <div className="flex items-center justify-between my-3">
          <div className="w-[40%] flex items-center justify-start gap-2">
            <h1 className="text-base text-exgray-100 font-semibold">
              Source List
            </h1>

            {/* <div className="flex-1 relative pl-2">
              <input
                type="text"
                name="title"
                onChange={onChange}
                // value={value}
                placeholder="Search"
                className="outline outline-2 outline-exgray-100 bg-exwhite-100 py-2 w-full text-base pr-10 placeholder:text-exgray-100 rounded-full text-exgray-400 pl-5 focus:outline-secondary-300"
              />
              <div className="absolute top-0 right-2 h-full flex items-center justify-center">
                <SearchIcon color="#042347" />
              </div>
            </div>

            <div className="cursor-pointer">
              <FilterIcon />
            </div> */}
          </div>

          <button
            className="text-base border-2 border-primary-700 text-primary-700 font-semibold text-center px-4 py-1 rounded-full active:scale-95"
            onClick={tagCandidatesHandler}
          >
            Move Forward
          </button>
        </div>
        <div>
          <div className="flex items-center justify-start gap-4 px-4">
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Candidate Name
              </h1>
            </div>
            <div className="w-44">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1">
                Email Id
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Location
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Yrs of Experience
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Notice Period
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Current CTC
              </h1>
            </div>
            <div className="flex-1">
              <h1 className="text-satrt text-base text-exgray-200/70 font-semibold text-start py-1 ">
                Expected CTC
              </h1>
            </div>
          </div>
        </div>
        {appliedCadidateViaRefer && (
          <InfiniteScroll
            className="no-scrollbar"
            dataLength={
              appliedCadidateViaRefer?.referrals?.length
                ? appliedCadidateViaRefer?.referrals?.length
                : 0
            }
            next={() => getMoreHandler()}
            hasMore={appliedCadidateViaRefer?.metadata?.nextResultURL}
            // hasMore={true}
            // height={"calc(100vh - 155px)"}
            style={{ overflow: "visible" }}
            loader={""}
          >
            <div className="space-y-2">
              {appliedCadidateViaRefer?.referrals?.map((candidate) => {
                if (candidate.status !== "pending") {
                  return (
                    <CandidateCard
                      candidate={candidate}
                      setSelectedCandidates={setSelectedCandidates}
                      selectedCandidates={selectedCandidates}
                      // candidateDetails={candidate.author}
                      key={uuid4()}
                      // candidate={candidate}
                      // setAppliedCadidateViaRefer={setAppliedCadidateViaRefer}
                      // appliedCadidateViaRefer={appliedCadidateViaRefer}
                      candidateDetails={candidate.userDetails}
                      // key={uuid4()}
                    />
                  );
                }
              })}
            </div>
          </InfiniteScroll>
        )}
      </div>

      // appliedCadidateViaRefer && (
      // <InfiniteScroll
      //   className="no-scrollbar"
      //   dataLength={
      //     appliedCadidateViaRefer?.referrals?.length
      //       ? appliedCadidateViaRefer?.referrals?.length
      //       : 0
      //   }
      //   next={() => getMoreHandler()}
      //   hasMore={appliedCadidateViaRefer?.metadata?.nextResultURL}
      //   // hasMore={true}
      //   // height={"calc(100vh - 155px)"}
      //   style={{ overflow: "visible" }}
      //   loader={""}
      // >
      //   <div className="space-y-2">
      //     {appliedCadidateViaRefer?.referrals?.map((candidate) => {
      //       if (candidate.status !== "pending") {
      //         return (
      //           <CandidateCard
      //             candidate={candidate}
      //             setAppliedCadidateViaRefer={setAppliedCadidateViaRefer}
      //             appliedCadidateViaRefer={appliedCadidateViaRefer}
      //             candidateDetails={candidate.userDetails}
      //             key={uuid4()}
      //           />
      //         );
      //       }
      //     })}
      //   </div>
      // </InfiniteScroll>
    );
  }
};

export default JobApplicants;
