import { useAppSelector } from "../../config/hooks";

const useSalaries = () => {
  const minSalaries = useAppSelector((state) => state.appConfig.minSalaries);

  const defualtSalary = (defaultSalId) => {
    const defaultMinSalary = minSalaries.find(
      (sal) => sal.value == defaultSalId
    );
    return defaultMinSalary;
  };
  return { minSalaries, defualtSalary };
};

export default useSalaries;
