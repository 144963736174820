import React, { useState } from "react";
import Popup from "../../../../../components/popup/Popup";
import { useAppSelector } from "../../../../../config/hooks";
import ExperienceCard from "./ExperienceCard";
import { v4 as uuidv4 } from "uuid";
import ExperienceForm from "../forms/ExperienceForm";

const ExperienceSection = () => {
  const candidateExDetails = useAppSelector(
    (state) => state.candidateProfile.userExperience
  );
  const [togglePopup, setTogglePopup] = useState(false);

  const togglePopupHandler = (editExperienceDetails = null) => {
    setTogglePopup(!togglePopup);
  };

  const closePopupHandler = () => {
    setTogglePopup(false);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-lg text-black text-semibold text-start">
          Experience
        </h1>
        <button
          type="button"
          className="text-sm text-expurple-800 font-medium"
          onClick={togglePopupHandler}
        >
          <span className="font-bold text-lg">+</span> Add new experience
        </button>
      </div>

      <div className="space-y-2">
        {candidateExDetails?.map((expDetail) => (
          <ExperienceCard
            expDetail={expDetail}
            key={uuidv4()}
            togglePopupHandler={togglePopupHandler}
          />
        ))}
      </div>

      <Popup onClose={closePopupHandler} visible={togglePopup}>
        <ExperienceForm onClose={closePopupHandler} />
      </Popup>
    </div>
  );
};

export default ExperienceSection;
