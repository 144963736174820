import Select, { components } from "react-select";
import CheckedIcon from "../../assets/Icons/CheckedIcon";

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between">
        <p className="">{props.label}</p>

        <div
          className={`h-5 w-5 rounded-sm ${
            props.isSelected
              ? "bg-[#0A65CC] flex items-center justify-center"
              : "border border-exgray-300"
          }`}
        >
          {props.isSelected && (
            <div className="mt-[1px]">
              <CheckedIcon color="white" />
            </div>
          )}
        </div>
      </div>
    </components.Option>
  );
};

const ExMultiDropdown = ({
  title,
  error,
  optionValue,
  optionLabel,
  dropDownHeight = "3.5rem",
  dropDownBorderWidth = "2px",
  dropDownBorderColor = "#CDCED2",
  menuListHeight = "auto",
  isMandatory=false,
  ...props
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      height: dropDownHeight,
      //   maxWidth: "150px",
      fontSize: "16px",
      color: state.isSelected ? "#696969" : "#696969",
      border: `${dropDownBorderWidth} solid ${
        state.isFocused
          ? error
            ? "#dc2623"
            : dropDownBorderColor
          : error
          ? "#dc2623"
          : dropDownBorderColor
      }`,
      borderRadius: "6px",
      backgroundColor: "#FFFFFF",
      boxShadow: state.isFocused ? "none" : "none",
      ":hover": {
        border: `${dropDownBorderWidth} solid ${
          state.isFocused
            ? error
              ? "#dc2623"
              : dropDownBorderColor
            : error
            ? "#dc2623"
            : dropDownBorderColor
        }`,
      },
    }),
    // valueContainer: (provided) => ({
    //   ...provided,
    //   display: "flex",
    //   flexWrap: "nowrap", // Ensures selected options are displayed in a single row
    //   gap: "5px", // Adds space between the selected options
    //   overflow: "hidden", // Hides content that exceeds the container’s width
    //   alignItems: "center",
    // }),
    input: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#181919",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#181919",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      color: state.isSelected ? "#0A65CC" : "#0A65CC",
      backgroundColor: state.isSelected ? "#E7F0FA" : "#FFFFFF",
      ":hover": {
        backgroundColor: "#E7F0FA",
        color: "#0A65CC",
        cursor: "pointer",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 99999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: `${menuListHeight}`,
      "::-webkit-scrollbar": {
        width: 0,
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      // display: "none",
      color: "#CDCED2",
      ":hover": {
        color: "#CDCED2",
      },
    }),

    dropdownIndicatorContainer: (provided) => ({
      ...provided,
      cursor: "pointer",
      paddingRight: "8px",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      "& svg": {
        display: "none",
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#8C8C8C", // Adjust the placeholder text color
      fontSize: "16px", // Adjust the font size as needed
      // fontWeight: 400,
    }),
  };

  return (
    <div className="space-y-1">
      <label className={`text-exgray-200/70 text-base font-semibold`}>
        {title}{isMandatory && " *"}
      </label>
      <Select
        {...props}
        isMulti
        getOptionValue={(position) => position[optionValue]}
        getOptionLabel={(position) => position[optionLabel]}
        isClearable={true}
        backspaceRemovesValue={true}
        hideSelectedOptions={false}
        styles={customStyles}
        isSearchable={true}
        components={{ Option }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: "#FDCF57",
            primary: "black",
          },
        })}
      />
      {error && <p className="text-red-400">{error}</p>}
    </div>
  );
};

export default ExMultiDropdown;
