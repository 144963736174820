import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Team A",
    Team: 4000,
  },
  {
    name: "Team B",
    Team: 1398,
  },
  {
    name: "Team C",
    Team: 9800,
  },
  {
    name: "Team D",
    Team: 3908,
  },
  {
    name: "Team E",
    Team: 4800,
  },
  {
    name: "Team F",
    Team: 3800,
  },
  {
    name: "Team G",
    Team: 4300,
  },
];

const generateColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    colors.push(`hsl(${(i * 360) / numColors}, 70%, 50%)`); // Vary hue to create distinct colors
  }
  return colors;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#f5f5f5",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label">{`${label}`}</p>
        <p>{`Applications: ${payload[0].payload.Team}`}</p>
      </div>
    );
  }

  return null;
};

const Bargraph = () => {
  const colors = generateColors(data.length);
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          //   width={500}
          //   height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis
            axisLine={false}
            tickLine={false}
            label={{
              value: "Number of Application",
              angle: -90,
              position: "insideLeft",
              offset: -5,
              style: { textAnchor: "middle", fontSize: 14 },
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Bar dataKey="Team" radius={10} barSize={50}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % 20]} />
            ))}
          </Bar>
         </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default Bargraph;
