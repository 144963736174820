import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/popup/Popup";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getAllAttendanceApi, postAttedanceApi, updateOutTimeApi } from "../../../slices/attendanceSlice";
import DailyReportForm from "./DailyReportForm";
import * as constants from "../../../constant";


const EmployeeCommenView = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const [togglePopup, setTogglePopup] = useState(false);
  const [date, setDate] = useState(new Date());
  const token = useAppSelector((state) => state?.login?.session?.token);

  const [startDateSelected, setStartDateSelected] = useState(null);
  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  const postAttedance = (selectedDate) => {
    setStartDateSelected(selectedDate);
    dispatch(postAttedanceApi({
      employeeId: memberDetail.id,
      date: new Date(),
      checkIn: selectedDate,
      //checkOut: undefined,
      status: ""
    }))
    //getAllAttendence();
  }

  const updateEndTime = () => {
    console.log("you are in update end time");
    setTogglePopup(!togglePopup);
  }

  const markEndTime = () => {
    dispatch(updateOutTimeApi({
      employeeId: memberDetail.id,
      checkOut: new Date(),
      attendanceId: todayAttendance.id,
      status: "Present"
    }))
    setTogglePopup(!togglePopup);
    //getAllAttendence();
  }

  const getAllAttendence = () => {
    let month = moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
    let year = moment().year();
    dispatch(
      getAllAttendanceApi(
        {
          dateFrom: moment(`01/${month}/${year}`).format('YYYY-MM-DD'),
          dateTo: moment().format('YYYY-MM-DD'),
          employeeId: memberDetail.id
        }
      )
    )
  }

  const todayAttendance = useAppSelector((state) => state?.attendance?.todayAttendance);

  return (
    <>
      <div className="flex space-x-3">
        <div className="flex bg-exwhite-100 p-3 rounded-md w-5/12 items-center border border-secondary-200 shadow shadow-2">
          {memberDetail?.profileImage ?
            <img
              src={`${constants.baseUrl}/${memberDetail?.profileImage}?t=${token}`}
              alt="profile"
              className="h-20 w-20 rounded-full"
            />
            :
            <ProfileAvatar firstName={"Kailas"} lastName={"Surve"} />
          }
          <div className="mx-3">
            <h1 className="text-lg font-semibold text-secondary-700">{memberDetail?.firstName} {memberDetail?.lastName}</h1>
            <h1 className="text-base">{memberDetail?.jobProfile}</h1>
            <h1 className="text-sm text-secondary-800 font-medium">{memberDetail?.employeeCode}</h1>
            <h1 className="text-sm text-secondary-800 font-medium">Reporting Manager:</h1>
          </div>
        </div>
        <div className="bg-exwhite-100 px-5 py-3 w-4/12 rounded-md border border-secondary-200 shadow shadow-2">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl text-secondary-600 font-medium">
              {todayAttendance?.checkIn ?
                moment(todayAttendance?.checkIn).format('LTS')
                :
                moment(date).format('LTS')
              }
            </h1>
            <button
              disabled={todayAttendance?.checkIn}
              onClick={() => postAttedance(date)}
              className={`${todayAttendance?.checkIn ? "bg-exgray-400" : "bg-exgreen-200"} px-8 py-1 rounded-lg text-exwhite-100 text-lg`}>
              Start
            </button>
          </div>
          <div>
            <h1 className="text-base">
              Time-In
            </h1>
            <h1 className="text-sm text-exgray-400">
              {moment().format('LL')}
            </h1>
          </div>
        </div>
        <div className="bg-exwhite-100 px-5 py-3 w-4/12 rounded-md border border-secondary-200 shadow shadow-2">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl text-secondary-600 font-medium">
              {todayAttendance?.status ?
                moment(todayAttendance?.checkOut).format('LTS')
                :
                moment(date).format('LTS')
              }
            </h1>
            <button
              disabled={(!todayAttendance?.checkIn || todayAttendance?.status === "Present")}
              onClick={() => updateEndTime()}
              className={`${(todayAttendance?.checkIn != "" && todayAttendance?.status == "") ? "bg-exgreen-200" : "bg-exgray-400"} px-9 py-1 rounded-lg text-exwhite-100 text-lg`}>
              End
            </button>
          </div>
          <div>
            <h1 className="text-base text-exgray-200">
              Time-out
            </h1>
            <h1 className="text-sm text-exgray-400">
              {moment().format('LL')}
            </h1>
          </div>
        </div>
      </div >
      <div className="flex space-x-2 mt-3">
        <div className="flex bg-primary-200 py-3 px-7 rounded-md w-3/12 items-center border border-secondary-200 shadow shadow-2">
          <h1 className="text-2xl text-secondary-500 font-medium w-4/12">95%</h1>
          <div className="w-8/12">
            <h1 className="text-sm text-exblue-600 font-medium">
              Attandence Score
            </h1>
            <h1 className="text-xs">
              For last month
            </h1>
          </div>
        </div>
        <div className="flex bg-primary-200 py-3 px-7 rounded-md w-3/12 items-center border border-secondary-200 shadow shadow-2">
          <h1 className="text-2xl text-secondary-500 font-medium w-4/12">03</h1>
          <div className="w-8/12">
            <h1 className="text-sm text-exblue-600 font-medium">
              Late Arrivals
            </h1>
            <h1 className="text-xs">
              For this month
            </h1>
          </div>
        </div>
        <div className="flex bg-primary-200 py-3 px-7 rounded-md w-3/12 items-center border border-secondary-200 shadow shadow-2">
          <h1 className="text-2xl text-secondary-500 font-medium w-4/12">09</h1>
          <div className="w-8/12">
            <h1 className="text-sm text-exblue-600 font-medium">
              Working Hrs
            </h1>
            {/* <h1 className="text-xs">
              For last month
            </h1> */}
          </div>
        </div>
        <div className="flex bg-primary-200 py-3 px-7 rounded-md w-3/12 items-center border border-secondary-200 shadow shadow-2">
          <h1 className="text-2xl text-secondary-500 font-medium w-4/12">09</h1>
          <div className="w-8/12">
            <h1 className="text-sm text-exblue-600 font-medium">
              Leave Balance
            </h1>
            <h1 className="text-xs">
              Till this month
            </h1>
          </div>
        </div>
      </div>
      {todayAttendance?.checkIn &&
        <Popup onClose={togglePopupHandler} visible={togglePopup}>
          <DailyReportForm
            attendanceId={todayAttendance?.id}
            onClose={togglePopupHandler}
            updateEndTime={markEndTime}
          />
        </Popup>
      }

    </>
  );
};

export default EmployeeCommenView;
