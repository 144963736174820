//Base url for development
export const baseUrl = "https://api.dev.exotalent.in/files";
export const lxBaseUrl = "https://api.dev.linkcxo.com";

//common url for prod and dev -> do not comment
export const socketUrl = "wss://api.dev.linkcxo.com";

// Base url for production
// export const baseUrl = "https://api.exotalent.in/linkcxo";
// export const lxBaseUrl = "https://api.prod.linkcxo.com";

export const noco_token = `DHBMRWcAgKvOGQw531KFFF93raNueBkajh4vpQyvdWFd3UeE7HroIlig9sWpzTkZzzWvTxDhB0Wu3lFpy0m9MUbE5pmsn8RKZf2rgUe49GcWKxoyBbWBsSLVZpqflISx`;

export const exouserAuthLocalBaseURL = "http://localhost:8081";
export const exoCompanyAuthLocalBaseURL = "http://localhost:8083";
export const exoRecruiterAuthLocalBaseURL = "http://localhost:8084";

export const exouserLocalBaseURL = "http://localhost:8080";
export const exoJobsLocalBaseURL = "http://localhost:8086";
export const exoCompanyLocalBaseURL = "http://localhost:8082";
export const exoRecruiterLocalBaseURL = "http://localhost:8085";

export const ADMIN = "Admin";
export const JOB_MANEGMENT = "Job Management";
export const CANDIDATE_MANEGMENT = "Candidate Management";

export const APPLICATION_SEND = "APPLICATION_SEND";
export const HOLD = "HOLD";
export const SHORTLISTED = "SHORTLISTED";
export const REJECTED = "REJECTED";
export const WITHDRAW = "WITHDRAW";
export const APPLIED = "APPLIED";

export const COMPANY_MEMBER = "COMPANY_MEMBER";
export const COMPANY = "COMPANY";
export const RECRUITER = "RECRUITER";
export const USER = "USER";

export const IND_COUNTRYCODE = {
  Id: 99,
  Iso2: "IN",
  CountryName: "India",
  Iso3: "IND",
  PhoneCode: "91",
  PhoneCodeLabel: "IND +91",
};

export const ITEMS = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Autoliv.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Bosch.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Calsoft.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Jaguar Land Rover India.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Continuum.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Daimler.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/DMG MORI.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Hindustan Platinum.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Honda power pack.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Hyundai.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Inteva Products.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/ITL-logo-01.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/John Deere.png",
  },
];

export const ITMES2 = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Bajaj Auto.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Aditya%20birla.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Cummins.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Eaton.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/GMR.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Honda.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/ISS.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/MBRDI.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/PVR.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Schindler.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Wipro.png",
  },
];

export const ITEMS3 = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Kone%20Crane.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Mahindra & Mahindra.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Onward Technologies.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Rieter.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Satven.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Schlumberger.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Sun Mobility.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Tata Elxsi.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Tech Mahindra.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Weichai.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Yokogawa.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Zetwerk.png",
  },
];

export const CANDIDATE_STATUS = [
  //Stage 1
  "Sourced",
  "Submitted For Internal Review",
  "Advisor Approved",
  "Reject During Screening Stage",
  "On Hold During Screening Stage",
  //Stage 2
  "CV Shared With Client For Screening",
  "Shortlisted By Client",
  "Rejected By Client",
  "On Hold By Client",
  "Duplicate By Client",
  // Stage 3
  "L1 Interview Scheduled",
  "L1 Interview Pending",
  "L1 Interview Hold",
  "L1 Feedback Awaited",
  "L2 Interview Scheduled",
  "L2 Interview Pending",
  "L2 Interview Hold",
  "L2 feedback Awaited",
  "Final / HR Round Scheduled",
  "Final / HR Round Pending",
  "Final / HR Round Hold",
  "Final / HR Feedback Awaited",
  "Final Select",
  "Final Reject",
  "Final On Hold",
  // Stage 4
  "Documents Received (Lot I)",
  "CTC Discussion Done",
  "CTC Discussion Pending",
  "CTC Offer Released",
  "CTC Offer Accepted",
  "CTC Offer Rejected",
  "In Revised CTC Stage",
  "Final CTC Accepted",
  "Documents Received (Lot II)",
  "Medical Scheduled",
  "Medical Done",
  "Medical Fitment Report Awaited",
  "Medically Fit",
  "Formal Offer Letter / LOI Shared",
  "Formal Offer Letter / LOI Accepted",
  "Resigned",
  "Resignation Acceptance Received",
  "DOJ Confirmed By Client",
  "Joining Pending",
  "Joined",
  "Drop",
  "Withdraw",
  "Back out",
  "Replacement Clause Completed",
  // Stage 5
  "Position On Hold",
  "Position Closed By Other Sources",
  "Position Closed Internally",
];

export const NAVI_MUMBAI_ADDRESS =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471.342204083488!2d73.0189613463966!3d19.07526976742342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c16ad58b1f41%3A0x2404b72d3da5dcd4!2sArihant%20Aura%2C%20Thane%20-%20Belapur%20Rd%2C%20Turbhe%20MIDC%2C%20Turbhe%2C%20Navi%20Mumbai%2C%20Maharashtra%20400705!5e0!3m2!1sen!2sin!4v1736418829103!5m2!1sen!2sin";
