import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import JobCard from "../../job-search/components/JobCard";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getExternalRecruiterNextRefferedJobApi,
  getExternalRecruiterRefferedJobApi,
} from "../../../../../slices/referSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import NoDataFoundIcon from "../../../../../assets/Icons/NoDataFoundIcon";

const RecruiterJobsList = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  // const jobCards = useAppSelector((state) => state.jobs.jobCards);
  const refferredJobsSelector = useAppSelector(
    (state) => state.refer.refferedJobs
  );

  // const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getExternalRecruiterRefferedJobApi({ recruiterId: userId })
        ).then(unwrapResult);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const getMoreDataHandler = () => {
    try {
      dispatch(getExternalRecruiterNextRefferedJobApi({ recruiterId: userId }));
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  if (error) {
    return <h1>Something went wrong</h1>;
  }

  return (
    <div className="">
      {!refferredJobsSelector.isloading &&
        (!refferredJobsSelector?.jobs ||
          refferredJobsSelector?.jobs?.length === 0) && (
          <div className="py-10">
            <div className="flex items-center justify-center">
              <NoDataFoundIcon />
            </div>
            <div>
              <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                No Data Found
              </h1>
              {/* <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
      Find new opportunities and manage your job search progress here.
    </p> */}
            </div>
          </div>
        )}
      {!refferredJobsSelector.isloading && (
        <InfiniteScroll
          className="no-scrollbar"
          dataLength={
            refferredJobsSelector?.jobs?.length
              ? refferredJobsSelector?.jobs?.length
              : 0
          }
          next={() => getMoreDataHandler()}
          hasMore={refferredJobsSelector?.metadata?.nextURL}
          // height={"calc(100vh - 155px)"}
          loader={""}
        >
          {refferredJobsSelector?.jobs &&
            refferredJobsSelector?.jobs?.length > 0 && (
              <div className="flex flex-wrap mx-auto">
                {refferredJobsSelector?.jobs?.length > 0 &&
                  refferredJobsSelector?.jobs?.map((job) => (
                    <div className="w-[33.3333333%]" key={uuid4()}>
                      <Link to={`/job/${job.id}`}>
                        <JobCard job={job} />
                      </Link>
                    </div>
                  ))}
              </div>
            )}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default RecruiterJobsList;
