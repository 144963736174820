import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import CrossIcon from "../../../assets/Icons/CrossIcon";
import CheckedIcon from "../../../assets/Icons/CheckedIcon";
import { postAttedanceApi, postDailyReportApi } from "../../../slices/attendanceSlice";
import { useEffect } from "react";
import ReactQuill from 'react-quill'; // Import the ReactQuill component
import 'react-quill/dist/quill.snow.css';

export interface DailyReportProps {
  onClose: Function;
  attendanceId: string;
  updateEndTime: Function;
}

const DailyReportForm = ({ onClose, attendanceId, updateEndTime }: DailyReportProps) => {
  const navigate = useNavigate();
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const dailyReport = useAppSelector((state) => state.attendance.todaysReports);
  const dispatch = useAppDispatch();

  let reportArray = [];

  const form = useForm({
    defaultValues: {
      textInput: "",
    },
  });
  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  // useEffect(() => {
  //   dispatch(getAllDailyReport({
  //     employeeId: "",
  //     attendanceId: "",
  //     dailyReport: [],
  //     approvedBy: "",
  //     approvalStatus: ""
  //   }))
  // }, []);


  const onSubmit = async (formData) => {
    dispatch(postDailyReportApi({
      employeeId: memberDetail.id,
      attendanceId: attendanceId,
      dailyReport: [formData.textInput],
      approvedBy: "",
      approvalStatus: ""
    }))
    updateEndTime();
  };

  return (
    <div className="px-10 py-6 w-[70%] mx-auto bg-white shadow-md rounded-lg overflow-y-auto no-scrollbar">
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-xl text-primary-400 font-medium mb-1">
          Accomplished Task
        </h1>
        <div onClick={() => onClose()}>
          <CrossIcon />
        </div>
      </div>
      <div className="h-full flex-1 justify-between">

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 mt-4">
          <div className="flex items-center">
            <div className="w-full">
              <Controller
                name="textInput"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter some text in input box",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ReactQuill
                    value={value}
                    onChange={onChange}
                    theme="snow" // You can choose between 'snow' and 'bubble' themes
                  />
                )}
              />
            </div>
            {/* <button
              onClick={() => handleSubmit(onSubmit)}
              //disabled={(startDateSelected == null && todayAttendance?.length == 0 || (todayAttendance?.length > 0 && todayAttendance[0]?.status))}
              className={`bg-primary-500 w-2/12 h-11 rounded-lg text-exwhite-100 text-lg mt-6`}>
              Add
            </button> */}
          </div>
          <h1 className="text-red-400">{errors?.textInput?.message}</h1>
          <div className="flex items-end justify-end mt-8">
            <button
              onClick={() => handleSubmit(onSubmit)}
              //disabled={(startDateSelected == null && todayAttendance?.length == 0 || (todayAttendance?.length > 0 && todayAttendance[0]?.status))}
              className={`bg-primary-500 w-2/12 h-11 rounded-lg text-exwhite-100 text-lg`}>
              Submit
            </button>
          </div>
        </form>

      </div>

    </div>
  );
};

export default DailyReportForm;
