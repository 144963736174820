import React from "react";
import EditIcon from "../../assets/Icons/EditIcon";
import VerticleThreeDotsIcon from "../../assets/Icons/VerticleThreeDotsIcon";
import CrossIcon from "../../assets/Icons/CrossIcon";
import moment from "moment";


const Interaction = ({data}) => {
  
  return (
    <div className="bg-exwhite-100 border border-gray-300 rounded-lg p-6  mx-auto my-2">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4">
        <div className="text-secondary-500 text-xl font-semibold">{moment(data?.interactionDate).utc().format('D MMM YYYY, hh:mm A')}</div>
        
        <div className="flex gap-2"> 
         <h3 className="text-red-500 border border-red-200 px-4 rounded-lg bg-red-100">Call</h3>
              {/* <VerticleThreeDotsIcon/> */}
        </div>
      </div>

      {/* Participants */}
      <div className="flex items-center gap-[15%]">
        <div>
          <span className="text-exgray-400 ">Created by :</span>{" "}
          <span className="text-exgray-800 font-semibold">XXXXXXXX</span>
        </div>
      { data.interactionPerson.length>0 && 
        <div className=" flex  gap-2 items-center ">
          <span className="text-exgray-500 font-semibold">Participated :</span>
          <div className="flex flex-wrap gap-2 ">
           { data.interactionPerson.map((ele,i)=>{
            return (
              <span key={i+ele} className="border border-exgray-200 text-exgray-300 px-4 rounded-full text-sm">
              {ele}
             </span>
            )
           })
          }     
          </div>
        </div>}
      </div>

      {/* Title */}
      <div className=" text-exgray-500 text-lg font-semibold my-1">
        {data?.action}   
      </div>

      {/* Description */}
      <p className="text-exgray-500 text-sm mb-2">
       {data?.remarks}
      </p>

      {/* Attached Document */}
      <div className="flex items-center  gap-8 w-fit bg-secondary-200 py-1 px-3 rounded-lg mb-2">
        <div>
          <span className="font-semibold text-primary-700">DOC</span>
          <div className="text-gray-500 text-xs">Uploaded on 5th Feb 2023</div>
        </div>
        {/* <div>
            <CrossIcon color="#095BB8" width="12"/>
        </div> */}
      </div>

      {/* Follow-up */}
      <div>
        {/* <div className="text-primary-800 ">Follow-up</div> */}
        <div className="bg-exgray-100/25 flex justify-between gap-2  p-1 py-2 rounded">
          <div className="flex justify-between items-center">
            <h2 className="text-secondary-600 font-semibold ">
              Next Follow-up
            </h2>
            {/* <EditIcon/> */}
          </div>
          <div className="text-gray-600 text-sm border border-exgray-200 w-fit  rounded-full py-1 px-4 font-semibold ">
           {moment(data?.nextFollowUpDate).utc().format('D MMM YYYY, hh:mm A')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interaction;
