import React from "react";

const ExInput = ({
  isMandatory=false,
  title, 
  name, 
  error, 
  ...props 
}) => {
  return (
    <div className="space-y-1">
      <label
        htmlFor={name}
        className={`text-exgray-200/70 text-base font-semibold`}
      >
        {title}{isMandatory&& " *"}
      </label>
      <div>
        <input
          className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border ${
            error ? "border-[#dc2623]" : "border-exgray-200"
          } text-exgray-800 placeholder:text-gray-400 text-base`}
          id={name}
          autoComplete="off"
          {...props}
        />
      </div>
      {error && <p className="text-red-400">{error}</p>}
    </div>
  );
};

export default ExInput;
