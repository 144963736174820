import React, { useEffect, useState } from "react";
import EditIcon from "../../assets/Icons/EditIcon";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getClientByIdApi } from "../../slices/salesSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
import Popup from "../../components/popup/Popup";
import EditClientDetailForm from "./EditClientDetailForm";
import useSalesDropdown from "../../utils/dropdown-options/useSalesDropdown";
import useSalesMultipleDropdown from "../../utils/dropdown-options/useSalesMultipleDropdown";

const LeadCard = () => {
  const dispatch=useAppDispatch()
  const {clientDetail}=useAppSelector((state)=>state.sales)
  const [togglePopup, setTogglePopup] = useState(false);
  const [selectedStage, setSelectedStage] = useState<string>(clientDetail?.stages || "");
  const { salesStages } = useAppSelector((state) => state.appConfig);

  const clientId=useParams()  
    // useEffect(()=>{
    //   dispatch(getClientByIdApi(clientId.clientId))
    // },[])
  const {defaultSalesIndustry,defaultSalesSubIndustry}=useSalesDropdown()
  const {defaultSalesEngagementType}= useSalesMultipleDropdown()
  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  return (
    <div className="  bg-white shadow-lg rounded-lg border border-gray-200 min-w-96 px-4">
     <div className="flex justify-between items-center">
      <h2 className="text-3xl font-semibold text-blue-600 mb-2">{clientDetail?.clientName}</h2>
         <div onClick={togglePopupHandler}>
          <EditIcon/>
        </div>
     </div>
      <p className="text-sm text-exgray-400">{clientDetail?.location}</p>
      {/* <p className="text-sm font-semibold text-exgray-600">
        <span className="font-normal">Lead Owner:</span> Julie Sweet
      </p> */}
      <div className="mt-2">
        {/* <h3 className="text-sm text-exgray-800 mb-2">Basic Details:</h3> */}
        <p className="text-sm font-semibold text-exgray-600 ">
          <span className="font-normal">Lead Created Date:</span> {moment(clientDetail?.createdAt).format(' Do, MMMM YYYY')}
        </p>
        {/* <p className="text-sm font-semibold text-exgray-600">
          <span className="font-normal">Source of Lead:</span> Social Media
        </p> */}
        <p className="text-sm font-semibold text-exgray-600">
          <span className="font-normal">Industry:</span> {defaultSalesIndustry(clientDetail?.industry)}
        </p>
        <p className="text-sm font-semibold text-exgray-600">
          <span className="font-normal">Sub Industry:</span> {defaultSalesSubIndustry(clientDetail?.subIndustry)}
        </p>
        <p className="text-sm font-semibold text-exgray-600">
          <span className="font-normal">Engagement:</span> {defaultSalesEngagementType(clientDetail?.engagementType)}
        </p>
        {/* <p className="text-sm font-semibold text-exgray-600">
          <span className="font-normal">Services:</span> ET
        </p> */}
      </div>
      <div className="flex justify-center gap-4 items-center mt-4 bg-secondary-100  rounded-full ">
        <label className="text-exgray-300 " htmlFor="stage-select">
          Stage:{" "}
        </label>
        <select className="py-2 rounded bg-secondary-100 text-secondary-400 font-semibold" value={selectedStage} onChange={(e) => setSelectedStage(e.target.value)}>
          <option value="" className="text-center ">Select Stage</option>
          {salesStages.map((stage, index) => (
            <option className="text-center" key={index} value={stage.Title}>
              {stage.Title}
            </option>
          ))}
          </select>
      </div>  
      <Popup
            visible={togglePopup}
            onClose={togglePopupHandler}
            children={<EditClientDetailForm togglePopupHandler={togglePopupHandler}/>}
      />
    </div>
  );
};
export default LeadCard;