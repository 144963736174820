import React, { useState } from "react";
import MemberCard from "../../../../../components/company-member-card/MemberCard";
import { useAppSelector } from "../../../../../config/hooks";
import { v4 as uuid4 } from "uuid";

const CompanyMemberList = () => {
  const memberSelector = useAppSelector((state) => state.member);

  const [toggle, setToggle] = useState(0);

  const employeeTabs = [
    {
      title: "All (20)",
    },
    {
      title: "Full Time",
    },
    {
      title: "Contract",
    },
    {
      title: "Freelancer",
    },
    {
      title: "Terminated Employee",
    },
  ];

  const { members } = memberSelector || [];

  const toggleHandler = (index) => {
    setToggle(index);
  };

  return (
    <div className="space-y-4">
      {/* <div className="flex items-center justify-start gap-4">
        {employeeTabs.map((option, index) => (
          <button
            className={`py-1 px-5 ${
              toggle === index
                ? "text-primary-600 bg-primary-200"
                : "text-exgray-100"
            } text-base text-center font-semibold rounded-sm`}
            onClick={() => toggleHandler(index)}
            key={uuid4()}
          >
            {option.title}
          </button>
        ))}
      </div> */}
      {/* <div className="grid grid-cols-3 gap-4 md:grid-cols-[repeat(3,minmax(200px,1fr))] lg:grid-cols-[repeat(3,minmax(100px,1fr))] xl:grid-cols-[repeat(5,minmax(100px,1fr))] 2xl:grid-cols-[repeat(5,minmax(200px,1fr))] min-[1930px]:grid-cols-[repeat(6,minmax(100px,1fr))]">
        <MemberCard key={uuid4()} />
      </div> */}

      {toggle === 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {members &&
            members.length > 0 &&
            members.map((member) => (
              <MemberCard member={member} key={uuid4()} />
            ))}
        </div>
      )}
    </div>
  );
};

export default CompanyMemberList;
