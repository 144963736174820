import { useAppSelector } from "../../config/hooks";

const useCurrencies = () => {
  let currenciesArray = useAppSelector((state) => state.appConfig.currencies);

  const defaultCurrency = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Id: dafaultId, Title: dafaultId };
    }
    const selectedCurrency = currenciesArray.find(
      (currency) => currency.Id == dafaultId
    );
    return selectedCurrency;
  };

  // const defaultIndustries = (dafaultIds) => {
  //   const selectedIndustry = industriesArray.filter((industry) =>
  //     dafaultIds.some((obj2) => obj2 == industry.Id)
  //   );
  //   return selectedIndustry;
  // };

  const defaultCurrencies = (dafaultIds) => {
    const selectedCurrencies = dafaultIds.map((id) => {
      if (isNaN(id)) {
        return { Id: id, Title: id };
      }

      const filteredIndustry = currenciesArray.find(
        (location) => location.Id == id
      );
      return filteredIndustry;
    });

    return selectedCurrencies;
  };

  currenciesArray = currenciesArray.map((cur) => {
    return {
      Id: cur.Title,
      Title: cur.Title,
    };
  });

  return { currenciesArray, defaultCurrency, defaultCurrencies };
};

export default useCurrencies;
