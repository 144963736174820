import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../config/hooks';

const OnboardingTab = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { memberId } = useParams();

  const navigateHandler = (path) => {
    navigate(path);
  };
  
  const userId = useAppSelector((state) => state.login.session.userId);

  if(memberId === userId){
    return (
      <div className="flex w-full items-center justify-between mt-3 border-b border-exgray-100">
        <button
          className={`px-4 py-1 ${pathname === `/employee-dashboard/employee-profile/${memberId}`
            ? "border-b-2 border-primary-600 text-primary-600 font-semibold"
            : "text-exgray-200"
            } text-base text-center font-normal`}
          onClick={() => navigateHandler(`/employee-dashboard/employee-profile/${memberId}`)}
        >
          Document Review
        </button>
        <button
          className={`px-4 py-1 ${pathname === `/employee-dashboard/employee-profile/${memberId}/kyc-document`
            ? "border-b-2 border-primary-600 text-primary-600 font-semibold"
            : "text-exgray-200"
            } text-base text-center font-normal`}
          onClick={() => navigateHandler(`/employee-dashboard/employee-profile/${memberId}/kyc-document`)}
        >
          KYC Documents
        </button>
        <button
          className={`px-4 py-1 ${pathname === `/employee-dashboard/employee-profile/${memberId}/statutory-document`
            ? "border-b-2 border-primary-600 text-primary-600 font-semibold"
            : "text-exgray-200"
            } text-base text-center font-normal`}
          onClick={() => navigateHandler(`/employee-dashboard/employee-profile/${memberId}/statutory-document`)}
        >
          Statutory Details
        </button>
        {/* <div className="border-b-2 border-exgray-100 flex-1 self-end"></div> */}
      </div>
    )
  }
  else{
    return (
      <div className="flex w-full items-center justify-between mt-3 border-b border-exgray-100">
        <button
          className={`px-4 py-1 ${pathname === `/company-admin/employee-profile/${memberId}`
            ? "border-b-2 border-primary-600 text-primary-600 font-semibold"
            : "text-exgray-200"
            } text-base text-center font-normal`}
          onClick={() => navigateHandler(`/company-admin/employee-profile/${memberId}`)}
        >
          Document Review
        </button>
        <button
          className={`px-4 py-1 ${pathname === `/company-admin/employee-profile/${memberId}/kyc-document`
            ? "border-b-2 border-primary-600 text-primary-600 font-semibold"
            : "text-exgray-200"
            } text-base text-center font-normal`}
          onClick={() => navigateHandler(`/company-admin/employee-profile/${memberId}/kyc-document`)}
        >
          KYC Documents
        </button>
        <button
          className={`px-4 py-1 ${pathname === `/company-admin/employee-profile/${memberId}/statutory-document`
            ? "border-b-2 border-primary-600 text-primary-600 font-semibold"
            : "text-exgray-200"
            } text-base text-center font-normal`}
          onClick={() => navigateHandler(`/company-admin/employee-profile/${memberId}/statutory-document`)}
        >
          Statutory Details
        </button>
        {/* <div className="border-b-2 border-exgray-100 flex-1 self-end"></div> */}
      </div>
    )
  }

  
}

export default OnboardingTab
