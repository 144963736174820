import { AxiosPromise } from "axios";
import apiClient from "../config/axiosConfig";
import apiCompanyClient from "../config/local-axios-config/companyApiConfig";
import {
  DocumentUpload,
  GetEmployeeDetailRequest,
  getMemberReq,
  member,
  MemberUpdate,
  removeMemberReq,
  removeMemberRes,
} from "../models/companyMember.model";
import { ApiResponse } from "../models/base.model";
import { MemberDetail } from "../models/chat.model";

class CompanyMemberService {
  createMember(createMemberReq) {
    return apiClient.post(`/v1/exo/auth/company/member/register`, createMemberReq);
  }

  getMembers(req: getMemberReq): AxiosPromise<ApiResponse<Array<member>>> {
    return apiClient.get(`/v1/exo/companies/${req.companyId}/users`, {
      params: { authorId: req.authorId },
    });
  }

  getMemberById(req: GetEmployeeDetailRequest) {
    return apiClient.get(`/v1/exo/companies/users/${req.memberId}`);
  }

  removeMember(
    req: removeMemberReq
  ): AxiosPromise<ApiResponse<removeMemberRes>> {
    return apiClient.delete(`/v1/exo/companies/users/${req.memberId}`);
  }

  updateMember(req: MemberUpdate) {
    return apiClient.put(`/v1/exo/companies/users/${req.memberId}`, req);
  }

  uploadDocument(req: DocumentUpload) {
    return apiClient.post(`/v1/exo/companies/user/${req.userID}/documents`, req);
  }


  updateDocumentStatus(req: DocumentUpload) {
    return apiClient.put(`/v1/exo/companies/user/${req.userID}/documents/${req.id}`, req);
  }

  getAllDocuments(req) {
    return apiClient.get(`/v1/exo/companies/user/${req.userID}/documents`);
  }

}

const companyMemberService = new CompanyMemberService();
export default companyMemberService;
