import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import LocationIcon from "../../../../../assets/Icons/LocationIcon";
import RightArrowIcon from "../../../../../assets/Icons/RightArrowIcon";
import VerticleThreeDotsIcon from "../../../../../assets/Icons/VerticleThreeDotsIcon";
import { v4 as uuidV4 } from "uuid";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import * as constants from "../../../../../constant";
import axios from "axios";
import { useAppSelector } from "../../../../../config/hooks";

const BundleUploadUserCard = ({ candidateDetails }) => {
  const { defaultIndustry } = useIndustries();
  const token = useAppSelector((state) => state?.login?.session?.token);

  const industry = useMemo(() => {
    if (candidateDetails?.industry && candidateDetails?.industry[0]) {
      return defaultIndustry(candidateDetails?.industry[0]);
    }
    return null;
  }, [candidateDetails?.industry]);

  const handleDownload = async () => {
    if (candidateDetails?.resume?.url) {
      axios
        .get(
          `${constants.baseUrl}/${candidateDetails?.resume?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = candidateDetails?.resume?.name;
          a.click();
        })
        .catch((err) => {});
    }
  };

  return (
    <div
      className="flex items-center justify-start p-4 bg-transparent gap-7 odd:bg-exwhite-100 even:bg-exgray-100/30"
      key={uuidV4()}
    >
      <div className="w-fit">
        {false ? (
          <Link
            to={`/job-seekers/${candidateDetails?.id}`}
            className="h-28 w-28  rounded-lg flex items-center justify-center overflow-hidden cursor-pointer"
          >
            <img
              //   src={`${constants.baseUrl}/${candidateDetails?.picUrl}?t=${token}`}
              alt="profile"
              className="w-full h-full object-cover"
            />
          </Link>
        ) : candidateDetails?.lastName && candidateDetails?.firstName ? (
          <div
            to={`/job-seekers/${candidateDetails?.id}`}
            className="h-28 w-28 rounded-lg bg-primary-500 bg-center bg-cover bg-no-repeat cursor-pointer text-4xl text-exwhite-100 font-semibold flex items-center justify-center"
          >
            <h1 className="tracking-wide">{`${candidateDetails?.firstName[0].toUpperCase()}${candidateDetails?.lastName[0].toUpperCase()}`}</h1>
          </div>
        ) : (
          <div
            to={`/job-seekers/${candidateDetails?.id}`}
            className="h-28 w-28 rounded-lg bg-DefaultProfileImg bg-center bg-cover bg-no-repeat cursor-pointer"
          ></div>
        )}
      </div>

      <div className="flex-1 space-y-1">
        <Link
          to={`/job-seekers/${candidateDetails?.id}`}
          className="text-lg text-exgray-800 font-semibold leading-none line-clamp-1 cursor-pointer"
        >
          {`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
        </Link>
        <p className="text-base text-exgray-400 line-clamp-1">
          {candidateDetails?.designation}
        </p>
        <div className="flex items-center justify-start gap-2">
          <p className="text-expurple-700 text-base font-semibold line-clamp-1">
            {candidateDetails?.organization
              ? candidateDetails?.organization
              : "N/A"}
          </p>
          {candidateDetails?.locations && (
            <div className="flex items-center justify-start gap-1">
              <div className="w-fit">
                <LocationIcon width="20" height="20" color="#5071C4" />
              </div>
              <p className="text-expurple-800 text-base font-semibold line-clamp-1 flex-1">
                {candidateDetails?.locations}
              </p>
            </div>
          )}
        </div>

        {industry && (
          <p className="text-base text-exgray-500 font-semibold line-clamp-1">
            {industry?.Name}
          </p>
        )}
      </div>

      <div className="flex-1">
        <p className="text-exgray-800 text-base font-semibold line-clamp-1">
          {candidateDetails?.totalExperience
            ? `${candidateDetails?.totalExperience} Yrs Exp`
            : "N/A"}
        </p>
      </div>

      <div className="flex-1"></div>

      <div className="flex-1 flex items-center justify-end gap-5">
        <div className="w-fit">
          <button
            className="text-base text-[#0BA02C] bg-green-100 pl-5 pr-3 py-2 text-center rounded-md flex items-center justify-center gap-2 active:scale-90"
            onClick={handleDownload}
          >
            Resume
            <div className="rotate-90">
              <RightArrowIcon color="#0BA02C" />
            </div>
          </button>
        </div>

        <div className="cursor-not-allowed">
          <VerticleThreeDotsIcon />
        </div>
      </div>

      {/* <div className="flex items-center justify-start gap-20 border-2 border-red-900">
        <Link
          to={`/job-seekers/${candidateDetails?.id}`}
          className="flex items-center justify-start gap-5 cursor-pointer"
        >
          {false ? (
            <div className="h-28 w-28  rounded-lg flex items-center justify-center overflow-hidden">
              <img
                //   src={`${constants.baseUrl}/${candidateDetails?.picUrl}?t=${token}`}
                alt="profile"
                className="w-full h-full object-cover"
              />
            </div>
          ) : (
            <div className="h-28 w-28 rounded-lg bg-DefaultProfileImg bg-center bg-cover bg-no-repeat "></div>
          )}

          <div className="w-60 space-y-1">
            <h1 className="text-lg text-exgray-800 font-semibold leading-none line-clamp-1">
              {`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
            </h1>
            <p className="text-base text-exgray-400 line-clamp-1">
              {candidateDetails?.designation}
            </p>
            <div className="flex items-center justify-start gap-2">
              <p className="text-expurple-700 text-base font-semibold line-clamp-1">
                {candidateDetails?.organization
                  ? candidateDetails?.organization
                  : "N/A"}
              </p>
              {candidateDetails?.locations && (
                <div className="flex items-center justify-start gap-1">
                  <div className="w-fit">
                    <LocationIcon width="20" height="20" color="#5071C4" />
                  </div>
                  <p className="text-expurple-800 text-base font-semibold line-clamp-1 flex-1">
                    {candidateDetails?.locations}
                  </p>
                </div>
              )}
            </div>

            {industry && (
              <p className="text-base text-exgray-500 font-semibold line-clamp-1">
                {industry?.Name}
              </p>
            )}
          </div>
        </Link>
        <p className="text-exgray-800 text-base font-semibold line-clamp-1">
          {candidateDetails?.totalExperience
            ? `${candidateDetails?.totalExperience} Yrs Exp`
            : "N/A"}
        </p>
      </div> */}

      {/* <p className="text-base text-exgray-800">In progress</p> */}

      {/* <div className="flex items-center justify-end gap-20">
        <button
          className="text-base text-[#0BA02C] bg-green-100 pl-5 pr-3 py-2 text-center rounded-md flex items-center justify-center gap-2 active:scale-90"
          onClick={handleDownload}
        >
          Resume
          <div className="rotate-90">
            <RightArrowIcon color="#0BA02C" />
          </div>
        </button>

        <div className="relative w-fit">
          <div className="cursor-not-allowed">
            <VerticleThreeDotsIcon />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BundleUploadUserCard;
