import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { getAllClientsApi, getNextClientsApi } from '../../slices/salesSlice';
import Popup from '../../components/popup/Popup';
import ClientForm from './ClientForm';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'sonner';
import FilterClientForm from './FilterClientForm';

  const statusColors: Record<string, string> = {
    Warm: "bg-yellow-100 text-yellow-600",
    Hot: "bg-red-100 text-red-600",
    Cold: "bg-blue-100 text-blue-600",
  };
const LeadManagement = () => {
    
    const dispatch=useAppDispatch()
    const allClients=useAppSelector((state)=>state.sales.clients)
    const {salesEngangementType,salesIndustry,salesStages,salesStatus,salesSubIndustry}=useAppSelector((state)=>state.appConfig)
    const {clients}=allClients||{}
    const [search, setSearch] = useState("");
    const [togglePopup, setTogglePopup] = useState(false);
    const [toggleFilterPopup, setToggleFilterPopup] = useState(false);
    const [filterStatus, setFilterStatus] = useState("");
    const [filteredClients, setFilteredClients] = useState(clients||[]);

    useEffect(()=>{
      dispatch(getAllClientsApi())
    },[])

    useEffect(() => {
      setFilteredClients(clients); // Sync filtered clients when clients change in Redux
    }, [clients]);
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        setSearch(value);
    
        const updatedClients = clients?.filter((client) =>
          client.clientName.toLowerCase().includes(value) ||
          client.domain.toLowerCase().includes(value) ||
          client.location.toLowerCase().includes(value)
        );
        setFilteredClients(updatedClients);
      };
    
      // const handleFilter = (status: string) => {
      //   setFilterStatus(status);
    
      //   if (status) {
      //     const updatedClients = clients.filter(
      //       (client) => client.status.toLowerCase() === status.toLowerCase()
      //     );
      //     setFilteredClients(updatedClients);
      //   } else {
      //     setFilteredClients(clients);
      //   }
      // };
    
     
      const togglePopupHandler = () => {
        setTogglePopup(!togglePopup);
      };

      const toggleFIlterPopupHandler = () => {
        setToggleFilterPopup(!toggleFilterPopup);
      };


      const getMoreDataHandler = () => {
        try {
          console.log("HIT NEXT URL TABLE")
          dispatch(getNextClientsApi())
          // console.log(clients,"CLIENTSHHKDHJKDGH",filteredClients)
        } catch (error) {
          toast.error("Unable to fetch data");
        }
      };
      return (
        <div className="px-6 py-2 mx-auto bg-white shadow-md rounded-lg ">
          <div className='py-2 flex justify-between items-center'>
            <h1 className='text-xl font-semibold text-exgray-500'>Lead Management</h1>
            <button 
              onClick={togglePopupHandler}
              className=" bg-primary-400 text-white px-4 py-2 font-semibold rounded-md shadow hover:bg-primary-600">
                <span className='text-xl'>+</span> Add Client
            </button>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex gap-2">
              <input
                type="text"
                placeholder="Search Clients..."
                value={search}
                onChange={handleSearch}
                className="border border-gray-300 rounded-md px-4 py-2 w-72 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {/* <select
                value={filterStatus}
                onChange={(e) => handleFilter(e.target.value)}
                className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">All Status</option>
                <option value="Hot">Hot</option>
                <option value="Warm">Warm</option>
                <option value="Cold">Cold</option>
              </select> */}
            </div>
            <button 
             onClick={toggleFIlterPopupHandler}
             className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md shadow hover:bg-gray-200">
              Filter
            </button>
          </div>
          <InfiniteScroll
            className="no-scrollbar"
            dataLength={clients?.length||0}
            next={() => getMoreDataHandler()}
            hasMore={allClients?.metadata?.nextResultURL?true:false}
            // height={"calc(100vh - 155px)"}
            loader={""}
            >
            <div className="relative overflow-auto">
              <table className="min-w-full border border-gray-200 text-sm text-left text-gray-500">
                <thead className="bg-gray-100 text-xs text-gray-700 uppercase">
                  <tr>
                    <th className="px-6 py-3 border-b">Client Name</th>
                    <th className="px-6 py-3 border-b">Domain</th>
                    <th className="px-6 py-3 border-b">Email</th>
                    <th className="px-6 py-3 border-b">Status</th>
                    <th className="px-6 py-3 border-b">Stages</th>
                    {/* <th className="px-6 py-3 border-b">subStages</th> */}
                    <th className="px-6 py-3 border-b">Industry</th>
                    <th className="px-6 py-3 border-b">Sub Industry</th>
                    <th className="px-6 py-3 border-b">Location</th>                       
                    <th className="px-6 py-3 border-b">Reamrks</th>                       
                    
                  </tr>
                </thead>
                <tbody>
                  {filteredClients?.map((client, index) => (
                    <tr
                      key={index}
                      className="bg-white hover:bg-gray-50 border-b text-gray-600"
                    >
                      <td className="px-6 py-3">{client?.clientName}</td>
                      <td className="px-6 py-3">{client?.domain}</td>
                      <td className="px-6 py-3">{client?.clientEmail}</td>
                      <td className="px-6 py-3 "><span
                          className={`px-3 py-1 rounded-md font-semibold ${
                            statusColors[client.status] 
                          }`}
                        >
                          {salesStatus.find((ele)=>ele.Id.toString()===client?.status)?.Title}
                        </span></td>
                      <td className="px-6 py-3">{salesStages.find((ele)=>ele.Id.toString()===client?.stages)?.Title}</td>
                      {/* <td className="px-6 py-3">{salesStages.find((ele)=>ele.Id.toString()===client?.subStages)?.Title}</td> */}
                      <td className="px-6 py-3">{salesIndustry.find((ele)=>ele.Id.toString()===client?.industry)?.Title}</td>
                      <td className="px-6 py-3">{salesSubIndustry.find((ele)=>ele.Id.toString()===client?.subIndustry)?.Title}</td>
                      <td className="px-6 py-3">{client?.location}</td>
                      <td className="px-6 py-3">{client?.remarks}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
              {(filteredClients?.length === 0 || clients==null) && (
                <div className="text-center py-4 text-gray-500">
                  No clients found.
                </div>
              )}
            </div>
          </InfiniteScroll>
          <Popup
            visible={togglePopup}
            onClose={togglePopupHandler}
            children={<ClientForm togglePopupHandler={togglePopupHandler}/>}
          />
          <Popup
            visible={toggleFilterPopup}
            onClose={toggleFIlterPopupHandler}
            children={<FilterClientForm togglePopupHandler={toggleFIlterPopupHandler}/>}
          />
        </div>
      )
}

export default LeadManagement;
