import React, { useMemo } from "react";
import useReadMoreToggle from "../../utils/custome-hooks/useReadMoreToggle";
import useJobTypes from "../../utils/dropdown-options/useJobTypes";
import { useLocation } from "react-router-dom";

const CandidateExperienceCard = ({ experience }) => {
  const { state } = useLocation();

  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(experience?.userExperienceDetails[0]?.description);

  const { defaultJobType } = useJobTypes();

  const jobType = useMemo(() => {
    if (experience?.userExperienceDetails[0]?.employmentType) {
      return defaultJobType(
        experience?.userExperienceDetails[0]?.employmentType
      );
    }
    return null;
  }, [defaultJobType, experience?.userExperienceDetails]);

  return (
    <div className="py-1">
      <h1 className="text-lg text-primary-400 font-semibold">
        {experience?.userExperienceDetails[0]?.title}
      </h1>

      <div className="flex items-start justify-start gap-4 text-base text-[#4E608D] font-medium">
        <h1 className="flex-1">{experience?.organization}</h1>
        <h1 className="flex-1">{jobType?.Title}</h1>
        <h1 className="flex-1">
          {`${experience?.userExperienceDetails[0]?.startDate?.month} ${experience?.userExperienceDetails[0]?.startDate?.year} - ${experience?.userExperienceDetails[0]?.endDate?.month} ${experience?.userExperienceDetails[0]?.endDate?.year}`}
        </h1>
      </div>

      <div>
        <p
          className={`text-sm text-exgray-200 font-semibold ${
            showMore ? "line-clamp-none" : "line-clamp-3"
          }`}
          ref={contentRef}
        >
          {experience?.userExperienceDetails[0]?.description}
        </p>
        {experience?.userExperienceDetails[0]?.description && isOverflowing && (
          <div className="flex justify-end items-center">
            <button
              className="border-none text-primary-500 text-base font-normal text-center"
              onClick={textToggleHandler}
            >
              {showMore ? "View Less" : "View More"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateExperienceCard;
