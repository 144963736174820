import React from "react";
import { Toaster } from "sonner";
// import "react-toastify/dist/ReactToastify.css";

const ToastNotification = () => {
  return (
    <Toaster
      richColors
      duration={3000}
      // visibleToasts={5}
      pauseWhenPageIsHidden
      toastOptions={{
        style: {
          fontSize: "18px",
        },
      }}
    />
  );
};

export default ToastNotification;
