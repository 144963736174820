import React from "react";

const MoreFriends = () => {
  return (
    <svg
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.5H5V3.5C5 2.95 4.55 2.5 4 2.5C3.45 2.5 3 2.95 3 3.5V5.5H1C0.45 5.5 0 5.95 0 6.5C0 7.05 0.45 7.5 1 7.5H3V9.5C3 10.05 3.45 10.5 4 10.5C4.55 10.5 5 10.05 5 9.5V7.5H7C7.55 7.5 8 7.05 8 6.5C8 5.95 7.55 5.5 7 5.5ZM18 6.5C19.66 6.5 20.99 5.16 20.99 3.5C20.99 1.84 19.66 0.5 18 0.5C17.68 0.5 17.37 0.55 17.09 0.64C17.66 1.45 17.99 2.43 17.99 3.5C17.99 4.57 17.65 5.54 17.09 6.36C17.37 6.45 17.68 6.5 18 6.5ZM13 6.5C14.66 6.5 15.99 5.16 15.99 3.5C15.99 1.84 14.66 0.5 13 0.5C11.34 0.5 10 1.84 10 3.5C10 5.16 11.34 6.5 13 6.5ZM13 8.5C11 8.5 7 9.5 7 11.5V12.5C7 13.05 7.45 13.5 8 13.5H18C18.55 13.5 19 13.05 19 12.5V11.5C19 9.5 15 8.5 13 8.5ZM19.62 8.66C20.45 9.39 21 10.32 21 11.5V13C21 13.17 20.98 13.34 20.95 13.5H23.5C23.78 13.5 24 13.28 24 13V11.5C24 9.96 21.63 9.01 19.62 8.66Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};

export default MoreFriends;
