import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  getAllMessageApi,
  removeChat,
  sendMessageApi,
} from "../../slices/chatSlice";
import Message from "./Message";

import { v4 as uuidv4 } from "uuid";

import EmojiPicker, { Theme, EmojiStyle } from "emoji-picker-react";
import { baseUrl } from "../../constant";
import CrossIcon from "../../assets/Icons/CrossIcon";
import AttachmentIcon from "../../assets/Icons/AttachmentIcon";
import SendButtonIcon from "../../assets/Icons/SendButtonIcon";

const Chat = ({ memberDetails, conversationId }) => {
  const { token, userId } = useAppSelector((state) => state.login.session);

  const { messages, conversations } = useAppSelector((state) => state.chat);

  const dispatch = useAppDispatch();
  // const {conversationId}=useParams()
  const messageEndRef = useRef(null);
  const [showEditContent, setShowEditContent] = useState(false);
  const [connectedWithSocket, setConnectedWithSocket] = useState(false);
  const [connection, setConnection] = useState(null);
  const [textMessage, setTextMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  const newUuid = uuidv4();

  useEffect(() => {
    dispatch(getAllMessageApi({ conversationId: conversationId }));
  }, [conversationId]);

  useEffect(() => {
    // Scroll to the bottom of the chat whenever messages are updated
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = () => {
    if (!textMessage.length) {
      return;
    }
    dispatch(
      sendMessageApi({
        conversationId:
          conversationId == "home" ? conversations[0]?.id : conversationId,
        sender: userId,
        content: { message: { id: newUuid, text: textMessage, type: 1 } },
      })
    );
    setTextMessage("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default behavior of Enter key (like submitting a form)
      handleSendMessage();
    }
  };

  const msg = Object.values(messages.entities);

  const handleRemoveChat = () => {
    dispatch(removeChat(conversationId));
  };

  const onEmojiClick = (emojiData, event: MouseEvent) => {
    setTextMessage((prevInput) => prevInput + emojiData.emoji);
    setShowEmoji(false);
  };

  return (
    <div
      style={{ height: `calc(100vh - 18vh)` }}
      className="flex flex-col xl:w-[380px] 2xl:w-[480px] rounded-t-lg border border-gray-500 shadow-sm shadow-golden bg-exwhite-100"
    >
      <div className="flex items-center  justify-between p-3 bg-primary-700 rounded-t-lg border-b border-lxgray-300">
        <div className="flex gap-4">
          {memberDetails[0]?.picUrl ? (
            <img
              src={`${baseUrl}/files/${memberDetails[0]?.picUrl}?t=${token}`}
              alt="Profile Image"
              className="w-10 h-10 object-cover rounded-full"
            />
          ) : (
            <div className="h-12 w-12 rounded-full bg-primary-400 flex items-center justify-center text-2xl text-exwhite-100 font-semibold">{`${memberDetails[0]?.firstName[0].toUpperCase()}${memberDetails[0]?.lastName[0].toUpperCase()}`}</div>
          )}
          <div>
            <h1 className="text-exwhite-100 font-semibold text-md">
              {memberDetails[0]?.firstName} {memberDetails[0]?.lastName}
            </h1>
            <p className="text-exgray-200 text-sm">
              {memberDetails[0]?.organization}
            </p>
          </div>
        </div>
        <div className="relative cursor-pointer">
          {/* <div className="px-2" onClick={()=>setShowEditContent(!showEditContent)}><EditDots /></div>
         
         {
          showEditContent && <div className="bg-lxgray-400 absolute py-2 top-8 right-0 rounded-lg text-lxgray-100">
            <ul className="w-fit text-center cursor-pointer">
              <li className="whitespace-nowrap hover:bg-lxgray-100 px-4 hover:text-lxgray-600">View Profile</li>
              <li className="hover:bg-lxgray-100 px-4 hover:text-lxgray-600">Mute</li>
              <li className="hover:bg-lxgray-100 px-4 hover:text-lxgray-600">Block</li>
              <li className="hover:bg-lxgray-100 px-4 hover:text-lxgray-600">Report</li>
            </ul>
          </div>
          } */}

          <div onClick={handleRemoveChat}>
            <CrossIcon width="14" />
          </div>
        </div>
      </div>

      <div className="flex-1 bg-lxgray-700 h-1/2 p-5 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col gap-7 text-exgray-500 font-semibold">
          {msg?.map((ele) => (
            <Message
              key={ele.id}
              message={ele.content?.message?.text}
              time={ele?.createdAt}
              messageType={ele?.sender === userId ? "sender" : "receiver"}
              metadata={ele?.content.message.metadata}
              conversationId={conversationId}
            />
          ))}
          <div ref={messageEndRef} />
        </div>
      </div>

      <div className="relative flex gap-4 items-center bg-exwhite-100 border border-exgray-100 p-1.5">
        <input
          onKeyDown={handleKeyDown}
          type="text"
          className="w-full py-2 rounded-lg bg-lxgray-800 text-lxgray-100 px-4"
          value={textMessage}
          onChange={(e) => setTextMessage(e.target.value)}
          placeholder="Type a message"
        />
        <AttachmentIcon />
        <div onClick={() => setShowEmoji(!showEmoji)}>😊</div>
        {showEmoji && (
          <div className="absolute bottom-14 ">
            <EmojiPicker
              autoFocusSearch={false}
              previewConfig={{ showPreview: false }}
              style={{ border: "#8C8C8C 2px solid" }}
              theme={Theme.LIGHT}
              emojiStyle={EmojiStyle.GOOGLE}
              reactionsDefaultOpen={false}
              skinTonesDisabled={true}
              height="20rem"
              width="23em"
              onEmojiClick={onEmojiClick}
            />
          </div>
        )}
        <div onClick={handleSendMessage} className="cursor-pointer">
          <SendButtonIcon />
        </div>
      </div>
    </div>
  );
};

export default Chat;
