import { AxiosPromise } from "axios";
import apiClient from "../config/axiosConfig";
import apiCompanyAuthClient from "../config/local-axios-config/companyAuthApiConfig";
import apiRecruiterAuthClient from "../config/local-axios-config/recruiterAuthApiConfig";
import apiUserClient from "../config/local-axios-config/userApiConfig";
import apiUserAuthClient from "../config/local-axios-config/userAuthAPIConfig";
import {
  CandidateGetOtpReq,
  CandidateGetOtpRes,
  CandidateNewSignUpReq,
  CandidateNewSignUpRes,
  CandidateVerifyOTPReq,
  CandidateVerifyOTPRes,
  CreateCompanyReq,
  CreateCompanyRes,
  GetOtpForDomainVerifyReq,
  GetOtpForDomainVerifyRes,
  GetRecruiterOtpReq,
  GetRecruiterOtpRes,
  RegisterCompanyMemberReq,
  RegisterCompanyMemberRes,
  RegisterRecruiterReq,
  RegisterRecruiterRes,
  VerifyDomainOtpReq,
  VerifyDomainOtpRes,
  VerifyRecruiterOTPReq,
  VerifyRecruiterOTPRes,
} from "../models/login.model";
import { ApiResponse } from "../models/base.model";

class AuthService {
  // Candidate auth -->
  getOTP(
    authReq: CandidateGetOtpReq
  ): AxiosPromise<ApiResponse<CandidateGetOtpRes>> {
    return apiClient.post(`/v1/exo/auth/otp`, authReq);
  }

  verifyOTP(
    registerReq: CandidateVerifyOTPReq
  ): AxiosPromise<ApiResponse<CandidateVerifyOTPRes>> {
    return apiClient.post(`/v1/exo/auth/otp/verify`, registerReq);
  }

  newSignUp(
    newregisterReq: CandidateNewSignUpReq
  ): AxiosPromise<ApiResponse<CandidateNewSignUpRes>> {
    return apiClient.post(`/v1/exo/auth/register`, newregisterReq);
  }

  // create refer auth

  createRefer(req) {
    return apiClient.post(`/v1/exo/auth/refer`, req);
  }

  //Comapny Auth

  verifyDomain(domainVerifyReq) {
    return apiClient.post(`/v1/exo/companies/authorize`, domainVerifyReq);
  }

  getOtpForDomainVerify(
    getOtp: GetOtpForDomainVerifyReq
  ): AxiosPromise<ApiResponse<GetOtpForDomainVerifyRes>> {
    return apiClient.post(`/v1/exo/auth/company/otp`, getOtp);
  }

  verifyDomainOtp(
    otpVerifyReq: VerifyDomainOtpReq
  ): AxiosPromise<ApiResponse<VerifyDomainOtpRes>> {
    return apiClient.post(`/v1/exo/auth/company/otp/verify`, otpVerifyReq);
  }

  createCompany(
    newCompanyReq: CreateCompanyReq
  ): AxiosPromise<ApiResponse<CreateCompanyRes>> {
    return apiClient.post(`/v1/exo/auth/company/register`, newCompanyReq);
  }

  registerCompanyMember(
    newCompanyReq: RegisterCompanyMemberReq
  ): AxiosPromise<ApiResponse<RegisterCompanyMemberRes>> {
    return apiClient.post(
      `/v1/exo/auth/company/member/register`,
      newCompanyReq
    );
  }

  //Recruiter Auth
  getRecruiterOtp(
    authReq: GetRecruiterOtpReq
  ): AxiosPromise<ApiResponse<GetRecruiterOtpRes>> {
    return apiClient.post(`/v1/exo/auth/recruiter/otp`, authReq);
  }

  verifyRecruiterOTP(
    verifyReq: VerifyRecruiterOTPReq
  ): AxiosPromise<ApiResponse<VerifyRecruiterOTPRes>> {
    return apiClient.post(`/v1/exo/auth/recruiter/otp/verify`, verifyReq);
  }

  registerRecruiter(
    newregisterReq: RegisterRecruiterReq
  ): AxiosPromise<ApiResponse<RegisterRecruiterRes>> {
    return apiClient.post(`/v1/exo/auth/recruiter/register`, newregisterReq);
  }

  //Candidate Bulk Upload

  newSignUps(newregisterReq) {
    return apiClient.post(`/v1/exo/auth/register/bulk`, newregisterReq);
  }
}

const authService = new AuthService();
export default authService;
