import { useAppSelector } from "../../config/hooks";

const useIndustries = () => {
  const industriesArray = useAppSelector((state) => state.appConfig.industries);

  const defaultIndustry = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Id: dafaultId, Name: dafaultId };
    }
    const selectedIndustry = industriesArray.find(
      (industry) => industry.Id == dafaultId
    );
    return selectedIndustry;
  };

  const defaultIndustries = (dafaultIds) => {
    const selectedIndustry = dafaultIds.map((id) => {
      if (isNaN(id)) {
        return { Id: id, Name: id };
      }

      const filteredIndustry = industriesArray.find(
        (location) => location.Id == id
      );
      return filteredIndustry;
    });

    return selectedIndustry;
  };

  return { industriesArray, defaultIndustry, defaultIndustries };
};

export default useIndustries;
