import React from 'react'

const Documentation = () => {
  return (
    <div>
      <h1>HRMS Documentation</h1>
    </div>
  )
}

export default Documentation
