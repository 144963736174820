import React from "react";
import CompanyDashboardProfile from "./CompanyDashboardProfile";
import CompanyDashboardDetails from "./CompanyDashboardDetails";

const CompanyOverview = () => {
  return (
    <div className="space-y-4">
      <CompanyDashboardProfile />
      <CompanyDashboardDetails />
    </div>
  );
};

export default CompanyOverview;
