import { useAppSelector } from "../../config/hooks";

const useBand = () => {
  const bandList = useAppSelector((state)=>state.appConfig.bandList);

  const defaultBand=(defaultId)=>{
    if(isNaN(defaultId)){
      return {Id:defaultId,Title:defaultId}
    }

    const selectedBand = bandList.find(
      (band)=> band.Id == defaultId
    )
    return selectedBand;
  }
  return { bandList, defaultBand };
};

export default useBand;
