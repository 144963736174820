import { useAppSelector } from "../../config/hooks";

const useMaritalStatus = () => {
  const maritalStatusArray = [
  {
    Id: 1,
    Title: "Married"
  },
  {
    Id: 2,
    Title: "Single"
  }
];

  const defaultMaritalStatus = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Id: dafaultId, Title: dafaultId };
    }
    const selectedStatus = maritalStatusArray.find(
      (industry) => industry.Id == dafaultId
    );
    return selectedStatus;
  };

  return { maritalStatusArray, defaultMaritalStatus };
};

export default useMaritalStatus;
