import React from "react";

const CandidateEducationCard = ({ education }) => {
  return (
    <div className="py-1">
      <h1 className="text-lg text-primary-400 font-semibold">
        {education?.degree}
      </h1>

      <div className="flex items-start justify-start gap-4">
        <h1 className="flex-1 text-base text-[#4E608D] font-medium">
          {education?.institute}
        </h1>
        <h1 className="w-fit text-base text-exgray-200">{`${education?.endDate?.month} ${education?.endDate?.year}`}</h1>
      </div>
    </div>
  );
};

export default CandidateEducationCard;
