const EditIcon = 
({ width = "14", height = "14", color = "#8C8C8C" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10.9438V13.1392C0 13.3414 0.1711 13.5002 0.388864 13.5002H2.75315C2.85426 13.5002 2.95536 13.4641 3.02536 13.3919L11.5181 5.51328L8.60166 2.80522L0.116659 10.6839C0.0388864 10.7561 0 10.8427 0 10.9438ZM13.7735 3.41904C14.0769 3.13741 14.0769 2.68245 13.7735 2.40081L11.9537 0.710984C11.6504 0.429346 11.1604 0.429346 10.8571 0.710984L9.43383 2.03252L12.3503 4.74058L13.7735 3.41904Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
