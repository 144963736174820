import React from "react";

const ScheduleCalenderIcon = ({
  width = "21",
  height = "20",
  color = "#0A65CC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 16.6667H5.50033C3.65938 16.6667 2.16699 15.1742 2.16699 13.3333V5.83333C2.16699 3.99238 3.65938 2.5 5.50033 2.5H14.667C16.5079 2.5 18.0003 3.99238 18.0003 5.83333V8.33333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16699 1.66699V3.33366"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 1.66699V3.33366"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16699 6.66699H18.0003"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.917 13.0352L14.667 14.2852"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 18.3333C16.9679 18.3333 18.8333 16.4679 18.8333 14.1667C18.8333 11.8655 16.9679 10 14.6667 10C12.3655 10 10.5 11.8655 10.5 14.1667C10.5 16.4679 12.3655 18.3333 14.6667 18.3333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ScheduleCalenderIcon;
