import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base.model";
import apiClient from "../config/axiosConfig";
import { Client, FilterClientData, PaginatedClientResponse } from "../models/sales.model";
import { CompanyProfile } from "../models/company.model";

class SalesService {
    createClient(
       client: Client
      ): AxiosPromise<ApiResponse<Client>> {
        return apiClient.post(`/v1/exo/clients`, client);
    }

    updateClient(
        client: Client
       ): AxiosPromise<ApiResponse<Client>> {
         return apiClient.put(`/v1/exo/clients/${client.id}`, client);
     }

    getAllClients(): AxiosPromise<ApiResponse<PaginatedClientResponse>> {
         return apiClient.get(`/v1/exo/clients`);
     }

    getfilteredClients(
        filterData: FilterClientData
      ):
        AxiosPromise<ApiResponse<PaginatedClientResponse>> {
        return apiClient.get(`/v1/exo/clients?`,{
            params:filterData
        });  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
    }

    getClientById(clientId:string):AxiosPromise<ApiResponse<Client>>{
        return apiClient.get(`/v1/exo/client/${clientId}`);
    }

    checkDomain(domain:string):AxiosPromise<ApiResponse<CompanyProfile>>{
        return apiClient.get(`/v1/exo/companies/domain?domain=${domain}`)
    }

    getClientByUrl(nextUrl) {
        return apiClient.get(nextUrl);
    }


}

const salesService=new SalesService();
export default salesService;