import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ExInput from "../../../../../components/basic-components/ExInput";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import useCountryCodes from "../../../../../utils/dropdown-options/useCountryCodes";

const UploadedPersonalDetails = ({ userDetails }) => {
  const { locationsArray, defaultLocation } = useLocations();
  const { modifiedCountryCodes, defaultCountryCode } = useCountryCodes();

  const {
    id,
    firstName,
    lastName,
    organization,
    designation,
    location,
    email,
    dateOfBirth,
    countryCode,
    mobile,
    socialInfo,
  } = userDetails.userDetails;

  const form = useForm({
    defaultValues: {
      firstname: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      designantion: designation ? designation : "",
      companyName: organization ? organization : "",
      emailId: email ? email : "",
      countryCode: countryCode ? defaultCountryCode(countryCode) : "",
      phoneNo: mobile ? mobile.toString() : "",
      dob: dateOfBirth ? dateOfBirth : "",
      location: location ? defaultLocation(location) : "",
      weblink: socialInfo
        ? socialInfo["Twitter"]?.profileURL
          ? socialInfo["Twitter"]?.profileURL
          : ""
        : "",
      // file: "",
    },
  });

  const {
    control,
    formState: { errors },
  } = form;

  //   const onSubmit = (formData) => {

  //     dispatch(
  //       updateUserProfileApi({
  //         userId: id,
  //         firstName: formData.firstName,
  //         lastName: formData.lastName,
  //         location: formData.location.Id.toString(),
  //         designation: formData.designantion,
  //         organization: formData.companyName,
  //         socialInfo: {
  //           Twitter: { type: "twitter", profileURL: formData.weblink },
  //         },
  //         // mobile: Number(formData.phone),
  //         dateOfBirth: formData.dob,
  //       })
  //     );
  //   };

  return (
    <div className="space-y-2">
      <h1 className="text-lg text-black text-semibold text-start border-b-2 border-b-exgray-100">
        Personal Details
      </h1>

      <form
        className="grid grid-cols-2 auto-rows-auto gap-4"
        // onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Controller
            name="firstname"
            control={control}
            rules={{
              required: {
                value: true,
                message: "First name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Enter your first name"
                title="First Name"
                name="firstname"
                readOnly={true}
                value={value || ""}
                onChange={onChange}
                error={errors?.firstname?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Last name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Enter your last name"
                title="Last Name"
                readOnly={true}
                name="lastName"
                value={value || ""}
                onChange={onChange}
                error={errors?.lastName?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="designantion"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Designantion is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Designation"
                placeholder="Enter Here"
                readOnly={true}
                name="designantion"
                onChange={onChange}
                value={value}
                error={errors?.designantion?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="companyName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Company name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Current Organisation Name"
                placeholder="Enter Here"
                name="companyName"
                readOnly={true}
                onChange={onChange}
                value={value}
                error={errors?.companyName?.message}
              />
            )}
          />
        </div>

        <div className="flex items-center justify-between gap-2">
          <div className="min-w-[120px] self-start">
            <Controller
              name="countryCode"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Country"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  isDisabled={true}
                  optionValue="PhoneCode"
                  optionLabel="PhoneCodeLabel"
                  options={modifiedCountryCodes}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  error={errors?.countryCode?.message}
                />
              )}
            />
          </div>
          <div className="flex-1 self-start">
            <Controller
              name="phoneNo"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Mobile no is required",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please enter a valid mobile number",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Phone Number"
                  placeholder="Enter your phone number"
                  name="phoneNo"
                  readOnly={true}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onChange={onChange}
                  value={value}
                  error={errors?.phoneNo?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            name="emailId"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Email ID is required",
              },
              pattern: {
                value:
                  /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                message: "Please enter a valid email ID",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Email ID"
                placeholder="eg. abc@abc.com"
                name="emailId"
                readOnly={true}
                onChange={onChange}
                value={value}
                error={errors?.emailId?.message}
              />
            )}
          />
        </div>

        <div className="w-full">
          <Controller
            name="dob"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Date of Birth is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              //   <CustomDatePicker
              //     title="Date of Birth"
              //     name="dob"
              //     onChange={onChange}
              //     selected={value ? new Date(value) : null}
              //     maxDate={new Date()}
              //     showYearDropdown={true}
              //     disabled={true}
              //     dropdownMode="select"
              //     placeholderText="Ex. mm/dd/yyyy"
              //     popperPlacement="bottom-end"
              //     dateFormat="MM/dd/yyyy"
              //     showMonthYearPicker={false}
              //     error={errors?.dob?.message}
              //   />

              <>
                <label
                  className="text-lg font-normal text-expurple-800"
                  htmlFor="dob"
                >
                  Date of Birth
                </label>
                <div className="w-full">
                  <DatePicker
                    className={`text-expurple-700 text-base  h-10 rounded-sm pl-2 outline-none w-full  bg-expurple-100 placeholder:text-expurple-700
                 `}
                    name="dob"
                    onChange={onChange}
                    selected={value ? new Date(value) : null}
                    maxDate={new Date()}
                    showYearDropdown={true}
                    disabled={true}
                    dropdownMode="select"
                    placeholderText="mm/dd/yyyy"
                    popperPlacement="bottom-end"
                    dateFormat="MM/dd/yyyy"
                    showMonthYearPicker={false}
                    id={"dob"}
                  />
                </div>
                <p className="text-red-900">{errors?.dob?.message}</p>
              </>
            )}
          />
        </div>

        <div>
          <Controller
            name="location"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Location is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Location"
                onChange={onChange}
                placeholder=""
                value={value || null}
                optionValue="Id"
                optionLabel="Name"
                isDisabled={true}
                options={locationsArray}
                dropDownHeight="40px"
                dropDownBorderWidth="0px"
                dropDownBorderColor="#696969"
                error={errors?.location?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="weblink"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Personal Website"
                placeholder="Website url..."
                name="weblink"
                onChange={onChange}
                readOnly={true}
                value={value}
                error={errors?.weblink?.message}
              />
            )}
          />
        </div>
      </form>
    </div>
  );
};

export default UploadedPersonalDetails;
