import { useAppSelector } from "../../config/hooks";

const useMinExperiences = () => {
  const minExperienceArray = useAppSelector(
    (state) => state.appConfig.minExperience
  );

  const defaultMinExperience = (dafaultId) => {
    const selectedMinExperience = minExperienceArray.find(
      (minExp) => minExp.value == dafaultId
    );
    return selectedMinExperience;
  };
  return { minExperienceArray, defaultMinExperience };
};

export default useMinExperiences;
