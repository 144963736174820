import React, { useEffect, useRef, useState } from "react";
import EditIcon from "../assets/Icons/EditIcon";
import DropdownArrowIcon from "../assets/Icons/DropdownArrowIcon";

export interface AccordianProps {
  title: string;
  children?: React.ReactNode;
  onPressEdit?: Function;
  isEditable?: boolean;
}

const Accordian = ({
  title,
  children,
  onPressEdit,
  isEditable
}: AccordianProps) => {

  return (
    <div className="w-full ">
      <div className="bg-secondary-100 border-b border-exgray-100 flex justify-between items-center px-3 py-2">
        <h1 className="text-secondary-700 font-medium">{title}</h1>
        <div className="flex">
          {
            isEditable &&
            <div onClick={() => onPressEdit()} className="mt-1.5 mr-3">
              <EditIcon color="#0A65CC" />
            </div>
          }

          {/* <div className="mt-1.5">
            <DropdownArrowIcon width="20" height="14" color={"#095BB8"} />
          </div> */}
        </div>
      </div>
      <div className="px-3">
        {children}
      </div>
    </div>
  );
};

export default Accordian;
