import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'

import { formatDate } from '../../utils/date.util';

const Message = ({messageType,message,time,metadata,conversationId}:{messageType:string,message:string,time:string,metadata?:any,conversationId?:string}) => {
  const dispatch=useAppDispatch()
  // const {jobDes}=useAppSelector((state)=>state.job)
  const {userId}=useAppSelector((state)=>state.login.session)
  // useEffect(()=>{
  //   if(message==="Job Share" && metadata){
  //     dispatch(getJobDetailApi({jobId:metadata.id}))
  //   }
  // },[])
// const jobDes={
//   id:metadata?.id,
//   title:metadata?.title,
//   author:{
//     picUrl: metadata?.contents[0].url
//   },
//   industries:metadata?.contents[0].displayName,
//   location:metadata?.body,
//   workplaceType:metadata?.contents[0].type,
//   createdAt:null,
// }

  const renderContent = (conversationId) => {
    // if (message === "Job Share" ) {
    //   return (
    //     <Link onClick={()=>dispatch(removeChat(conversationId))} to={`/tab/jobs/description/Recommended/${jobDes?.id}`}>
    //       <JobCardBox data={jobDes} title='' />
    //     </Link>
    //   );
    // } else {
      return (
        <div
          className={`text-sm p-2 border border-exgray-100 ${messageType === "sender" ? ' rounded-b-lg rounded-l-lg items-end' : 'bg-exgray-100/50 rounded-b-lg rounded-r-lg'}`}
        >
          <h1 className='whitespace-pre-line'>{message}</h1>
        </div>
      );
    };
  
  return (
   
      <div className={`flex flex-col ${messageType==="sender"?"justify-end items-end pl-28":" justify-start items-start pr-28"}`}>
                {/* {message==="Job Share"?
                <Link to={`/tab/jobs/description/${jobDes.authorId===userId?"Open":"Recommended"}/${jobDes.id}`}>
                <JobCardBox data={jobDes} title=''/>
                </Link>
                :<div className={`text-sm p-2 border border-lxgray-300  ${messageType==="sender"?'bg-lxgray-800 rounded-b-lg rounded-l-lg items-end':'bg-lxgray-500 rounded-b-lg rounded-r-lg'}`}>
                        <h1 className='whitespace-pre-line'>{message}</h1>
                </div>} */}
                {renderContent(conversationId)}
                <p className={`text-lxgray-400 text-xs mt-1 ${messageType==="sender"?"text-end":""}`}>{formatDate(time)}</p>
      </div>
    
  )
}

export default Message
