import React, { useEffect, useState } from "react";

import PlusCircleIcon from "../../../../../assets/Icons/PlusCircleIcon";
import { Link } from "react-router-dom";
import JobCard from "../../job-search/components/JobCard";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import {
  getAssignJobsToMemberApi,
  getNextAssignJobsToMemberApi,
  getNextJobsToMemberAdminApi,
  getRecommendedJobApi,
} from "../../../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../../../../constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import NoDataFoundIcon from "../../../../../assets/Icons/NoDataFoundIcon";

const MemberJobsList = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );
  const jobCards = useAppSelector((state) => state?.jobs?.jobCards);
  const assignedJobsMemberNextUrl = useAppSelector(
    (state) => state.jobs.assignedJobsMemberNextUrl
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getRecommendedJobApi({
            companyId:
              memberSelector.designation === constants.ADMIN
                ? memberSelector.companyId
                : // : userId,
                  memberSelector.companyId,
            jobStatus: "OPEN",
          })
        ).then(unwrapResult);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    };

    const fetchAssignedJobsData = async () => {
      try {
        const response = await dispatch(
          getAssignJobsToMemberApi({
            memberId: userId,
          })
        ).then(unwrapResult);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    };

    if (
      loginSelector?.who === constants.COMPANY.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.ADMIN)
    ) {
      fetchData();
    } else {
      fetchAssignedJobsData();
    }
  }, []);

  const getMoreDataHandler = () => {
    if (
      loginSelector?.who === constants.COMPANY.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.ADMIN)
    ) {
      try {
        dispatch(getNextJobsToMemberAdminApi());
      } catch (error) {
        toast.error("Unable to fetch data");
      }
    } else {
      try {
        dispatch(
          getNextAssignJobsToMemberApi({
            memberId: userId,
          })
        );
      } catch (error) {
        toast.error("Unable to fetch data");
      }
    }
  };

  if (error) {
    return <h1>Something went wrong</h1>;
  }

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-center gap-2 w-fit ml-auto mr-0">
        {(memberSelector.designation === constants.ADMIN ||
          memberSelector.designation === constants.JOB_MANEGMENT) && (
          <Link
            to={"/create-job"}
            className="px-5 py-2 text-base text-exwhite-100 font-semibold flex items-center justify-center gap-2 bg-expurple-800 rounded-md"
          >
            <PlusCircleIcon width="25" height="25" color="white" /> Create Job
          </Link>
        )}
        {/* <button className="p-2">
          <FilterIcon />
        </button> */}
      </div>

      {!isLoading && (!jobCards?.jobs || jobCards?.jobs.length === 0) && (
        <div className="py-10">
          <div className="flex items-center justify-center">
            <NoDataFoundIcon />
          </div>
          <div>
            <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
              No Data Found
            </h1>
            {/* <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
    Find new opportunities and manage your job search progress here.
  </p> */}
          </div>
        </div>
      )}

      {!isLoading && (
        <InfiniteScroll
          className="no-scrollbar"
          dataLength={jobCards?.jobs?.length ? jobCards?.jobs?.length : 0}
          next={() => getMoreDataHandler()}
          hasMore={
            loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
            memberSelector.designation === constants.ADMIN
              ? jobCards?.metadata?.nextResultURL
              : assignedJobsMemberNextUrl
          }
          // height={"calc(100vh - 155px)"}
          loader={""}
        >
          <div className="flex flex-wrap mx-auto">
            {jobCards?.jobs?.length > 0 &&
              jobCards.jobs.map((job) => (
                <div className="w-[33.3333333%]" key={uuid4()}>
                  <Link to={`/job/${job.id}`}>
                    <JobCard job={job} />
                  </Link>
                </div>
              ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default MemberJobsList;
