import { useAppSelector } from "../../config/hooks";

const useNationality = () => {
  const nationalityArray = [
  {
    Id: 1,
    Title: "Indian"
  },
  {
    Id: 2,
    Title: "NRI"
  }
];

  const defaultNationality = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Id: dafaultId, Title: dafaultId };
    }
    const selectedNationality = nationalityArray.find(
      (industry) => industry.Id == dafaultId
    );
    return selectedNationality;
  };

  return { nationalityArray, defaultNationality };
};

export default useNationality;
