import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import JobCard from "../../job-search/components/JobCard";
import Popup from "../../../../../components/popup/Popup";
import JobDetail from "../../job-search/components/JobDetail";
import {
  getNextSavedJobApi,
  getSavedJobApi,
} from "../../../../../slices/jobSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import NoDataFoundIcon from "../../../../../assets/Icons/NoDataFoundIcon";

const SavedJobsList = () => {
  const dispatch = useAppDispatch();
  const savedJobs = useAppSelector((state) => state.jobs.savedJobs);
  const [togglePopup, setTogglePopup] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState("");

  const { jobs, metadata } = savedJobs || {};

  useEffect(() => {
    dispatch(getSavedJobApi());
  }, []);

  const togglePopupHandler = (jobId) => {
    setTogglePopup(!togglePopup);
    setSelectedJobId(jobId);
  };

  const closePopupHandler = (jobId) => {
    setTogglePopup(false);
    setSelectedJobId("");
  };

  const getMoreDataHandler = () => {
    try {
      dispatch(getNextSavedJobApi());
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  if (!savedJobs || !savedJobs?.jobs || savedJobs?.jobs?.length === 0) {
    return (
      <div className="py-10">
        <div className="flex items-center justify-center">
          <NoDataFoundIcon />
        </div>
        <div>
          <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
            No Saved Jobs Found
          </h1>
          {/* <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
        Find new opportunities and manage your job search progress here.
      </p> */}
        </div>
      </div>
    );
  }

  return (
    <div>
      {savedJobs && savedJobs?.jobs?.length > 0 && (
        <InfiniteScroll
          className="no-scrollbar"
          dataLength={jobs?.length ? jobs?.length : 0}
          next={() => getMoreDataHandler()}
          hasMore={metadata?.nextResultURL}
          // hasMore={true}
          // height={"calc(100vh - 155px)"}
          loader={""}
        >
          <div className="flex flex-wrap mx-auto">
            {jobs?.length > 0 &&
              jobs.map((savedJob) => (
                <div className="w-[33.3333333%]" key={uuid4()}>
                  <div onClick={() => togglePopupHandler(savedJob.id)}>
                    <JobCard job={savedJob} />
                  </div>
                  <Popup
                    onClose={closePopupHandler}
                    key={uuid4()}
                    visible={savedJob?.id === selectedJobId}
                  >
                    {savedJob.id === selectedJobId && (
                      <div className="bg-expurple-200 p-10 rounded-xl w-[75%] h-[90%] overflow-y-auto no-scrollbar">
                        <JobDetail
                          jobDetails={savedJob}
                          applied={savedJob.applied}
                          savedJob={true}
                          closePopupHandler={closePopupHandler}
                        />
                      </div>
                    )}
                  </Popup>
                </div>
              ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default SavedJobsList;
