import React, { useEffect, useState } from 'react'
import OnboardingTab from './components/OnboardingTab'
import { getMembersApi } from '../../slices/companyMemberSlice';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { useLocation } from 'react-router-dom';
import * as constants from "../../constant";
import MemberCard from '../../components/company-member-card/MemberCard';

const Onboarding = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member);
  const [togglePopup, setTogglePopup] = useState(false);
  const members = memberSelector.members || [];

  useEffect(() => {
    dispatch(
      getMembersApi({
        companyId:
          loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberSelector?.memberDetails?.companyId,
      })
    );
  }, []);

  return (
    <div className="min-h-screenheight bg-exwhite-100 p-4 space-y-4">
      <h1 className="text-lg text-exgray-800 font-medium">Onboarding List</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-5">
        {members &&
          members.length > 0 &&
          members.map((member) => (
            <MemberCard
              member={member}
              key={member.id}
              type={"onboarding"}
            />
          ))}
      </div>

      {/* <div className='flex'>
        <OnboardingTab />
      </div> */}
    </div>
  )
}

export default Onboarding
