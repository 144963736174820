import { Link, useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/Icons/EditIcon";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { useAppSelector } from "../../../config/hooks";
import { useMemo } from "react";

const HealthBenefits = () => {
  const navigate = useNavigate();
  const memberDetail = useAppSelector((state) => state.company.memberDetails);

  return (
    <>
      <div className="flex">
        {/* <div className="w-full">
          <div className="flex items-center">
            <div className="flex w-6/12">
              <h1 className="text-sm  text-exgray-400 mt-2">First Name : </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> {memberDetail?.firstName}</h1>
            </div>
            <div className="flex w-6/12">
              <h1 className="text-sm text-exgray-400 mt-2">Last Name : </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> {memberDetail?.lastName}</h1>
            </div>
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <h1 className="text-sm  text-exgray-400 mt-2">Date of Birth :  </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> 25 / 10 / 1994</h1>
            </div>
            <div className="flex w-6/12">
              <h1 className="text-sm text-exgray-400 mt-2">Gender :  </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> {memberDetail?.gender}</h1>
            </div>
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <h1 className="text-sm  text-exgray-400 mt-2">Marital Status :   </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> Married</h1>
            </div>
            <div className="flex w-6/12">
              <h1 className="text-sm text-exgray-400 mt-2">Nationality :  </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> Indian</h1>
            </div>
          </div>
        </div> */}

      </div >
    </>
  );
};

export default HealthBenefits;
