import moment from "moment";
import { useNavigate } from "react-router-dom";
import BasicDataView from "../../../components/BasicDataView";
import { useAppSelector } from "../../../config/hooks";
import { member } from "../../../models/companyMember.model";

export interface EmployeeDetailProps {
  memberDetail: member;
}

const EmploymentDetails = ({ memberDetail }: EmployeeDetailProps) => {
  const navigate = useNavigate();
  //const memberDetail = useAppSelector((state) => state.company.memberDetails);
  return (
    <>
      <div className="p-3">
        <div>
          <div className="flex items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"Job Title"}
                value={memberDetail?.jobProfile}
              />
            </div>
            <div className="flex w-6/12">
              <BasicDataView
                title={"Department"}
                value={memberDetail?.departments.join(',')}
              />
            </div>
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"Reporting Manager"}
                value={""}
              />

            </div>
            <div className="flex w-6/12">
              <BasicDataView
                title={"Date Of Joining"}
                value={moment(memberDetail?.joiningDate).format("DD/MM/YYYY")}
              />
            </div>
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"Access Role"}
                value={memberDetail?.designation}
              />
            </div>
            <div className="flex w-6/12">
              <BasicDataView
                title={"Work Mode"}
                value={memberDetail?.workMode}
              />
            </div>
          </div>
          <div className="flex mt-3 mb-3 items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"Employment Type"}
                value={memberDetail?.employmentType}
              />
            </div>
            <div className="flex w-6/12">
              <BasicDataView
                title={"Work Email ID"}
                value={memberDetail?.emailId}
              />
            </div>
          </div>
        </div>

      </div >
    </>
  );
};

export default EmploymentDetails;
