import React, { useState } from "react";
import ArrowIcon from "../../../../../assets/Icons/ArrowIcon";
import LocationIcon from "../../../../../assets/Icons/LocationIcon";
import SearchIcon from "../../../../../assets/Icons/SearchIcon";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "../../../../../config/hooks";
import { getRecommendedJobApi } from "../../../../../slices/jobSlice";
import Popup from "../../../../../components/popup/Popup";
import AdvanceFilterForm from "./AdvanceFilterForm";

const SearchBar = () => {
  const dispatch = useAppDispatch();

  const { locationsArray } = useLocations();

  const [togglePopup, setTogglePopup] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      title: "",
      location: null,
    },
  });

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const closeTogglePopupHandler = () => {
    setTogglePopup(false);
  };

  const onSubmit = async (formData) => {
    dispatch(
      getRecommendedJobApi({
        status: "OPEN",
        s: formData.title,
        location: formData.location ? [formData.location.Id.toString()] : null,
        // expMin: 8,
        // expMax: 4,
        // salaryMin: 4, not working
        // salaryMax: 7, notworking
      })
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-expurple-100 rounded-md p-2 flex items-center justify-center divide-x-2 divide-exgray-100">
          <div className="flex-1">
            <Controller
              name="title"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "First Name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="flex-1 relative">
                  <input
                    type="text"
                    name="title"
                    onChange={onChange}
                    value={value}
                    placeholder="Job title, Keyword..."
                    className="outline-none bg-transparent py-3 w-full text-base px-10 placeholder:text-exgray-200"
                  />
                  <div className="absolute top-0 left-2 h-full flex items-center justify-center">
                    <SearchIcon />
                  </div>
                </div>
              )}
            />
          </div>

          <div className="w-[20%] relative">
            <Controller
              name="location"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title=""
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={locationsArray}
                  searchJob={true}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                />
              )}
            />

            <div className="absolute top-0 left-2 h-full flex items-center justify-center">
              <LocationIcon />
            </div>
          </div>

          {/* <div className="flex-1 relative">
          <input
            type="number"
            placeholder="Experience"
            className="outline-none bg-transparent py-3 w-full text-base px-10 placeholder:text-exgray-200"
          />
          <div className="absolute top-0 left-2 h-full flex items-center justify-center">
            <StackLayericon />
          </div>
        </div> */}

          <div className="w-[20%]">
            <button
              className="text-base text-expurple-800 w-full py-3 text-start px-8 flex items-center justify-between gap-4"
              type="button"
              onClick={togglePopupHandler}
            >
              Adavnce filter
              <ArrowIcon />
            </button>
          </div>
          <button
            type="submit"
            className="py-3 px-8 text-center bg-expurple-800 text-base font-semibold rounded-md text-exwhite-100"
          >
            Find Job
          </button>
        </div>
      </form>
      <Popup onClose={togglePopupHandler} visible={togglePopup}>
        <AdvanceFilterForm onClose={togglePopupHandler} />
      </Popup>
    </div>
  );
};

export default SearchBar;
