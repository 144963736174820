import { useLocation, useParams } from "react-router-dom";
import ScheduleCalenderIcon from "../../assets/Icons/ScheduleCalenderIcon";
import { useEffect } from "react";
import { useAppDispatch } from "../../config/hooks";
import { getJobByIdPopupApi } from "../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";

const InterviewStagesTab = () => {
  const { jobId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getJobByIdPopupApi({ jobId: jobId })
        ).then(unwrapResult);
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex items-center justify-center gap-4">
      <div className="flex-1 space-y-2">
        <div>
          <button className="flex items-center justify-center px-4 py-2 bg-primary-400 text-base text-exwhite-100 font-semibold rounded-md gap-2 w-fit ml-auto mr-0 active:scale-95">
            <ScheduleCalenderIcon color="white" /> Schedule Interview
          </button>
        </div>

        <div className="max-h-screen overflow-y-auto no-scrollbar">
          <div className="bg-exwhite-100 p-4 flex items-start justify-start gap-4 rounded-sm">
            <div className="text-center self-start">
              <h1 className="text-4xl text-secondary-400 font-semibold leading-none">
                07
              </h1>
              <p className="text-sm text-primary-400 font-semibold leading-none">
                Dec 2024
              </p>
            </div>
            <div className="flex-1 space-y-4">
              <div className="flex items-center justify-between">
                <div className="">
                  <h1 className="text-lg text-secondary-400 font-semibold">
                    Interview 1
                  </h1>
                  <p className="text-sm text-exgray-200/80 leading-none font-semibold">
                    Recruiter reviews the candidate's resume for qualifications
                    and skills.
                  </p>
                </div>

                <button className="px-4 py-2 text-secondary-400 text-base bg-secondary-100 rounded-full self-start">
                  Status : <span className="font-semibold">On Hold</span>
                </button>
              </div>

              <div className="space-y-4 border-b border-b-exgray-100 pb-2">
                <h1 className="text-sm text-exgray-200/80 leading-none font-semibold">
                  Interview Type :{" "}
                  <span className="text-exgray-600 font-semibold ">
                    On Hold
                  </span>
                </h1>

                <h1 className="text-sm text-exgray-200/80 leading-none font-semibold">
                  Interviewer :{" "}
                  <span className="text-exgray-600 font-semibold">
                    Sarah Johnson
                  </span>
                </h1>
              </div>

              <div className="space-y-2">
                <h1 className="text-exgray-200/80 text-base font-semibold">
                  Comments :
                </h1>

                <div className="space-y-1">
                  <div className="flex items-center justify-start gap-2">
                    <div className="w-7 h-7 bg-DefaultProfileImg bg-cover bg-center bg-no-repeat rounded-full"></div>
                    <h1 className="flex-1 text-base text-primary-400 font-semibold self-start">
                      Vinayak Sagar
                    </h1>
                  </div>

                  <h1 className="text-exgray-800 text-base ">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptates ut, incidunt deleniti facilis at enim modi
                    inventore minima optio delectus reiciendis porro eaque
                    eveniet! Natus repellendus vel ullam molestiae esse
                    delectus, officiis quae?
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-[380px] self-start space-y-2">
        <h1 className="text-base text-primary-400 font-semibold text-start">
          Tagged Job :
        </h1>
      </div>
    </div>
  );
};

export default InterviewStagesTab;
