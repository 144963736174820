import { useAppSelector } from "../../config/hooks";

const useQualifications = () => {
  let qualificationsArray = useAppSelector(
    (state) => state.appConfig.qualifications
  );

  const defaultQualification = (id) => {
    if (isNaN(id)) {
      return { Id: id, Title: id };
    }
    const filteredQualification = qualificationsArray.find(
      (location) => location.Id == id
    );
    return filteredQualification;
  };

  const defaultQualifications = (dafaultIds) => {
    const selectedQualifications = dafaultIds.map((id) => {
      if (isNaN(id)) {
        return { Id: id, Title: id };
      }

      const filteredQualification = qualificationsArray.find(
        (location) => location.Id == id
      );
      return filteredQualification;
    });

    return selectedQualifications;
  };

  return { qualificationsArray, defaultQualification, defaultQualifications };
};

export default useQualifications;
