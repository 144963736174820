import React from "react";

const EmployeePersonalInfo = () => {
  return (
    <div className="space-y-4 px-4">
      <div className="space-y-4">
        <h1 className="text-base text-primary-400 font-semibold">
          Current Address
        </h1>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Address 1 :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"456, Marine Drive"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Address 2 :</span>
            <p className="flex-1 text-base font-semibold">{` ${"Colaba"}`}</p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">City :</span>
            <p className="flex-1 text-base font-semibold">{` ${"Mumbai"}`}</p>
          </div>
        </div>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">State :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"Maharashtra"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Pin Code :</span>
            <p className="flex-1 text-base font-semibold">{` ${"400065"}`}</p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Country :</span>
            <p className="flex-1 text-base font-semibold">{` ${"India"}`}</p>
          </div>
        </div>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Contact Number :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"+91 7718839870"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Alternate Number :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"+91 7718839870"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Email Id :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"vinayak@gmail.com"}`}
            </p>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <h1 className="text-base text-primary-400 font-semibold">
          Permanent Address
        </h1>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Address 1 :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"456, Marine Drive"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Address 2 :</span>
            <p className="flex-1 text-base font-semibold">{` ${"Colaba"}`}</p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">City :</span>
            <p className="flex-1 text-base font-semibold">{` ${"Mumbai"}`}</p>
          </div>
        </div>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">State :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"Maharashtra"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Pin Code :</span>
            <p className="flex-1 text-base font-semibold">{` ${"400065"}`}</p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Country :</span>
            <p className="flex-1 text-base font-semibold">{` ${"India"}`}</p>
          </div>
        </div>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Contact Number :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"+91 7718839870"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Alternate Number :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"+91 7718839870"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Email Id :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"vinayak@gmail.com"}`}
            </p>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <h1 className="text-base text-primary-400 font-semibold">
          Emergency Contact
        </h1>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Name :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"Rahul Sharma"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Relationship :</span>
            <p className="flex-1 text-base font-semibold">{` ${"Brother"}`}</p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            {/* <span className="font-normal">City :</span>
                    <p className="flex-1 text-base font-semibold">
                      {` ${"Mumbai"}`}
                    </p> */}
          </div>
        </div>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Address 1 :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"456, Marine Drive"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Address 2 :</span>
            <p className="flex-1 text-base font-semibold">{` ${"Colaba"}`}</p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">City :</span>
            <p className="flex-1 text-base font-semibold">{` ${"Mumbai"}`}</p>
          </div>
        </div>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">State :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"Maharashtra"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Pin Code :</span>
            <p className="flex-1 text-base font-semibold">{` ${"400065"}`}</p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Country :</span>
            <p className="flex-1 text-base font-semibold">{` ${"India"}`}</p>
          </div>
        </div>

        <div className="flex items-start justify-start">
          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Contact Number :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"+91 7718839870"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Alternate Number :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"+91 7718839870"}`}
            </p>
          </div>

          <div className="flex flex-1 items-start justify-start gap-1 text-exgray-400">
            <span className="font-normal">Email Id :</span>
            <p className="flex-1 text-base font-semibold">
              {` ${"vinayak@gmail.com"}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeePersonalInfo;
