// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
// import "@cyntler/react-doc-viewer/dist/index.css";
// import { useMemo } from "react";

// const Pdf = ({ src, fileName }) => {
//   const docs = useMemo(() => {
//     if (src)
//       return [
//         {
//           uri: src,
//           fileType: src.includes(".pdf")
//             ? "pdf"
//             : src.includes(".doc")
//             ? "doc"
//             : "docx",
//           fileName: fileName,
//         },
//       ];
//     else return "";
//   }, [fileName, src]);

//   return (
//     docs && (
//       <div className="">
//         <DocViewer
//           documents={docs}
//           pluginRenderers={DocViewerRenderers}
//           // activeDocument={docs[0]}
//           config={{
//             header: {
//               disableFileName: true,
//               disableHeader: true,
//             },
//           }}
//           theme={{
//             primary: "#5296d8",
//             secondary: "#ffffff",
//             tertiary: "#5296d899",
//             text_primary: "#ffffff",
//             text_secondary: "#5296d8",
//             text_tertiary: "#00000099",
//             disableThemeScrollbar: false,
//           }}
//         />
//       </div>
//     )
//   );
// };

// export default Pdf;

import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const Pdf = ({ src }) => {
  const [numPages, setNumPages] = useState(null);

  // Callback when the document is successfully loaded
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <Document
      file={src}
      onLoadSuccess={onDocumentLoadSuccess}
      className={`space-y-2`}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          className={"shadow-md shadow-exgray-400"}
        />
      ))}
    </Document>
  );
};

export default Pdf;
