import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContactDetail } from "../models/contact.model";
import { CreateInteractionReq, Interaction } from "../models/interaction.model";
import { Client, FilterClientData, PaginatedClientResponse } from "../models/sales.model"
import salesService from "../services/sales.service";
import interactionService from "../services/interaction.service";
import contactService from "../services/contact.service";
import { CompanyProfile } from "../models/company.model";
import { logout } from "./loginSlice";
import { toast } from "sonner";

export interface SalesReduxState{
    clients:PaginatedClientResponse;
    clientDetail:Client|null;
    interactions:Array<Interaction>;
    contacts:Array<ContactDetail>;
    domain:CompanyProfile | null;
}

const initialState:SalesReduxState={
    clients:null,
    clientDetail: null,
    interactions:[],
    contacts:[],
    domain:null
}


export const createClientApi = createAsyncThunk(
    "sales/createClientApi",
    async (data: Client, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.createClient(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const updateClientApi = createAsyncThunk(
  "sales/updateClientApi",
  async (data: Client, { getState, rejectWithValue }) => {
    try {
      const response = await salesService.updateClient(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);


export const getAllClientsApi = createAsyncThunk(
    "sales/getAllClientsApi",
    async (data: null, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.getAllClients();
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getfilteredClientsApi = createAsyncThunk(
    "sales/getfilteredClientsApi",
    async (data: FilterClientData, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.getfilteredClients(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const getNextClientsApi = createAsyncThunk(
  "sales/getNextClientsApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      // console.log(state ,"NEXT URL")
      // console.log(state?.sales?.clients?.metadata?.nextResultURL)
      const response = await salesService.getClientByUrl(
        state?.sales?.clients?.metadata?.nextResultURL
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
  );
export const getClientByIdApi = createAsyncThunk(
    "sales/getClientByIdApi",
    async (clientId: string, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.getClientById(clientId);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const checkDomainApi = createAsyncThunk(
    "sales/checkDomainApi",
    async (domain: string, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.checkDomain(domain);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
);

export const createInteractionApi = createAsyncThunk(
    "sales/createInteractionApi",
    async (data: CreateInteractionReq, { getState, rejectWithValue }) => {
      try {
        const response = await interactionService.createInteraction(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );


export const getAllInteractionsApi = createAsyncThunk(
    "sales/getAllInteractionsApi",
    async (clientId: string, { getState, rejectWithValue }) => {
      try {
        const response = await interactionService.getAllInteractions(clientId);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const createContactApi = createAsyncThunk(
    "sales/createContactApi",
    async (data: ContactDetail, { getState, rejectWithValue }) => {
      try {
        const response = await contactService.createContact(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );


export const getAllContactsApi = createAsyncThunk(
    "sales/getAllContactsApi",
    async (clientId: string, { getState, rejectWithValue }) => {
      try {
        const response = await contactService.getAllContacts(clientId);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  
  

  export const salesSlice=createSlice({
    name:'sales',
    initialState,
    reducers:{
    },
   extraReducers:(builder)=>{
    builder.addCase(getAllClientsApi.fulfilled,(state,action)=>{
        console.log(action.payload.data)
        
        const client = action?.payload?.data;
        if (client) {      
          state.clients= client 
        }       
    })
    builder.addCase(getfilteredClientsApi.fulfilled,(state,action)=>{
      console.log(action.payload.data)
      
      const client = action?.payload?.data;
      if (client) {      
        state.clients= client 
      }       
  })
    builder.addCase(createClientApi.fulfilled,(state,action)=>{
      console.log(action.payload.data)
      const client = action.payload?.data;
      if (client && state?.clients?.clients) {
        state.clients.clients.unshift(client);  // Ensure that 'clients' is an array
      }
      // else{
      //   state.clients.clients=clients
      // }
    })
    builder.addCase(getClientByIdApi.fulfilled,(state,action)=>{
        console.log(action.payload.data,"GETCLIENTBYID")
        state.clientDetail=action.payload.data
    })
    builder.addCase(checkDomainApi.fulfilled,(state,action)=>{
        console.log(action.payload,"CHECKO DOMAIN")
        // state.clients.clients.push(action.payload.data.data)
    })
    builder.addCase(getAllInteractionsApi.fulfilled,(state,action)=>{
        console.log(action.payload.data,"ALL INTERACTION")
        if(action.payload.data!==null){
          state.interactions=action.payload.data
        }
        else{
          state.interactions=[]
        }
        // Y
        // state.clients.clients.push(action.payload.data.data)
    })
    builder.addCase(getAllContactsApi.fulfilled,(state,action)=>{
        console.log(action.payload.data,"CONTACTS")
        if(action.payload.data){
          state.contacts=action.payload.data
        }
        else{
          state.contacts=[]
        }
        // state.clients.clients.push(action.payload.data.data)
    })

     builder.addCase(getNextClientsApi.fulfilled, (state, action) => {
          const response = action.payload.data;
          console.log(response,"RESPONSEEDD")
          if (response?.clients && response?.clients.length > 0) {
            state.clients.clients = state.clients.clients.concat(response.clients);
          }
          state.clients.metadata = response.metadata;
    });

    builder.addCase(createContactApi.fulfilled,(state,action)=>{
      console.log(action.payload.data,"CONTACTS")
      if(action.payload.data){
        state.contacts=[...state.contacts,action.payload.data]
      }
    })

    builder.addCase(createInteractionApi.fulfilled,(state,action)=>{
      console.log(action.payload.data,"Interactions")
      if(action.payload.data){
        state.interactions=[...state.interactions,action.payload.data]
        toast.success("Interaction Created Successfully")
      }
    })
    builder.addCase(createInteractionApi.rejected,(state,action)=>{
      console.log(action.payload,"Interactions reject")
      toast.error(action.payload.error)
    })

     builder.addCase(logout, (state) => {
          // Reset this slice's state to initial state
          return initialState;
    });

   }
})

export default salesSlice.reducer;