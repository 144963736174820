import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getBulkCandidateDetailApi } from "../../slices/candidateProfileSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useLocation, useParams } from "react-router-dom";
import CandidateExperienceCard from "./CandidateExperienceCard";
import { v4 as uuidV4 } from "uuid";
import CandidateEducationCard from "./CandidateEducationCard";
import Pdf from "../pdf-viewer/Pdf";
import DownloadIcon from "../../assets/Icons/DownloadIcon";
import * as constants from "../../constant";
import axios from "axios";
import { toast } from "sonner";
import useIndustries from "../../utils/dropdown-options/useIndustries";
import useSalaries from "../../utils/dropdown-options/useSalaries";
import useLocations from "../../utils/dropdown-options/useLocations";
import moment from "moment";

const BasicInfo = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state?.login?.session?.token);
  const toggleSwitch = useAppSelector(
    (state) => state.jobs.isCandidateViewEnable
  );
  const { applicantId } = useParams();
  const { state } = useLocation();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { defaultIndustry } = useIndustries();
  const { defualtSalary } = useSalaries();
  const { defaultLocation, defaultLocations } = useLocations();

  const experienceData = {
    organization: state?.candidate?.previousCompany,
    userExperienceDetails: [
      {
        title: state?.candidate?.previousCompanyDesignation,
        description: "",
        employmentType: state?.candidate?.workMode,
        endDate: {
          month: moment(state?.candidate?.previousCompanyEndDate).format(
            "MMMM"
          ), // Full month name
          year: moment(state?.candidate?.previousCompanyEndDate).format("YYYY"),
        },
        isCurrentRole: true,
        location: "3",
        startDate: {
          month: moment(state?.candidate?.previousCompanyStartDate).format(
            "MMMM"
          ), // Full month name
          year: moment(state?.candidate?.previousCompanyStartDate).format(
            "YYYY"
          ),
        },
      },
    ],
  };

  const eduData = {
    degree: state?.candidate?.qualification,
    institute: state?.candidate?.university,
    startDate: {
      month: moment(state?.candidate?.qualificationStartDate).format("MMMM"), // Full month name
      year: moment(state?.candidate?.qualificationStartDate).format("YYYY"),
    },
    endDate: {
      month: moment(state?.candidate?.qualificationEndDate).format("MMMM"), // Full month name
      year: moment(state?.candidate?.qualificationEndDate).format("YYYY"),
    },
    description: "",
    grade: "",
  };

  const defaultCandidateIndustry = useMemo(() => {
    if (toggleSwitch) {
      return user?.userAdditionalInfo?.preferredIndustry &&
        user?.userAdditionalInfo?.preferredIndustry.length > 0
        ? defaultIndustry(user?.userAdditionalInfo?.preferredIndustry[0])
        : null;
    }
    return state?.candidate?.preferredIndustry &&
      state?.candidate?.preferredIndustry.length > 0
      ? defaultIndustry(state.candidate.preferredIndustry[0])
      : null;
  }, [
    defaultIndustry,
    state?.candidate?.preferredIndustry,
    toggleSwitch,
    user?.userAdditionalInfo?.preferredIndustry,
  ]);

  const defaultCurrentSal = useMemo(() => {
    if (toggleSwitch) {
      return user?.userAdditionalInfo?.lastSalary
        ? defualtSalary(user?.userAdditionalInfo?.lastSalary)
        : null;
    }
    return state?.candidate?.lastSalary
      ? defualtSalary(state?.candidate?.lastSalary)
      : null;
  }, [
    defualtSalary,
    state?.candidate?.lastSalary,
    toggleSwitch,
    user?.userAdditionalInfo?.lastSalary,
  ]);

  const defaultExpectedSal = useMemo(() => {
    if (toggleSwitch) {
      return user?.userAdditionalInfo?.expectation
        ? defualtSalary(user?.userAdditionalInfo?.expectation)
        : null;
    }
    return state?.candidate?.expectation
      ? defualtSalary(state?.candidate?.expectation)
      : null;
  }, [
    defualtSalary,
    state?.candidate?.expectation,
    toggleSwitch,
    user?.userAdditionalInfo?.expectation,
  ]);

  const defaultLoc = useMemo(() => {
    if (toggleSwitch) {
      return user?.userDetails?.location
        ? defaultLocation(user?.userDetails?.location)
        : null;
    }
    return state?.candidate?.location
      ? defaultLocation(state?.candidate?.location)
      : null;
  }, [
    defaultLocation,
    state?.candidate?.location,
    toggleSwitch,
    user?.userDetails?.location,
  ]);

  const defaultPreferedJobLocations = useMemo(() => {
    return state?.candidate?.preferredJobLocation &&
      state?.candidate?.preferredJobLocation.length > 0
      ? defaultLocations(state?.candidate?.preferredJobLocation)
      : null;
  }, [defaultLocations, state?.candidate?.preferredJobLocation]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getBulkCandidateDetailApi({ userId: applicantId })
        ).then(unwrapResult);
        setUser(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, []);

  const handleDownload = async () => {
    if (user?.userAdditionalInfo?.resume?.url) {
      axios
        .get(
          `${constants.baseUrl}/${user?.userAdditionalInfo?.resume?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = user?.userAdditionalInfo?.resume?.name;
          a.click();
        })
        .catch((err) => {
          toast.error("Unable to download resume");
        });
    } else if (state?.candidate?.attachment?.url) {
      axios
        .get(
          `${constants.baseUrl}/${state?.candidate?.attachment?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = state?.candidate?.attachment?.name;
          a.click();
        })
        .catch((err) => {
          toast.error("Unable to download resume");
        });
    }

    // else if (
    //   (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
    //     memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
    //   loginSelector?.who === constants.RECRUITER.toLowerCase()
    // ) {
    //   axios
    //     .get(
    //       `${constants.baseUrl}/${candidate?.applications[0]?.attachment?.url}?t=${token}`,
    //       {
    //         headers: {
    //           "Content-Type": "application/octet-stream",
    //         },
    //         responseType: "blob",
    //       }
    //     )
    //     .then((response) => {
    //       const a = document.createElement("a");
    //       const url = window.URL.createObjectURL(response.data);
    //       a.href = url;
    //       a.download = candidate?.applications[0]?.attachment?.name;
    //       a.click();
    //     })
    //     .catch((err) => {});
    // }
  };

  return (
    <div className="space-y-2 h-screenheight overflow-y-auto no-scrollbar">
      <div className="bg-exwhite-100 p-4 space-y-4 rounded-sm">
        <h1 className="text-sm text-primary-400 font-semibold pb-1 border-b border-exgray-100">
          Basic Information
        </h1>

        <div className="grid grid-cols-2 auto-rows-auto gap-y-4">
          {/* <div className="flex items-start justify-start gap-1">
            <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
              Gender :
            </h1>
            <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
              Male
            </p>
          </div> */}

          {
            <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Year of Experience :
              </h1>
              {toggleSwitch ? (
                <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                  {`${user?.userDetails?.totalExperience} Yrs`}
                </p>
              ) : (
                <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                  {`${state?.candidate?.yearsOfExperience} Yrs`}
                </p>
              )}
            </div>
          }

          {state?.candidate?.preferredIndustry[0] && (
            <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Industry :{" "}
              </h1>
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                {defaultCandidateIndustry?.Name}
              </p>
            </div>
          )}

          <div className="flex items-start justify-start gap-2">
            <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
              Current Salary :
            </h1>
            <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
              {defaultCurrentSal?.Title}
            </p>
          </div>

          {state?.candidate?.expectation && (
            <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Expected Salary :
              </h1>
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                {defaultExpectedSal?.Title}
              </p>
            </div>
          )}

          <div className="flex items-start justify-start gap-1">
            <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
              Location :
            </h1>
            <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
              {defaultLoc?.Name}
            </p>
          </div>

          <div className="flex items-start justify-start gap-1">
            <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
              Serving Notice Period :
            </h1>
            {toggleSwitch ? (
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                {user?.userAdditionalInfo?.isServingNoticePeriod ? "Yes" : "No"}
              </p>
            ) : (
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                {state?.candidate?.isOnNotice ? "Yes" : "No"}
              </p>
            )}
          </div>

          {/* <div className="flex items-start justify-start gap-1">
            <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
              Last Working Day :
            </h1>
            <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
              10/10/2010
            </p>
          </div> */}

          <div className="flex items-start justify-start gap-1">
            <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
              No of Days :
            </h1>
            <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
              {toggleSwitch
                ? user?.userAdditionalInfo?.noticePeriod
                : state?.candidate?.noOfServingDays}
            </p>
          </div>

          <div className="flex items-start justify-start gap-1">
            <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
              Work Mode :
            </h1>
            <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
              {toggleSwitch
                ? user?.userAdditionalInfo?.workMode
                : state?.candidate?.workMode}
            </p>
          </div>

          <div className="flex items-start justify-start gap-1">
            <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
              Preferred /Job Location :
            </h1>

            {defaultPreferedJobLocations &&
              defaultPreferedJobLocations.length > 0 &&
              defaultPreferedJobLocations.map((loc) => (
                <p
                  className="flex-1 text-base text-primary-400 font-semibold leading-none"
                  key={uuidV4()}
                >
                  {loc?.Name}
                </p>
              ))}
          </div>
        </div>

        <div className="flex items-start justify-start gap-1">
          <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
            Reason for Change :
          </h1>
          <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
            {state?.candidate?.reasonForJobChange}
          </p>
        </div>

        {state?.candidate?.comments &&
          state?.candidate?.comments.length > 0 && (
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <h1 className="w-fit text-sm text-primary-400 font-semibold leading-none">
                  Comments :
                </h1>

                <button className="text-secondary-400 text-sm text-center rounded-sm font-semibold">
                  {/* + Add Comment */}
                </button>
              </div>

              <div className="p-2 border border-[#E7E0C7] bg-[#FFF9E6] space-y-1">
                {/* <div className="flex items-center justify-start gap-2">
              <div className="h-8 w-8 rounded-full bg-DefaultProfileImg bg-cover bg-center bg-no-repeat"></div>
              <h1 className="flex-1 text-base text-exgray-600 font-semibold">
                Salahuddin Shaikh
              </h1>
              <p className="text-sm text-exgray-300 font-semibold">
                02 Jan 2024
              </p>
            </div> */}
                <div>
                  {state?.candidate?.comments &&
                    state?.candidate?.comments.length > 0 &&
                    state?.candidate?.comments.map((cmnt) => (
                      <h1
                        className="pl-10 text-exgray-200 font-semibold"
                        key={uuidV4()}
                      >
                        {cmnt.comment}
                      </h1>
                    ))}
                </div>
              </div>
            </div>
          )}
      </div>

      {!loading && user?.userExperience && user?.userExperience?.length > 0 && (
        <div className="bg-exwhite-100 p-4 space-y-2">
          <h1 className="text-sm text-primary-400 font-semibold pb-1 border-b border-exgray-100">
            Experience Details
          </h1>

          <div className="max-h-[500px] overflow-y-auto no-scrollbar divide-y-2 divide-exgray-100/50">
            {toggleSwitch ? (
              user.userExperience.map((experience) => (
                <CandidateExperienceCard
                  experience={experience}
                  key={uuidV4()}
                />
              ))
            ) : (
              <CandidateExperienceCard
                experience={experienceData}
                key={uuidV4()}
              />
            )}
          </div>
        </div>
      )}

      {!loading && user?.userEducation && user?.userEducation?.length > 0 && (
        <div className="bg-exwhite-100 p-4 space-y-2">
          <h1 className="text-sm text-primary-400 font-semibold pb-1 border-b border-exgray-100">
            Education Details
          </h1>

          <div className="max-h-[500px] overflow-y-auto no-scrollbar divide-y-2 divide-exgray-100/50">
            {toggleSwitch ? (
              user.userEducation.map((education) => (
                <CandidateEducationCard education={education} key={uuidV4()} />
              ))
            ) : (
              <CandidateEducationCard education={eduData} key={uuidV4()} />
            )}
          </div>
        </div>
      )}

      <div className="bg-exwhite-100 p-4 space-y-4">
        <h1 className="text-sm text-primary-400 font-semibold pb-1 border-b border-exgray-100">
          Key Skills
        </h1>

        {toggleSwitch
          ? user?.userDetails?.skills &&
            user?.userDetails?.skills.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {user?.userDetails?.skills.map((skill) => (
                  <p
                    className="px-4 py-1 text-start text-base text-exgray-200 font-semibold rounded-full bg-exgray-100/50"
                    key={uuidV4()}
                  >
                    {skill}
                  </p>
                ))}
              </div>
            )
          : state?.candidate?.skills &&
            state?.candidate?.skills.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {state?.candidate?.skills.map((skill) => (
                  <p
                    className="px-4 py-1 text-start text-base text-exgray-200 font-semibold rounded-full bg-exgray-100/50"
                    key={uuidV4()}
                  >
                    {skill}
                  </p>
                ))}
              </div>
            )}

        {/* {user?.userDetails?.skills && user?.userDetails?.skills.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {user?.userDetails?.skills.map((skill) => (
              <p
                className="px-4 py-1 text-start text-base text-exgray-200 font-semibold rounded-full bg-exgray-100/50"
                key={uuidV4()}
              >
                {skill}
              </p>
            ))}
          </div>
        ) : state?.candidate?.skills && state?.candidate?.skills.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {state?.candidate?.skills.map((skill) => (
              <p
                className="px-4 py-1 text-start text-base text-exgray-200 font-semibold rounded-full bg-exgray-100/50"
                key={uuidV4()}
              >
                {skill}
              </p>
            ))}
          </div>
        ) : null} */}
      </div>

      {!loading && (
        <div className="bg-exwhite-100 p-4 space-y-4">
          <div className="flex items-center justify-between pb-2 border-b border-exgray-100">
            <h1 className="text-sm text-primary-400 font-semibold">
              CV / Resume
            </h1>

            <button
              className="p-1 border-2 border-secondary-400 mr-4 rounded-md active:scale-95"
              onClick={handleDownload}
            >
              <DownloadIcon />
            </button>
          </div>

          {toggleSwitch ? (
            <div className="w-fit mx-auto pt-2">
              <Pdf
                src={`${constants.baseUrl}/${user?.userAdditionalInfo?.resume?.url}?t=${token}`}
              />
            </div>
          ) : (
            <div className="w-fit mx-auto pt-2">
              <Pdf
                src={`${constants.baseUrl}/${state?.candidate?.attachment?.url}?t=${token}`}
              />
            </div>
          )}
        </div>
      )}
      {/* {user?.userAdditionalInfo?.resume?.url &&
          user?.userAdditionalInfo?.resume?.url.includes(".pdf") ? (
            <div className="w-fit mx-auto h-screenheight overflow-auto no-scrollbar px-2 pt-2">
              <Pdf
                src={`${constants.baseUrl}/${user?.userAdditionalInfo?.resume?.url}?t=${token}`}
              />
            </div>
          ) : user?.userAdditionalInfo?.resume?.url &&
            (user?.userAdditionalInfo?.resume?.url.includes(".doc") ||
              user?.userAdditionalInfo?.resume?.url.includes(".docx")) ? (
            <div className="w-fit mx-auto h-screenheight overflow-auto no-scrollbar px-2 pt-2">
              <Pdf
                src={`${constants.baseUrl}/${user?.userAdditionalInfo?.resume?.url}?t=${token}`}
              />
            </div>
          ) : (
            <div className="h-[300px] flex items-center justify-center">
              <h1>CV / Resume Not Available</h1>
            </div>
          )}
        </div>
      )} */}
    </div>
  );
};

export default BasicInfo;
