import { useEffect } from "react";

import {
  getMemberByIdApi,
  getMembersApi,
} from "../../../../slices/companyMemberSlice";

import * as constants from "../../../../constant";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { toast } from "sonner";
import SideMenu from "../../../../components/side-menu/SideMenu";
import { Outlet } from "react-router-dom";

// const navigationButtons = [
//   { title: "Profile" },
//   { title: "Jobs" },
//   { title: "Members" },
// ];

const CompanyDashboard = () => {
  const dispatch = useAppDispatch();

  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state?.member);

  useEffect(() => {
    try {
      dispatch(
        getMembersApi({
          companyId:
            loginSelector.who === constants.COMPANY.toLowerCase()
              ? userId
              : memberSelector?.memberDetails?.companyId,
        })
      );
    } catch (error) {
      toast.error("Unable to fetch employees data");
    }
  }, []);

  useEffect(() => {
    if (loginSelector.who === constants.COMPANY_MEMBER.toLowerCase())
      try {
        dispatch(getMemberByIdApi({ memberId: userId }));
      } catch (error) {
        toast.error("Unable to fetch employees data");
      }
  }, []);

  return (
    <div className="">
      <div className="w-[98%] min-h-screenheight grid grid-cols-[300px_1fr] gap-x-5 mx-auto py-4">
        <div>
          <SideMenu />
        </div>
        <div className="flex-1 self-start overflow-x-scroll no-scrollbar ">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CompanyDashboard;
