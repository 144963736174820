import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "../modules/ats/pages/main/Main";
import HomePage from "../modules/home/home-page/HomePage";
import CandidateLogin from "../modules/home/logins/candidate/CandidateLogin";
import CompanyLogin from "../modules/home/logins/company/CompanyLogin";
import RecruiterLogin from "../modules/home/logins/recuiter/RecruiterLogin";
import Candidate from "../modules/home/logins/candidate/Candidate";
import CandidateCreate from "../modules/home/logins/candidate/CandidateCreate";
import Company from "../modules/home/logins/company/Company";
import CompanyCreate from "../modules/home/logins/company/CompanyCreate";
import Recruiter from "../modules/home/logins/recuiter/Recruiter";
import RecruiterCreate from "../modules/home/logins/recuiter/RecruiterCreate";
import CandidateProfile from "../modules/ats/pages/candidate-profile/CandidateProfile";

import CandidateAppliedJobs from "../modules/ats/pages/candidate-applied-jobs/CandidateAppliedJobs";
import JobSearch from "../modules/ats/pages/job-search/JobSearch";
import CompanyProfile from "../modules/ats/pages/company-profile/CompanyProfile";
import CompanyDashboard from "../modules/ats/pages/company-dashboard/CompanyDashboard";
import CreateJob from "../modules/ats/pages/company-dashboard/components/CreateJob";
import JobPage from "../modules/ats/pages/company-job-page/JobPage";
import MemberSignup from "../modules/home/logins/signup-member/MemberSignup";
import MemberPage from "../modules/ats/pages/member-page/MemberPage";
import BundleUpload from "../modules/ats/pages/bundle-upload/BundleUpload";
import RecruiterDashboard from "../modules/ats/pages/recruiter-dashboard/RecruiterDashboard";
import ProtectedRoute from "../components/protected-route/ProtectedRoute";
import JobSeekers from "../modules/ats/pages/bundle-upload/JobSeekers";
import BundleUploadSuccess from "../modules/ats/pages/bundle-upload/BundleUploadSuccess";
import JobSeerkerView from "../modules/ats/pages/bundle-upload/JobSeerkerView";
import CompanyOverview from "../modules/ats/pages/company-dashboard/components/CompanyOverview ";
import CompanyMembersDashboard from "../modules/ats/pages/company-dashboard/components/CompanyMembersDashboard";
import CompanyMemberList from "../modules/ats/pages/company-dashboard/components/CompanyMemberList";
import CompanyMemberDetails from "../modules/ats/pages/company-dashboard/components/CompanyMemberDetails";
import CompanyLegalDocs from "../modules/ats/pages/company-dashboard/components/CompanyLegalDocs";
import AtsDashboard from "../modules/ats/pages/application-tracking-system/dashboard/AtsDashboard";
import CompanyJobsDashboard from "../modules/ats/pages/company-dashboard/components/CompanyJobsDashboard";
import CandidateView from "../components/candidate-view/CandidateView";
import SalesDashborad from "../modules/sales/SalesDashborad";
import ClientManagement from "../modules/sales/LeadManagement";
import ClientList from "../modules/sales/ClientList";
import JobApplicants from "../modules/ats/pages/company-job-page/components/JobApplicants";
import TaggedList from "../modules/ats/pages/company-job-page/components/TaggedList";
import EmployeeList from "../modules/hrms/EmployeeList";
import Notifications from "../modules/hrms/Notifications";
import Documentation from "../modules/hrms/Documentation";
import Onboarding from "../modules/hrms/Onboarding";
import OnboardingManagement from "../modules/hrms/OnboardingManagement";
import OnboardingSetup from "../modules/hrms/onboarding/OnboardingSetup";
import DocumentList from "../modules/hrms/onboarding/DocumentList";
import KycDocuments from "../modules/hrms/onboarding/KycDocuments";
import StatutoryDocuments from "../modules/hrms/onboarding/StatutoryDocuments";
import BasicInfo from "../components/candidate-view/BasicInfo";
import ResumeTab from "../components/candidate-view/ResumeTab";
import InterviewStagesTab from "../components/candidate-view/InterviewStagesTab";
import ClientDetail from "../modules/sales/ClientDetail";
import BulkUpload from "../modules/ats/pages/bundle-upload/BulkUpload";
import Employee from "../modules/hrms/employeeProfile/Employee";
import EmplpoyeeDashboard from "../modules/hrms/employeeProfile/EmplpoyeeDashboard";
import EmployeeProfile from "../modules/hrms/employeeProfile/EmployeeProfile";
import DocumentManagement from "../modules/hrms/components/DocumentManagement";
import EmploymentDetails from "../modules/hrms/components/EmploymentDetails";
import HealthBenefits from "../modules/hrms/components/HealthBenefits";
import EmployeeDetail from "../modules/hrms/components/EmployeeDetail";
import EmployeeAttendance from "../modules/hrms/employeeProfile/EmployeeAttendance";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      { path: "/", element: <HomePage /> },
      {
        path: "/candidate",
        element: <Candidate />,
        children: [
          {
            path: "/candidate",
            element: <CandidateLogin />,
          },
          {
            path: "/candidate/register",
            element: <CandidateCreate />,
          },
        ],
      },
      {
        path: "/company",
        element: <Company />,
        children: [
          {
            path: "/company",
            element: <CompanyLogin />,
          },
          {
            path: "/company/register",
            element: <CompanyCreate />,
          },
        ],
      },
      {
        path: "/recruiter",
        element: <Recruiter />,
        children: [
          {
            path: "/recruiter",
            element: <RecruiterLogin />,
          },
          {
            path: "/recruiter/register",
            element: <RecruiterCreate />,
          },
        ],
      },
      {
        path: "/candidate-profile",
        element: <ProtectedRoute element={<CandidateProfile />} />,
      },
      {
        path: "/applied-jobs",
        element: <ProtectedRoute element={<CandidateAppliedJobs />} />,
      },
      {
        path: "/job-search",
        element: <JobSearch />,
      },
      {
        path: "/company-profile",
        element: <CompanyProfile />,
      },
      //company flow starts here
      {
        path: "/company-admin",
        element: <ProtectedRoute element={<CompanyDashboard />} />,
        children: [
          {
            path: "/company-admin",
            element: <ProtectedRoute element={<CompanyOverview />} />,
          },
          {
            path: "/company-admin/employees",
            element: <ProtectedRoute element={<CompanyMembersDashboard />} />,
            children: [
              {
                path: "/company-admin/employees",
                element: <ProtectedRoute element={<CompanyMemberList />} />,
              },
              {
                path: "/company-admin/employees/teams",
                element: <ProtectedRoute element={<div>list</div>} />,
              },
            ],
          },
          {
            path: "/company-admin/member-details/:memberId",
            element: <ProtectedRoute element={<CompanyMemberDetails />} />,
          },
          {
            path: "/company-admin/docs",
            element: <ProtectedRoute element={<CompanyLegalDocs />} />,
          },
          {
            path: "/company-admin/ATS-Dashboard",
            element: <ProtectedRoute element={<AtsDashboard />} />,
          },
          {
            path: "/company-admin/jobs",
            element: <CompanyJobsDashboard />,
          },
          {
            path: "/company-admin/create-job",
            element: <ProtectedRoute element={<CreateJob />} />,
          },
          {
            path: "/company-admin/update-job/:jobId",
            element: <ProtectedRoute element={<CreateJob />} />,
          },
          {
            path: "/company-admin/job/:jobId",
            element: <ProtectedRoute element={<JobPage />} />,
            children: [
              {
                path: "/company-admin/job/:jobId",
                element: <ProtectedRoute element={<JobApplicants />} />,
              },
              {
                path: "/company-admin/job/:jobId/tagged-list",
                element: <ProtectedRoute element={<TaggedList />} />,
              },
            ],
          },
          {
            path: "/company-admin/candidate-pool",
            element: <ProtectedRoute element={<JobSeekers />} />,
          },
          {
            path: "/company-admin/bulk-upload",
            element: <ProtectedRoute element={<BulkUpload />} />,
          },
          {
            path: "/company-admin/success",
            element: <ProtectedRoute element={<BundleUploadSuccess />} />,
          },
          {
            path: "/company-admin/job/:jobId/applicant/:applicantId",
            element: <ProtectedRoute element={<CandidateView />} />,
            children: [
              {
                path: "/company-admin/job/:jobId/applicant/:applicantId",
                element: <ProtectedRoute element={<BasicInfo />} />,
              },
              {
                path: "/company-admin/job/:jobId/applicant/:applicantId/resume",
                element: <ProtectedRoute element={<ResumeTab />} />,
              },
              {
                path: "/company-admin/job/:jobId/applicant/:applicantId/interview-stages",
                element: <ProtectedRoute element={<InterviewStagesTab />} />,
              },
            ],
          },
          {
            path: "/company-admin/sales",
            element: <ProtectedRoute element={<SalesDashborad />} />,
          },
          {
            path: "/company-admin/sales/lead-management",
            element: <ProtectedRoute element={<ClientManagement />} />,
          },
          {
            path: "/company-admin/sales/client-list",
            element: <ProtectedRoute element={<ClientList />} />,
          },
          {
            path: `/company-admin/sales/detail/:clientId`,
            element: <ProtectedRoute element={<ClientDetail />} />,
          },
          {
            path: "/company-admin/employee-list",
            element: <ProtectedRoute element={<EmployeeList />} />,
            children: [
              {
                path: "/company-admin/employee-list",
                element: <ProtectedRoute element={<CompanyMemberList />} />,
              },
              {
                path: "/company-admin/employee-list/teams",
                element: <ProtectedRoute element={<div>list</div>} />,
              },
            ],
          },
          {
            path: "/company-admin/notification",
            element: <ProtectedRoute element={<Notifications />} />,
          },
          {
            path: "/company-admin/documentation",
            element: <ProtectedRoute element={<Documentation />} />,
          },
          {
            path: "/company-admin/onboarding",
            element: <ProtectedRoute element={<Onboarding />} />,
          },
          {
            path: "/company-admin/employee-profile",
            element: <ProtectedRoute element={<EmployeeProfile />} />,
            children: [
              {
                path: "/company-admin/employee-profile/:memberId/",
                element: <ProtectedRoute element={<DocumentList />} />,
              },
              {
                path: "/company-admin/employee-profile/:memberId/kyc-document",
                element: <ProtectedRoute element={<KycDocuments />} />,
              },
              {
                path: "/company-admin/employee-profile/:memberId/statutory-document",
                element: <ProtectedRoute element={<StatutoryDocuments />} />,
              },
            ],
          },
          {
            path: "/company-admin/candidate-search",
            element: <ProtectedRoute element={<h1>hello world</h1>} />,
          },
        ],
      },
      //company flow ends here
      {
        path: "/compnay-profile-edit/:companyId",
        element: <ProtectedRoute element={<CompanyProfile />} />,
      },
      {
        path: "/create-job",
        element: <ProtectedRoute element={<CreateJob />} />,
      },
      {
        path: "/job/:jobId",
        element: <ProtectedRoute element={<JobPage />} />,
      },
      {
        path: "/update-job/:jobId",
        element: <ProtectedRoute element={<CreateJob />} />,
      },
      {
        path: "/signup-member/:memberId",
        element: <MemberSignup />,
      },
      {
        path: "/member",
        element: <ProtectedRoute element={<MemberPage />} />,
      },
      {
        path: "/recruiter-dashboard",
        element: <ProtectedRoute element={<RecruiterDashboard />} />,
      },
      {
        path: "/bulk-upload",
        element: <ProtectedRoute element={<BundleUpload />} />,
      },
      {
        path: "/job-seekers",
        element: <ProtectedRoute element={<JobSeekers />} />,
      },
      {
        path: "/job-seekers/:userId",
        element: <ProtectedRoute element={<JobSeerkerView />} />,
      },
      {
        path: "/employee-dashboard",
        element: <ProtectedRoute element={<Employee />} />,
        children: [
          {
            path: "/employee-dashboard",
            element: <ProtectedRoute element={<EmplpoyeeDashboard />} />,
          },
          {
            path: "/employee-dashboard/employee-profile/:memberId",
            element: <ProtectedRoute element={<EmployeeProfile />} />,
            children: [
              {
                path: "/employee-dashboard/employee-profile/:memberId",
                element: <ProtectedRoute element={<DocumentList />} />,
              },
              // {
              //   path: "/employee-dashboard/employee-profile/:memberId",
              //   element: <ProtectedRoute element={<DocumentList />} />,
              // },
              {
                path: "/employee-dashboard/employee-profile/:memberId/kyc-document",
                element: <ProtectedRoute element={<KycDocuments />} />,
              },
              {
                path: "/employee-dashboard/employee-profile/:memberId/statutory-document",
                element: <ProtectedRoute element={<StatutoryDocuments />} />,
              },
            ],
          },
          {
            path: "/employee-dashboard/employee-attendance",
            element: <ProtectedRoute element={<EmployeeAttendance />} />,
          },
          {
            path: "/employee-dashboard/employee-performance",
            element: <ProtectedRoute element={<EmployeeProfile />} />,
          },
          {
            path: "/employee-dashboard/employee-leave-management",
            element: <ProtectedRoute element={<EmployeeProfile />} />,
          },
          {
            path: "/employee-dashboard/employee-payroll",
            element: <ProtectedRoute element={<EmployeeProfile />} />,
          },
        ],
      },
      {
        path: "/success",
        element: <ProtectedRoute element={<BundleUploadSuccess />} />,
      },
    ],
  },
]);

const Routes = () => {
  return <RouterProvider router={routes} />;
};

export default Routes;
