import React, { useState } from "react";
import Bargraph from "../../../../../components/graph/Bargraph";
import SearchIcon from "../../../../../assets/Icons/SearchIcon";
import { v4 as uuidV4 } from "uuid";

const applicantStatus = [
  {
    title: "All",
  },
  {
    title: "New",
  },
  {
    title: "Interview",
  },
  {
    title: "Selected",
  },
  {
    title: "Onboard",
  },
  {
    title: "Rejected",
  },
  {
    title: "Withdraw",
  },
];

const clients = [
  {
    title: "Nike,Inc",
    jobCounts: 200,
  },
  {
    title: "Walmart.Inc",
    jobCounts: 200,
  },
  {
    title: "Apple",
    jobCounts: 200,
  },
  {
    title: "Chroma",
    jobCounts: 200,
  },
  {
    title: "Starbucks Corporation",
    jobCounts: 200,
  },
];

const applicants = [
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
  {
    name: "Natali Craig",
    designantion: "Senior Data Analyst",
    location: "New York, USA",
    team: "Team A",
    date: "19/10/2024",
    status: "New",
  },
];

const AtsDashboard = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const onChange = (e) => {};

  const toggleTabHandler = (index) => {
    setTabIndex(index);
  };

  return (
    <div className="min-h-screenheight px-4 space-y-4">
      <div className="flex items-stretch justify-between gap-4">
        <div className="flex-1 space-y-3 flex flex-col">
          <div className="flex items-stretch justify-center gap-4">
            <div className="flex-1 py-7 px-14 bg-exwhite-100 border border-exgray-100 text-center text-primary-500 hover:border-primary-500 hover:bg-primary-500 hover:text-exwhite-100 rounded-md cursor-pointer">
              <h1 className="text-4xl font-semibold">145</h1>
              <p className="text-base font-normal">Total Jobs</p>
            </div>
            <div className="flex-1 py-7 px-14 bg-exwhite-100 border border-exgray-100 text-center text-primary-500 hover:border-primary-500 hover:bg-primary-500 hover:text-exwhite-100 rounded-md cursor-pointer">
              <h1 className="text-4xl font-semibold">25K</h1>
              <p className="text-base font-normal">Applications Recived</p>
            </div>
            <div className="flex-1 py-7 px-14 bg-exwhite-100 border border-exgray-100 text-center text-primary-500 hover:border-primary-500 hover:bg-primary-500 hover:text-exwhite-100 rounded-md cursor-pointer">
              <h1 className="text-4xl font-semibold">12K</h1>
              <p className="text-base font-normal">Candidate Data </p>
            </div>
          </div>

          <div className="bg-exwhite-100 p-4 border border-exgray-100 rounded-lg flex-grow">
            <Bargraph />
          </div>
        </div>

        <div className="w-[40%] bg-exwhite-100 rounded-lg border border-exgray-100 py-2 ">
          <h1 className="px-4 pb-1 text-base text-primary-500 font-semibold border-b-2 border-exgray-100">
            Client Records
          </h1>

          <div className={`${clients.length < 4 && "h-96"}`}>
            {clients.slice(0, 4).map((client) => (
              <div
                className="flex items-center justify-between gap-4 px-4 py-4 border-b-2 border-exgray-100"
                key={uuidV4()}
              >
                <div className="h-12 w-12 bg-deafultCompanyProfile bg-cover bg-center bg-no-repeat rounded-md"></div>

                <h1 className="flex-1 text-base text-exgray-100 font-semibold tracking-wide line-clamp-1">
                  {client.title}
                </h1>

                <span className="bg-[#29CC390D] text-[#0BA02C] text-base font-semibold text-center px-4 py-1 rounded-full">
                  {`${client.jobCounts} Jobs`}
                </span>
              </div>
            ))}
          </div>

          {clients.length >= 4 && (
            <div className="py-2 my-2">
              <div className="w-fit ml-auto mr-0">
                <button className="text-primary-500 font-semibold text-center px-2">
                  Show All
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bg-exwhite-100 p-4 rounded-lg border border-exgray-100 space-y-4">
        <div className="flex items-center justify-between gap-4">
          <h1 className="text-exgray-800 text-base font-semibold">
            Candidate Status
          </h1>

          <div className="w-[50%] relative">
            <input
              type="text"
              name="title"
              onChange={onChange}
              // value={value}
              placeholder="Search Applicant"
              className="outline outline-2 outline-exgray-100 bg-transparent py-2 w-full text-base px-10 placeholder:text-exgray-100 rounded-full text-exgray-400"
            />
            <div className="absolute top-0 left-2 h-full flex items-center justify-center">
              <SearchIcon color="#042347" />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start">
          {applicantStatus.map((statusFilter, index) => (
            <button
              className={`px-4 py-1 border-b-2 ${
                tabIndex === index
                  ? "border-primary-500 text-primary-500"
                  : "text-exgray-100 border-exgray-100"
              } text-base text-center font-semibold`}
              onClick={() => toggleTabHandler(index)}
              key={uuidV4()}
            >
              {statusFilter.title}
            </button>
          ))}
          <div className="border-b-2 border-exgray-100 flex-1 self-end"></div>
        </div>

        <div className="space-y-4 px-4">
          <div className="flex items-center justify-start gap-2 text-exgray-100">
            <h1 className="flex-1 text-start text-sm font-semibold">
              Candidate
            </h1>
            <h1 className="flex-1 text-start text-sm font-semibold">
              Designantion
            </h1>
            <h1 className="flex-1 text-start text-sm font-semibold">
              Location
            </h1>
            <h1 className="flex-1 text-start text-sm font-semibold">Team</h1>
            <h1 className="flex-1 text-start text-sm font-semibold">
              Applicant Date
            </h1>
            <h1 className="flex-1 text-start text-sm font-semibold">Status</h1>
          </div>
        </div>

        <div className="h-96 overflow-y-auto no-scrollbar">
          {applicants.map((applicant) => (
            <div
              className="flex items-center justify-start gap-2 odd:bg-exgray-100/20 p-4 rounded-lg cursor-pointer border-4 border-transparent hover:border-[#0366d64d]"
              key={uuidV4()}
            >
              <div className="flex-1 flex items-center justify-start gap-1">
                <div className="h-8 w-8 rounded-full bg-DefaultProfileImg bg-cover bg-center bg-no-repeat"></div>
                <h1 className="text-base text-exgray-800 font-semibold line-clamp-1">
                  Natali Craig
                </h1>
              </div>

              <h1 className="flex-1 text-base text-exgray-800 line-clamp-1">
                Senior Data Analyst
              </h1>

              <h1 className="flex-1 text-base text-exgray-800 line-clamp-1">
                New York, USA
              </h1>

              <h1 className="flex-1 text-base text-exgray-800 line-clamp-1">
                Team A
              </h1>

              <h1 className="flex-1 text-base text-exgray-800 line-clamp-1">
                19/10/2024
              </h1>

              <div className="flex-1">
                <p className="w-fit text-base text-exgray-800 font-semibold px-4 py-1 bg-[#3361FF4D] rounded-full">
                  New
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AtsDashboard;
