import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../../components/basic-components/ExInput";
import { Link } from "react-router-dom";
import Popup from "../../../../components/popup/Popup";
import OtpField from "../../../../components/otp/OtpField";
import { useAppDispatch } from "../../../../config/hooks";
import {
  getOtpDomainVerification,
  verifyCompanyDomain,
} from "../../../../slices/loginSlice";
import { setComapnyDomain } from "../../../../slices/companySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../../../constant";

const CompanyCreate = () => {
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      domainName: "",
      emailId: "",
      // designantion: "",
      // companyName: "",
      // tagline: "",
      // countryCode: null,
      // phoneNo: "",
      // industry: null,
      // function: null,
      // registrationType: null,
      // companyType: null,
      // companySize: "",
      // logo: "",
      // companyDescription: "",
    },
  });

  const toglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const onSubmit = async (formData) => {
    try {
      const response = await dispatch(
        verifyCompanyDomain({
          domain: formData.domainName,
          email: formData.emailId,
        })
      ).then(unwrapResult);
    } catch (error) {
      try {
        const response = await dispatch(
          getOtpDomainVerification({
            domain: formData.domainName,
            emailId: formData.emailId,
          })
        ).then(unwrapResult);
        if (response) {
          setTogglePopup(true);
          dispatch(
            setComapnyDomain({
              companyDomain: formData.domainName,
              companyEmail: formData.emailId,
            })
          );
        }
      } catch (error) {}
    }
  };

  // const modifiedCountryCodes = countryCodeOption.map((country) => ({
  //   ...country,
  //   PhoneCode: `${country.Iso3} +${country.PhoneCode}`,
  // }));

  return (
    <div className="h-full flex">
      <div className="w-full h-full"></div>
      <div className="h-full w-full">
        <h1 className="text-3xl text-expurple-800 font-semibold text-center pb-4 pt-10">
          Signup As a Company
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[50%] h-[80%] mx-auto space-y-3 overflow-y-scroll no-scrollbar"
        >
          {/* <div>
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Company name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Company Name"
                  placeholder="Enter Here"
                  name="companyName"
                  onChange={onChange}
                  value={value}
                  error={errors?.companyName?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="designantion"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Designantion is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Designation"
                  placeholder="Enter Here"
                  name="designantion"
                  onChange={onChange}
                  value={value}
                  error={errors?.designantion?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="tagline"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Tagline is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Tagline *"
                  placeholder="Connect | Collaborate | Celebrate"
                  name="tagline"
                  onChange={onChange}
                  value={value}
                  error={errors?.tagline?.message}
                />
              )}
            />
          </div> */}

          <div>
            <Controller
              name="domainName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Domain name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Domain Name *"
                  placeholder="eg. abc.com"
                  name="domainName"
                  onChange={onChange}
                  value={value}
                  error={errors?.domainName?.message}
                />
              )}
            />
          </div>

          {/* <div className="flex items-center justify-center gap-2">
            <div className="w-full self-start">
              <Controller
                name="industry"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Industry is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Industry *"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={experienceInYears}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.industry?.message}
                  />
                )}
              />
            </div>
            <div className="w-full self-start">
              <Controller
                name="function"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Function required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Function *"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={experienceInMonths}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.function?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="">
            <Controller
              name="registrationType"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Registration type is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Registration Type *"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Title"
                  options={experienceInYears}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  error={errors?.registrationType?.message}
                />
              )}
            />
          </div>

          <div className="">
            <Controller
              name="companyType"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Company type is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Company Type *"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Title"
                  options={experienceInYears}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  error={errors?.companyType?.message}
                />
              )}
            />
          </div>

          <div className="">
            <Controller
              name="companySize"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Company size is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Size *"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Title"
                  options={experienceInYears}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  error={errors?.companySize?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="logo"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please upload logo",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExUplaodInput
                  title={"Logo *"}
                  name={"logo"}
                  onChange={onChange}
                  // value={value}
                  error={errors?.logo?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="companyDescription"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Description is required",
                },
                maxLength: {
                  value: 2600,
                  message: "Only 2600 characters are allowed",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExTextarea
                  rows={8}
                  name="companyDescription"
                  value={value || ""}
                  title="About the company *"
                  maxLength={2600}
                  onChange={onChange}
                  placeholder="This information will be seen by the candidate in job discription."
                  error={errors?.companyDescription?.message}
                />
              )}
            />
          </div> */}

          <div>
            <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Official Email ID *"
                  placeholder="eg.abc@abc.com"
                  name="emailId"
                  onChange={onChange}
                  value={value}
                  error={errors?.emailId?.message}
                />
              )}
            />
          </div>

          {/* <div className="w-full">
            <div className="flex items-center justify-between gap-2">
              <div className="min-w-[120px] self-start">
                <Controller
                  name="countryCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Country *"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="PhoneCode"
                      options={modifiedCountryCodes}
                      dropDownHeight="40px"
                      dropDownBorderWidth="0px"
                      dropDownBorderColor="#696969"
                      error={errors?.countryCode?.message}
                    />
                  )}
                />
              </div>
              <div className="flex-1 self-start">
                <Controller
                  name="phoneNo"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Mobile no is required",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid mobile number",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExInput
                      title="Mobile No. *"
                      placeholder="Enter here"
                      name="phoneNo"
                      onChange={onChange}
                      type="number"
                      value={value}
                      error={errors?.phoneNo?.message}
                    />
                  )}
                />
              </div>
            </div>
          </div> */}

          <div className="flex items-center justify-center pt-4">
            <div className="w-fit mx-auto">
              <button
                type="submit"
                className={`py-2 px-10 bg-expurple-800 text-lg text-exwhite-100 text-center font-semibold rounded-xl tracking-wider`}
              >
                Create Account
              </button>
            </div>

            <div>
              <div className="w-fit ml-auto mr-0">
                <Link
                  to={"/company"}
                  className="text-center text-lg text-expurple-800 underline tracking-wider"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Popup onClose={() => setTogglePopup(false)} visible={togglePopup}>
        <div className="bg-expurple-100 p-10 rounded-xl">
          <OtpField
            userType={constants.COMPANY.toLowerCase()}
            onClose={() => setTogglePopup(false)}
          />
        </div>
      </Popup>
    </div>
  );
};

export default CompanyCreate;
