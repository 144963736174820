import { useEffect, useState } from "react";
import ClockIcon from "../../../../../assets/Icons/ClockIcon";
import LocationIcon from "../../../../../assets/Icons/LocationIcon";
import VerticleThreeDotsIcon from "../../../../../assets/Icons/VerticleThreeDotsIcon";
import { v4 as uuidV4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { getStagedApplicantsApi } from "../../../../../slices/jobSlice";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import StagedApplicantCard from "./StagedApplicantCard";
import * as constants from "../../../../../constant";

const StagedApplicants = ({
  column = null,
  nextStage = null,
  prevStage = null,
}) => {
  const dispatch = useAppDispatch();
  const { jobId } = useParams();

  const [applicantsList, setApplicantsList] = useState(null);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (column.id === "" && column.stageName) {
      try {
        const response = await dispatch(
          getStagedApplicantsApi({
            jobId,
            isTagged: true,
            recruiterId:
              loginSelector?.who === constants.COMPANY.toLowerCase() ||
              (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
                memberSelector?.designation === "Admin")
                ? ""
                : loginSelector.userId,
          })
        ).then(unwrapResult);
        setApplicantsList(response?.data?.data?.applications);
      } catch (error) {
        toast.error("Unable to fetch applicants");
      }
    } else {
      if (column.id) {
        try {
          const response = await dispatch(
            getStagedApplicantsApi({
              jobId,
              isTagged: true,
              stageId: column.id,
              recruiterId:
                loginSelector?.who === constants.COMPANY.toLowerCase() ||
                (loginSelector?.who ===
                  constants.COMPANY_MEMBER.toLowerCase() &&
                  memberSelector?.designation === "Admin")
                  ? ""
                  : loginSelector.userId,
            })
          ).then(unwrapResult);
          setApplicantsList(response?.data?.data?.applications);
        } catch (error) {
          toast.error("Unable to fetch applicants");
        }
      }
    }
  };

  return (
    <div className="space-y-2 h-screen overflow-y-auto no-scrollbar">
      {applicantsList &&
        applicantsList.length > 0 &&
        applicantsList.map((applicant) => (
          <StagedApplicantCard
            key={uuidV4()}
            applicant={applicant}
            fetchData={fetchData}
            nextStage={nextStage}
            prevStage={prevStage}
            currentStageId={column.id}
          />
        ))}
    </div>
  );
};

export default StagedApplicants;
