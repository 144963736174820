import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "../../../assets/Icons/EditIcon";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { useAppSelector } from "../../../config/hooks";
import { useMemo } from "react";
import OnboardingTab from "./OnboardingTab";

export interface DocumentProps {
  memberId: string;
}

const DocumentManagement = ({
  memberId
}: DocumentProps) => {
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  //const { memberId } = useParams();
  const navigateHandler = (path) => {
    navigate(path);
  };

  return (
    <div className="min-h-screenheight bg-exwhite-100 p-4 space-y-4">
      <div className='flex'>
        <OnboardingTab />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default DocumentManagement;
