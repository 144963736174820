const DownloadIcon = ({ width = "23", height = "23", color = "#0A65CC" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.952909 23C0.702315 23 0.5 22.7924 0.5 22.5352C0.5 22.278 0.702329 22.0704 0.952909 22.0704H22.0471C22.2977 22.0704 22.5 22.279 22.5 22.5352C22.5 22.7924 22.2977 23 22.0471 23H0.952909ZM20.3357 10.9228L11.8294 20.2264L11.8099 20.2464C11.628 20.4217 11.3422 20.4131 11.1714 20.2264L2.68654 10.9457C2.59744 10.861 2.54175 10.7391 2.54175 10.6048C2.54175 10.3476 2.74408 10.14 2.99466 10.14H7.27237V0.46479C7.27237 0.207622 7.47563 0 7.72528 0H15.2764C15.527 0 15.7293 0.207636 15.7293 0.46479V10.14L20.007 10.1409C20.1184 10.1419 20.2297 10.1838 20.317 10.2676C20.4989 10.4429 20.5073 10.7362 20.3365 10.9229L20.3357 10.9228Z"
        fill={color}
      />
    </svg>
  );
};

export default DownloadIcon;
