import { useEffect, useRef, useState } from "react";
import DropdownArrowIcon from "../../../../../assets/Icons/DropdownArrowIcon";
import EnvelopeIcon from "../../../../../assets/Icons/EnvelopeIcon";
import { useParams } from "react-router-dom";
import { addColumnReducer } from "../../../../../slices/jobSlice";
import { useAppDispatch } from "../../../../../config/hooks";
import { toast } from "sonner";

const TaggedListHeader = () => {
  const dispatch = useAppDispatch();
  const { jobId } = useParams();
  const [sort, setSort] = useState("Ai Scoring");
  const [toggleSort, setToggleSort] = useState(false);

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggleSort(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const toggleSortHandler = () => {
    setToggleSort(!toggleSort);
  };

  const addColumnHandler = () => {
    try {
      dispatch(addColumnReducer());
    } catch (error) {
      toast.error("Unable to add column");
    }
  };

  return (
    <div className="flex items-center justify-between pt-2">
      <div className="flex items-center justify-start gap-4">
        <h1 className="text-base text-exgray-100 font-semibold">
          Interview Stages
        </h1>
        <button
          className="border border-secondary-400 bg-secondary-100 text-base text-center text-secondary-400 font-semibold px-4 py-1 rounded-full active:scale-95"
          onClick={addColumnHandler}
        >
          + Add Columns
        </button>
      </div>

      {/* <div className="w-fit flex items-center justify-center gap-4">
        <button className="flex items-center justify-center gap-1 border border-exgray-500 text-base text-center text-exgray-500 font-semibold px-4 py-1 rounded-full active:scale-95">
          <div>
            <EnvelopeIcon height="19" width="19" color="#343839" />
          </div>
          <p className="">Email</p>
        </button>

        <div className="flex items-center justify-center gap-1">
          <p className="text-exgray-500 text-base font-semibold">Sort By :</p>
          <div className="flex-1 relative">
            <button
              className="flex items-center justify-center gap-2"
              onClick={toggleSortHandler}
              ref={menuParentRef}
            >
              <p className="text-base text-exgray-500 font-bold">{sort}</p>
              <div className="">
                <DropdownArrowIcon color="#343839" />
              </div>
            </button>
            {toggleSort && (
              <div
                className="absolute top-8 right-0 rounded-md bg-exwhite-100 w-36"
                style={{
                  boxShadow: "0px 12px 32px 0px #18191C14",
                }}
                ref={menuRef}
              >
                <button
                  className="py-2 px-4 bg-exwhite-100 text-start rounded-t-md hover:bg-secondary-100 w-full hover:text-secondary-400"
                  onClick={() => {
                    setSort("Ai Scoring");
                    setToggleSort(false);
                  }}
                >
                  Ai Scoring
                </button>
                <button
                  className="py-2 px-4 bg-exwhite-100 text-start rounded-b-md hover:bg-secondary-100 hover:text-secondary-400 w-full"
                  onClick={() => {
                    setSort("A - Z");
                    setToggleSort(false);
                  }}
                >
                  A - Z
                </button>
                <button
                  className="py-2 px-4 bg-exwhite-100 text-start rounded-b-md hover:bg-secondary-100 hover:text-secondary-400 w-full"
                  onClick={() => {
                    setSort("Date");
                    setToggleSort(false);
                  }}
                >
                  Date
                </button>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TaggedListHeader;
