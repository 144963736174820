import { useAppSelector } from "../../config/hooks";

const useDepartment = () => {
  const departmentList = useAppSelector((state) => state.appConfig.departmentList);

  const defaultDepertment = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Id: dafaultId, Title: dafaultId };
    }
    const selectedIndustry = departmentList.find(
      (department) => department.Id == dafaultId
    );
    return selectedIndustry;
  };

  const defaultDepertments = (dafaultIds) => {
    const selectedIndustry = dafaultIds.map((id) => {
      if (isNaN(id)) {
        return { Id: id, Title: id };
      }

      const filterDepartment = departmentList.find(
        (dep) => dep.Id == id
      );
      return filterDepartment;
    });
    return selectedIndustry;
  };

  const getDepartment=(departments)=>{
    let department = departments.map((dep)=>{
      return dep.Title;
    })
    return department;
  }

  return { departmentList, defaultDepertment, defaultDepertments, getDepartment };
};

export default useDepartment;
