import nocoApiClient from "../config/local-axios-config/nocoApiConfig";

class SalesNocoService {
  getSalesEngangementType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Engagement-Type?limit=500&fields=Id,Title`
    );
  }
  getSalesIndustry() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Industry?limit=500&fields=Id,Title`
    );
  }
  getSalesSubIndustry() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-SubIndustry?limit=500&fields=Id,Title`
    );
  }
  getSalesStages() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Stages?limit=500&fields=Id,Title`
    );
  }
  getSalesStatus() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Status?limit=500&fields=Id,Title`
    );
  }

}

const salesnocoService = new SalesNocoService();
export default salesnocoService;