import { useAppSelector } from "../../config/hooks";

const useAccessRole = () => {
  const roleList = useAppSelector((state) => state.appConfig.roleList);

  const defaultRole = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Id: dafaultId, Title: dafaultId };
    }
    const selectedIndustry = roleList.find(
      (role) => role.Id == dafaultId
    );
    return selectedIndustry;
  };

  return { roleList, defaultRole };
};

export default useAccessRole;
