import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import CrossIcon from "../../../../../assets/Icons/CrossIcon";
import { v4 as uuidv4 } from "uuid";
import { Controller, useForm } from "react-hook-form";
import ExMultiDropdown from "../../../../../components/basic-components/ExMultiDropdown";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import useLanguages from "../../../../../utils/dropdown-options/useLanguages";
import {
  addLanguagesApi,
  postLanguagesApi,
  updateUserProfileApi,
} from "../../../../../slices/candidateProfileSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";

const PreferenceForm = ({ onClose = () => {} }) => {
  const dispatch = useAppDispatch();

  const userId = useAppSelector((state) => state.login.session.userId);
  const languageSelector = useAppSelector(
    (state) => state.candidateProfile.userLanguages
  );
  const candidateDetailsSelector = useAppSelector(
    (state) => state.candidateProfile?.userDetails
  );

  const { industriesArray, defaultIndustries } = useIndustries();
  const { laguagesArray, defaultLanguages } = useLanguages();

  const [interestValue, setInterestValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      interest: candidateDetailsSelector.interest || [],
      industry:
        candidateDetailsSelector.industry &&
        candidateDetailsSelector.industry.length > 0
          ? defaultIndustries(candidateDetailsSelector.industry)
          : null,
      languages:
        languageSelector?.length > 0
          ? defaultLanguages(languageSelector)
          : null,
    },
  });

  const { handleSubmit, control, watch, formState, reset, setValue } = form;
  const { errors, isDirty } = formState;

  const allInterestValues = watch("interest");

  const onChangeInterestValue = (e) => {
    const fieldValue = e.target.value;
    setInterestValue(fieldValue);
  };

  const addInterestHandler = () => {
    setValue("interest", [...allInterestValues, interestValue]);
    setInterestValue("");
  };

  const removeInterestHandler = (index) => {
    const newInterestArray = allInterestValues?.filter((_, i) => i !== index);
    setValue("interest", newInterestArray);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const intdustryArray = formData.industry.map((industry) =>
      industry.Id.toString()
    );

    const langArray = formData.languages.map((lan) => ({
      language: lan.Id.toString(),
      reading: "Yes",
      speaking: "Yes",
      writing: "Yes",
    }));

    try {
      await dispatch(
        updateUserProfileApi({
          userId: userId,
          industry: intdustryArray,
          interest: formData.interest,
        })
      ).then(unwrapResult);

      setIsLoading(false);
      toast.success("Preference added !");
      onClose();

      if (languageSelector?.length > 0) {
        dispatch(
          addLanguagesApi({
            userId: userId,
            languages: langArray,
          })
        );
      } else {
        dispatch(
          postLanguagesApi({
            userId: userId,
            languages: langArray,
          })
        );
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
      onClose();
    }
  };

  return (
    <div className="w-[50%] max-h-screenheight bg-expurple-200 p-10 rounded-xl space-y-2 no-scrollbar">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
        <div className="space-y-2">
          <h1 className="text-2xl text-expurple-800 font-semibold tracking-wide">
            Add Preference
          </h1>

          <div>
            <Controller
              name="interest"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Interest is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="space-y-2">
                  <label
                    htmlFor={"interest"}
                    className={`text-lg text-expurple-800`}
                  >
                    Interest
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700 text-base"
                      id="interest"
                      placeholder="Add Interest"
                      autoComplete="off"
                      name="interest"
                      onChange={onChangeInterestValue}
                      value={interestValue}
                    />
                    <div className="absolute h-full top-0 flex items-center justify-center right-2">
                      <button
                        type="button"
                        disabled={!interestValue}
                        onClick={addInterestHandler}
                        className="py-1 w-fit px-4  text-expurple-800 font-bold underline text-sm rounded-sm"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  {errors?.interest?.message && (
                    <p className="text-red-400">{errors?.interest?.message}</p>
                  )}

                  {allInterestValues.length > 0 && (
                    <div className="flex flex-wrap gap-2">
                      {allInterestValues?.map((skill, index) => (
                        <div
                          className="px-3 py-1 bg-expurple-800 text-golden w-fit rounded-md text-sm flex items-center justify-center gap-4"
                          key={uuidv4()}
                        >
                          <h1>{skill}</h1>
                          <div
                            className="cursor-pointer"
                            onClick={() => removeInterestHandler(index)}
                          >
                            <CrossIcon width="10" height="10" />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          <div>
            <Controller
              name="industry"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExMultiDropdown
                  title="Industry"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={industriesArray}
                  closeMenuOnSelect={true}
                  dropDownHeight="auto"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  menuListHeight="250px"
                  error={errors?.industry?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="languages"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExMultiDropdown
                  title="Languages"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Title"
                  options={laguagesArray}
                  closeMenuOnSelect={true}
                  dropDownHeight="auto"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  menuListHeight="250px"
                  error={errors?.languages?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className="w-fit ml-auto mr-0">
            <button
              type="submit"
              className={`text-base text-center px-8 py-2 text-exwhite-100 ${
                isLoading ? "bg-exgray-400/50 " : "bg-expurple-800"
              } rounded-lg`}
              disabled={isLoading}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PreferenceForm;
