import { useRef } from "react";
import EditIcon from "../../../../../assets/Icons/EditIcon";
import TaggedListHeader from "./TaggedListHeader";
import { Controller, useForm } from "react-hook-form";
import { toast } from "sonner";
import { v4 as uuidV4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import {
  createInterviewStagesApi,
  editCloumnhandler,
  exitColumnReducer,
  getInterviewStagesApi,
  removeColumnReducer,
  updateInterviewStagesApi,
} from "../../../../../slices/jobSlice";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { CreateInterviewStagesRes } from "../../../../../models/job.model";
import StagedApplicants from "./StagedApplicants";

type FormValues = {
  title: string;
  id?: string;
};

const TaggedList = () => {
  const dispatch = useAppDispatch();
  const inputRef = useRef();

  const { jobId } = useParams();

  const { setValue, control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      title: "",
      id: "",
    },
  });

  const { jobStages } = useAppSelector((state) => state.jobs);

  const editColumn = (targetId: string) => {
    if (targetId) {
      const updatedColumns = jobStages.map((col) => {
        if (col.id === targetId) {
          setValue("title", col.stageName);
          setValue("id", col.id);
          return { ...col, edit: true };
        } else {
          return { ...col, edit: false };
        }
      });

      dispatch(editCloumnhandler({ updatedJobStages: updatedColumns }));
    }
  };

  const exitColumn = (stageId: string) => {
    if (stageId) {
      const updatedColumns: Array<CreateInterviewStagesRes> = jobStages.map(
        (col) => {
          return { ...col, edit: false };
        }
      );

      dispatch(exitColumnReducer({ updatedJobStages: updatedColumns }));
    } else {
      dispatch(removeColumnReducer());
    }
    setValue("title", "");
    setValue("id", "");
  };

  const fetchJobInterviewStages = async () => {
    try {
      await dispatch(
        getInterviewStagesApi({
          jobId: jobId,
        })
      ).then(unwrapResult);
    } catch (error) {
      toast.error("Unabel to fetch tagged list");
    }
  };

  const onSubmit = async (formData: FormValues) => {
    if (formData.id) {
      try {
        await dispatch(
          updateInterviewStagesApi({
            stageName: formData.title,
            jobId,
            stageId: formData.id,
          })
        ).then(unwrapResult);
        setValue("title", "");
        setValue("id", "");
        fetchJobInterviewStages();
        toast.success("Coulmn Updated Successfully");
      } catch (error) {
        const updatedColumns = jobStages.map((col) => {
          return { ...col, edit: false };
        });

        dispatch(editCloumnhandler({ updatedJobStages: updatedColumns }));
        toast.error("Unable to Update Column");
      }
    } else {
      try {
        await dispatch(
          createInterviewStagesApi({ stageName: formData.title, jobId })
        ).then(unwrapResult);
        setValue("title", "");
        setValue("id", "");
        toast.success("Coulmn Created Successfully");
      } catch (error) {
        toast.error("Unable to create cloumn");
      }
    }
  };

  return (
    <div className="space-y-4">
      <TaggedListHeader />

      <div className="flex items-center justify-start gap-2 w-full overflow-x-auto no-scrollbar">
        {jobStages.map((column, _index) => (
          <div className={`min-w-[300px] space-y-2 self-start`} key={uuidV4()}>
            <div
              className={`flex items-center justify-between bg-exwhite-100 border-b-2 border-b-secondary-400 px-4 py-1 rounded-t-md`}
            >
              {!column.edit ? (
                <h1 className="text-primary-400 text-base font-semibold cursor-default">
                  {column.stageName}
                </h1>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className="w-full pr-2 outline-none bg-transparent text-base text-primary-400 font-semibold"
                        autoFocus
                        maxLength={27}
                        onChange={onChange}
                        value={value}
                        onBlur={() => exitColumn(column.id)}
                        ref={inputRef}
                        placeholder="Column Name"
                      />
                    )}
                  />
                </form>
              )}
              {column?.id && (
                <button
                  className="active:scale-95"
                  onClick={() => editColumn(column?.id)}
                >
                  <EditIcon height="12" width="12" color="#223870" />
                </button>
              )}
            </div>

            <div>
              <StagedApplicants
                column={column}
                nextStage={
                  _index <= jobStages.length ? jobStages[_index + 1] : null
                }
                prevStage={_index >= 1 ? jobStages[_index - 1] : null}
              />
            </div>
          </div>
        ))}
      </div>

      {/* <div className="flex items-center justify-start gap-2 w-full h-[500px] overflow-auto no-scrollbar">
        {columns.map((column) => (
          <div className={`min-w-[300px] space-y-2 self-start`} key={uuidV4()}>
            <div className="space-y-2">
              {column.applicants.map((applicant) => (
                <div
                  className="bg-exwhite-100 p-4 space-y-2 rounded-md"
                  key={uuidV4()}
                >
                  <div className="flex items-center justify-between">
                    <div className="bg-[#8833FF33] text-[#8833FF] text-base font-semibold text-center rounded-full px-4 py-1 ">
                      {applicant.aiScore}
                    </div>
                    <div className="rotate-90">
                      <VerticleThreeDotsIcon />
                    </div>
                  </div>

                  <h1 className="text-start text-base text-exgray-600 font-semibold">
                    {`${applicant.firstName} ${applicant.lastName}`}
                  </h1>

                  <div className="flex items-center justify-start gap-1">
                    <div className="w-fit">
                      <LocationIcon width="15" height="15" color="#696969" />
                    </div>
                    <p className="flex-1 line-clamp-1 text-base text-exgray-400">
                      {applicant.location}
                    </p>
                  </div>

                  <div className="border-b border-exgray-100 pb-2">
                    <h1 className="text-[#0BA02C] bg-[#E7F6EA]/50 w-fit px-4 py-1 rounded-full">
                      {applicant.status}
                    </h1>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center justify-center gap-2">
                      <div className="w-7 h-7 bg-DefaultProfileImg rounded-sm bg-cover bg-center"></div>

                      <h1 className="text-sm text-exgray-200 font-semibold text-start">
                        {applicant.reportTo}
                      </h1>
                    </div>

                    <div className="flex items-center justify-center gap-2">
                      <div>
                        <ClockIcon color="#8C8C8C" />
                      </div>

                      <h1 className="text-sm text-exgray-200 font-semibold text-start">
                        {applicant.createdAt}
                      </h1>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default TaggedList;

const data = {
  // title: "Golang Developer 1",
  // company: "Linkcxo2",
  // companyId: "7240229641557086208",
  // hideCompany: true,
  // industries: ["9"],
  functions: ["9"],
  // salary: null,
  // hideSalary: true,
  // location: ["Delhi"],
  // jobType: "FULL_TIME",
  // experience: {
  //   min: 5,
  //   max: 15,
  // },
  // hiringFor: "CLIENT",
  // qualification: "B.Tech",
  // skills: ["Java", "Spring", "Docker"],
  // description:
  // "To the function.\r\n",
  // attachment: null,
  // authorType: "USER",
  // authorId: "{{userId}}",
  createdBy: "",
  // jobStatus: "",
  // workplaceType: "",
  // currency: "",
  noOfApplicants: 0,
  oldId: "",
  contactEmailId: "",
};

const data1 = {
  // authorId: "7243822592057106432",
  // companyId: "7243822592057106432",
  // authorType: "COMPANY",
  // title: "Sr. sr designer",
  // company: "LinkCXo global pvt ltd",
  // hideCompany: false,
  // qualification: "",
  // jobType: "1",
  // workplaceType: "2",
  // experience: { min: 3, max: 5 },
  // currency: "Doller ($)",
  // salary: { min: 2, max: 4 },
  // hideSalary: false,
  // hiringFor: "COMPANY",
  // description: "asdasdasdad",
  // industries: ["6"],
  // skills: ["adasdasd"],
  // location: ["142"],
  // jobStatus: "",

  functions: ["9"],
  attachment: null,
  createdBy: "",
  noOfApplicants: 0,
  oldId: "",
};
