import React, { useMemo, useState } from "react";
import WorkPlaceIcon from "../../../../../assets/Icons/WorkPlaceIcon";
import IndustryIcon from "../../../../../assets/Icons/IndustryIcon";
import SalaryRangeIcon from "../../../../../assets/Icons/SalaryRangeIcon";
import GlobeIcon from "../../../../../assets/Icons/GlobeIcon";
import EnvelopeIcon from "../../../../../assets/Icons/EnvelopeIcon";
import useCompanyTypes from "../../../../../utils/dropdown-options/useCompanyTypes";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import useCompanySize from "../../../../../utils/dropdown-options/useCompanySize";
import { useAppSelector } from "../../../../../config/hooks";

import { v4 as uuidV4 } from "uuid";

import * as constants from "../../../../../constant";
import Popup from "../../../../../components/popup/Popup";
import CreateMember from "./CreateMember";
import { useNavigate } from "react-router-dom";

const CompanyDashboardDetails = () => {
  const navigate = useNavigate();

  const { defaultCompanyType } = useCompanyTypes();
  const { defaultIndustry } = useIndustries();
  const { defaultCompanySize } = useCompanySize();

  const companySelector = useAppSelector((state) => state.company);
  const memberSelector = useAppSelector((state) => state.member.members);
  const token = useAppSelector((state) => state.login.session.token);

  const [togglePopup, setTogglePopup] = useState(false);

  const { companyDetails } = companySelector;
  const { emailId, pageName, industry, size, registrationType, description } =
    companyDetails || {};

  const defaultOrgType = useMemo(
    () => defaultCompanyType(registrationType),
    [registrationType]
  );

  const defaultIndustryType = useMemo(
    () => defaultIndustry(industry),
    [industry]
  );

  const defaultSize = useMemo(() => defaultCompanySize(size), [size]);

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const navigateHandler = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="flex items-center justify-center gap-4">
        <div className="space-y-2 flex-1 self-start bg-exwhite-100 p-5 border-2 border-secondary-300/60 sapce-y-2">
          <div>
            <div className="border-b-2 border-secondary-300/60 grid grid-cols-2 auto-rows-auto gap-y-6 gap-x-5 p-5 bg-exwhite-100">
              <div className="text-start text-base space-y-1">
                <div>
                  <WorkPlaceIcon />
                </div>
                <h1 className="text-exgray-200 text-sm">ORGANIZATION TYPE</h1>
                <p className="text-expurple-800 font-semibold">
                  {defaultOrgType?.Title}
                </p>
              </div>
              <div className="text-start text-base space-y-1">
                <div>
                  <IndustryIcon height="24" />
                </div>
                <h1 className="text-exgray-200 text-sm">INDUSTRY TYPES</h1>
                <p className="text-expurple-800 font-semibold">
                  {defaultIndustryType?.Name}
                </p>
              </div>
              <div className="text-start text-base space-y-1">
                <div>
                  <SalaryRangeIcon width="25" height="24" />
                </div>
                <h1 className="text-exgray-200 text-sm">TEAM SIZE</h1>
                <p className="text-expurple-800 font-semibold text-start">
                  {defaultSize?.Title} Employees
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <h1 className="text-xl text-expurple-600 font-semibold">
              About Us
            </h1>
            <p className="text-base text-exgray-300 text-start whitespace-pre-wrap">
              {description}
            </p>
          </div>
        </div>
        <div className="self-start space-y-2 min-w-[35%]">
          <div className="border-2 bg-exwhite-100 border-secondary-300/60 rounded-lg p-5 space-y-4">
            <h1 className="text-xl text-expurple-600 font-semibold">
              Connect Us
            </h1>
            <div className="flex items-center justify-start gap-3 pb-2 border-b-2 border-b-secondary-300/60">
              <div>
                <GlobeIcon />
              </div>
              <div className="flex-1">
                <h1 className="text-exgray-200 text-sm">WEBSITE</h1>
                <p className="text-expurple-800 font-semibold text-start">
                  {pageName}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-start gap-3 pb-2 border-b-2 border-b-secondary-300/60">
              <div>
                <EnvelopeIcon />
              </div>
              <div className="flex-1">
                <h1 className="text-exgray-200 text-sm">EMAIL ADDRESS</h1>
                <p className="text-expurple-800 font-semibold text-start">
                  {emailId}
                </p>
              </div>
            </div>
          </div>
          <div className="border-2 bg-exwhite-100 border-secondary-300/60 rounded-lg p-5 space-y-4">
            <div className="flex items-center justify-between">
              <h1 className="text-xl text-expurple-600 font-semibold">
                Employees
              </h1>
            </div>
            <div className="space-y-2">
              {memberSelector &&
                memberSelector.length > 0 &&
                memberSelector.slice(0, 3).map(
                  (member) =>
                    member.firstName &&
                    member.lastName && (
                      <div
                        className="flex items-center justify-start gap-3"
                        key={uuidV4()}
                      >
                        <div className="w-fit cursor-pointer self-start">
                          {member.picUrl ? (
                            <div className="h-12 w-12  rounded-full flex items-center justify-center overflow-hidden">
                              <img
                                src={`${constants.baseUrl}/files/${member?.picUrl}?t=${token}`}
                                alt="profile"
                                className="w-full h-full object-cover"
                              />
                            </div>
                          ) : (
                            <div className="h-12 w-12 rounded-full bg-primary-400 flex items-center justify-center text-xl text-exwhite-100 font-semibold">{`${member.firstName[0]}${member.lastName[0]}`}</div>
                          )}
                        </div>
                        <div className="flex-1 self-start">
                          <h1 className="text-primary-400 font-semibold text-base line-clamp-1">{`${member.firstName} ${member.lastName}`}</h1>
                          <p className="text-sm text-exgray-800 text-start line-clamp-1">
                            {member.emailId}
                          </p>
                        </div>
                      </div>
                    )
                )}
            </div>
            {memberSelector.length > 3 && (
              <div>
                <div className="w-fit ml-auto mr-0">
                  <button
                    className="text-secondary-400 text-base font-semibold"
                    onClick={() => navigateHandler("/company-admin/employees")}
                  >
                    Show All
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Popup onClose={togglePopupHandler} visible={togglePopup}>
        <div className="w-[50%] bg-expurple-200 p-10 rounded-xl">
          <CreateMember onClose={togglePopupHandler} />
        </div>
      </Popup>
    </div>
  );
};

export default CompanyDashboardDetails;
