import apiRecruiterClient from "../config/local-axios-config/recruiterApiConfig";
import apiClient from "../config/axiosConfig";
import { Attendance, AttendanceFilter, GetDailyReport } from "../slices/attendanceSlice";
import { json } from "react-router-dom";
import { PostDailyReport } from "../models/employee.model";

class AttendanceService {
  postAttedance(req: Attendance) {
    return apiClient.post(`/v1/exo/attendance`, req);
  }

  getAllAttendance(req: AttendanceFilter) {
    return apiClient.get(`/v1/exo/attendance?`, {
      params: req
    });
  }

  updateOutTime(req: Attendance) {
    return apiClient.put(`/v1/exo/attendance/${req.attendanceId}`, req);
  }

  postDailyReport(req: PostDailyReport) {
    return apiClient.post(`/v1/exo/daily-reports`, req);
  }

  getDailyReport(
    data: GetDailyReport
  ) {
    return apiClient.get(`/v1/exo/daily-reports?employeeId=${data.employeeId}`);
  }

}

const attendanceService = new AttendanceService();
export default attendanceService;
